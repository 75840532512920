export enum TRAINING_LEVEL {
  BEGINNER = "beginner",
  INTERMEDIATE = "intermediate",
  ADVANCED = "advanced",
}

export const TRAINING_LEVEL_LABELS: { [key in TRAINING_LEVEL]: string } = {
  [TRAINING_LEVEL.BEGINNER]: "Débutant",
  [TRAINING_LEVEL.INTERMEDIATE]: "Intermédiaire",
  [TRAINING_LEVEL.ADVANCED]: "Avancé",
};
