import { createReducer } from "@reduxjs/toolkit";
import { resetInviteAdminUser, inviteAdminUser } from "./invite-admin-user";
import { UserInviteState } from "@redux/app-state";

const initialState: UserInviteState = { justInvitedUserId: null, processing: "idle" };

export const inviteAdminUserReducer = createReducer<UserInviteState>(initialState, builder => {
  builder.addCase(inviteAdminUser.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justInvitedUserId: payload,
      processing: "success",
    };
  });
  builder.addCase(inviteAdminUser.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(inviteAdminUser.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetInviteAdminUser, () => {
    return initialState;
  });
});
