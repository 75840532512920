import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { UserInvitationFormComponent } from "@user-management-context/primary/admin/user-invitations/form/user-invitations-form.components";
import { inviteStandardUser } from "@user-management-context/write/application/use-cases/admin/standard-user-invitation/invite-standard-user";
import { selectUnsubscribedUserList } from "@user-management-context/read/application/use-cases/admin/unsubscribed-user-list-retrieval/unsubscribed-user-list-selectors";
import { AppState } from "src/redux/app-state";
import { useInvitationSent } from "@user-management-context/primary/admin/user-invitations/form/use-invitation-sent";
import { retrieveUnsubscribedUserList } from "@user-management-context/read/application/use-cases/admin/unsubscribed-user-list-retrieval/retrieve-unsubscribed-user-list";
import { UserInvitationFormSkeleton } from "@user-management-context/primary/admin/user-invitations/form/user-invitations-form.skeleton";
import {
  doesUserExist,
  resetDoesUserExist,
} from "@user-management-context/read/application/use-cases/admin/user-account-existence/does-user-account-exist";
import { InviteAdminUserBody, InviteStandardUserBody } from "@user-management-context/shared/application/ports/user-invitation-port";
import { inviteAdminUser } from "@user-management-context/write/application/use-cases/admin/admin-user-invitation/invite-admin-user";

export const UserInvitationFormContainer = () => {
  const dispatch = useAppDispatch();
  const { teacherEmails, studentEmails, providerEmails, isLoading } = useAppSelector(selectUnsubscribedUserList);
  const { doesExist } = useAppSelector((state: AppState) => state.userAccountExistence);
  const { processing: standardUserInviteProcessing } = useAppSelector((state: AppState) => state.standardUserInvite);
  const { processing: adminUserInviteProcessing } = useAppSelector((state: AppState) => state.adminUserInvite);

  useEffect(() => {
    dispatch(retrieveUnsubscribedUserList());
  }, [dispatch]);

  const onSubmitStandardUser = (user: InviteStandardUserBody) => {
    dispatch(inviteStandardUser(user));
  };

  const onSubmitAdminUser = (user: InviteAdminUserBody) => {
    dispatch(inviteAdminUser(user));
  };

  const checkIfUserExist = useCallback(
    (email: string) => {
      dispatch(doesUserExist(email));
    },
    [dispatch]
  );

  const resetCheckIfUserExist = useCallback(() => {
    dispatch(resetDoesUserExist());
  }, [dispatch]);

  useInvitationSent();

  if (isLoading) return <UserInvitationFormSkeleton />;

  return (
    <UserInvitationFormComponent
      onSubmitStandardUser={onSubmitStandardUser}
      onSubmitAdminUser={onSubmitAdminUser}
      checkIfUserExist={checkIfUserExist}
      resetCheckIfUserExist={resetCheckIfUserExist}
      teacherEmails={teacherEmails}
      studentEmails={studentEmails}
      providerEmails={providerEmails}
      doesAccountExist={doesExist}
      standardUserInviteProcessing={standardUserInviteProcessing}
      adminUserInviteProcessing={adminUserInviteProcessing}
    />
  );
};
