import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useNavigate } from "react-router-dom";
import { resetCreateProvider } from "@user-management-context/write/application/use-cases/admin/provider-creation/create-provider";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

const selectProviderCreate = (state: AppState) => state.providerCreation;

export const useProviderCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedId, processing } = useAppSelector(selectProviderCreate);

  useEffect(() => {
    if (justCreatedId) {
      navigate(`${ROLE_BASED_URLS[ROLES.ADMIN].provider.list}/${justCreatedId}`);
      toast({ description: "Nouveau prestataire crée !" });
      dispatch(resetCreateProvider());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la création du prestataire", variant: "destructive" });
    }
  }, [justCreatedId, dispatch, processing, navigate, toast]);
};
