import { createReducer } from "@reduxjs/toolkit";
import { UploadState } from "@redux/app-state";
import { resetUploadDocument, uploadDocument } from "./upload-document";

const initialState: UploadState = { processing: "idle", url: null };

export const uploadDocumentReducer = createReducer<UploadState>(initialState, builder => {
  builder.addCase(uploadDocument.fulfilled, (state, { payload }) => {
    return {
      ...state,
      url: payload,
      processing: "success",
    };
  });
  builder.addCase(uploadDocument.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(uploadDocument.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUploadDocument, () => {
    return initialState;
  });
});
