import { isLoadingState, sortAlphabetically } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";
import { commonAdapters } from "src/common-adapters";
import { formatDateToLocale } from "@utils/formatting";

export const studentSelectProviderBatchesRetrievalForList = createSelector(
  [(state: AppState) => state.studentProviderBatchesRetrieval],
  providerBatchesRetrieval => {
    const { data, fetching } = providerBatchesRetrieval;
    const { dateProvider } = commonAdapters;
    const now = dateProvider!.now();

    const formattedData = [...data]
      .filter(batch => {
        const endDate = new Date(batch.interval.end);
        return now < endDate;
      })
      .sort((a, b) => sortAlphabetically(a.title, b.title))
      .map(batch => ({
        label: `${batch.title} (${formatDateToLocale(batch.interval.start)} - ${formatDateToLocale(batch.interval.end)})`,
        value: batch.id,
      }));

    const orderedData = data
      .filter(batch => {
        const endDate = new Date(batch.interval.end);
        return now < endDate;
      })
      .map(training => ({
        ...training,
        questions: [...training.questions].sort((a, b) => a.order - b.order),
      }));

    return {
      providerBatchesOptions: formattedData,
      rawProviderBatches: orderedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
