import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyDataReviewFundingRequest,
  formatFundingRequestDataToFormData,
} from "./form-validation/funding-request";
import RichText from "@shared-kernel/primary/shared/rich-text/rich-text";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { ReviewFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { TrainingDaysForm } from "./training-days";
import { PersonalizedEducationalProject } from "./personalized-educational-project";
import { AppState } from "@redux/app-state";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Loader2 } from "lucide-react";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import {
  computeDaysSpreadOver2Months,
  selectDateOptions,
} from "@academy-context/primary/admin/shared/selectors/funding-request/date-options-selectors";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";
import { TeacherListElement } from "@user-management-context/read/domain/types/admin/teacher";
import { TrainingDays } from "@academy-context/shared/domain/types/admin/funding-request";
import { Checkbox } from "@components/ui/checkbox";
import {
  adminRetrieveProviderBatches,
  resetAdminRetrieveProviderBatches,
} from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";
import { MAX_SPECIFIC_ACCOMODATION_CHARACTERS } from "@academy-context/primary/student/funding-request/form-validation/funding-request";
import { Textarea } from "@components/ui/textarea";
import { TRAINING_LEVEL, TRAINING_LEVEL_LABELS } from "@academy-context/shared/domain/types/enums/training-levels";
import { OrganisationOptions } from "@shared-kernel/domain/organisation";

interface Props {
  onSubmit: (body: ReviewFundingRequestBody) => void;
  onDelete: () => void;
  fundingRequest: FundingRequestVM;
  providers: ProviderListItem[];
  teachers: TeacherListElement[];
  providerBatchesOptions: ComboboxOptions[];
  internalTrainingsOptions: ComboboxOptions[];
  teacherOptions: ComboboxOptions[];
  providerOptions: ComboboxOptions[];
}

export const FundingRequestForm = ({
  onSubmit,
  fundingRequest,
  onDelete,
  providers,
  teachers,
  providerBatchesOptions,
  internalTrainingsOptions,
  teacherOptions,
  providerOptions,
}: Props) => {
  const dispatch = useAppDispatch();
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const { processing } = useAppSelector((state: AppState) => state.fundingRequestReview);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const now = new Date();
  const dateOptions = selectDateOptions(now);
  // We start at M+2
  const secondDateOption = dateOptions[1]!.value;
  const [month, setMonth] = useState(secondDateOption);
  const trainingDays = useMemo(() => computeDaysSpreadOver2Months(new Date(month)), [month]);
  const [needsAccommodation, setNeedsAccommodation] = useState(false);
  const [needsTravelExpenses, setNeedsTravelExpenses] = useState(false);
  // All this complexity is due to the fact that we don't want some useEffects to be triggered after form initialization
  // Otherwise, it resets all the values set in the form initialization
  const skipUseEffect1 = useRef(false);
  const skipUseEffect2 = useRef(false);
  const skipUseEffect3 = useRef(false);

  const {
    formState: { errors, isDirty, isSubmitted },
    setValue,
    control,
    resetField,
    watch,
    reset,
  } = form;

  const reviewedEducationalAdvisorId = watch("reviewedEducationalAdvisorId");
  const reviewedEducationalAdvisorType = watch("reviewedEducationalAdvisorType");
  const isProvider = reviewedEducationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;
  const reviewedSpecificAccommodation = watch("reviewedSpecificAccommodation");
  const travelExpenses = watch("travelExpenses");
  const isUndefinedTeacher = watch("isUndefinedTeacher");

  useEffect(() => {
    if (skipUseEffect1.current) {
      skipUseEffect1.current = false;
      return;
    }

    setValue("reviewedEducationalAdvisorId", "", { shouldDirty: false });
    setValue("reviewedInternalTrainingId", "", { shouldDirty: false });
    setValue("reviewedProviderBatchId", "", { shouldDirty: false });
    setValue("administrativeInternalTrainingId", "", { shouldDirty: false });
    setValue("address", "");
    setValue("additionalAddress", "");
  }, [reviewedEducationalAdvisorType, setValue, isUndefinedTeacher]);

  useEffect(() => {
    if (skipUseEffect2.current) {
      skipUseEffect2.current = false;
      return;
    }

    if (isProvider) {
      setValue("reviewedProviderBatchId", "", { shouldDirty: false });
    }
    // Should only be triggered when user changes the provider id since batches are related to a specific provider
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewedEducationalAdvisorId, setValue]);

  useEffect(() => {
    // Reset the provider batches when the educational advisor id changes otherwise if the admin switches between teacher and provider, the provider batches are not reset
    dispatch(resetAdminRetrieveProviderBatches());
    if (isProvider && reviewedEducationalAdvisorId) dispatch(adminRetrieveProviderBatches(reviewedEducationalAdvisorId));
    // Disabled otherwise this is triggered on the switch between teacher and provider which executes the dispatch event though it is the teacher's educational advisor id specified (results in a 404)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reviewedEducationalAdvisorId]);

  useEffect(() => {
    if (!needsAccommodation) setValue("reviewedSpecificAccommodation", "", { shouldDirty: false });
  }, [needsAccommodation, setValue]);

  useEffect(() => {
    setNeedsAccommodation(Boolean(reviewedSpecificAccommodation));
  }, [reviewedSpecificAccommodation]);

  useEffect(() => {
    if (!needsTravelExpenses) setValue("travelExpenses", undefined, { shouldDirty: false });
  }, [needsTravelExpenses, setValue]);

  useEffect(() => {
    setNeedsTravelExpenses(Boolean(travelExpenses));
  }, [travelExpenses]);

  useEffect(() => {
    if (skipUseEffect3.current) {
      skipUseEffect3.current = false;
      return;
    }
    const amount = isProvider ? 400 : 550;
    setValue("operaOffCommission", amount, { shouldDirty: false });
  }, [isProvider, reviewedEducationalAdvisorType, setValue]);

  useEffect(() => {
    if (reviewedEducationalAdvisorId) {
      let address: string;
      let additionalAddress: string;
      if (isProvider) {
        const provider = providers.find(p => p.id === reviewedEducationalAdvisorId);
        address = provider?.address ?? "";
        additionalAddress = provider?.additionalAddress ?? "";
      } else {
        const teacher = teachers.find(t => t.teacherId === reviewedEducationalAdvisorId);
        address = teacher?.address ?? "";
        additionalAddress = teacher?.additionalAddress ?? "";
      }
      setValue("address", address);
      setValue("additionalAddress", additionalAddress);
    }
    // isProvider is not included in the dependencies because it is not necessary to trigger this effect when it changes (reviewedEducationalAdvisorId already triggers it)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers, reviewedEducationalAdvisorId, setValue, teachers]);

  useEffect(() => {
    for (const [k, v] of Object.entries(trainingDays)) {
      setValue(k as keyof TrainingDays, v, { shouldDirty: true, shouldTouch: true });
    }
  }, [trainingDays, setValue]);

  // This useEffect has to be below the previous ones to avoid the reset of form data
  useEffect(() => {
    if (fundingRequest) {
      reset(formatFundingRequestDataToFormData({ ...fundingRequest, trainingDays }));
      const educationalAdvisorType = fundingRequest.reviewedEducationalAdvisor?.type ?? fundingRequest.educationalAdvisor?.type;
      const isUndefinedTeacher = !(fundingRequest.reviewedEducationalAdvisor?.id ?? fundingRequest.educationalAdvisor.id);

      if (educationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER) {
        skipUseEffect1.current = true;
        skipUseEffect2.current = true;
        skipUseEffect3.current = true;
      } else if (isUndefinedTeacher) {
        skipUseEffect1.current = true;
      }
    }

    // This is intended because we don't want to trigger it at each trainingDays change. Instead we want to trigger the useEffect below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundingRequest, reset]);

  const handleOnSubmit = (formBody: FormInputs) => {
    const body = formatFormDataToBodyDataReviewFundingRequest(formBody);
    onSubmit(body);
  };

  const coverLetter = watch("coverLetter");

  const handleCoverLetterChange = (value: { html: string; text: string }) => {
    const isDirty = control._defaultValues.coverLetter !== value.html;
    // We use resetField because it seems like shouldDirty doesn't work when setting it to false
    if (isDirty) {
      setValue("coverLetter", value.html, { shouldDirty: isDirty, shouldValidate: isSubmitted });
      setValue("coverLetterText", value.text.trim(), { shouldDirty: isDirty, shouldValidate: isSubmitted });
    } else {
      resetField("coverLetter");
      resetField("coverLetterText");
    }
  };

  const handleResetCoverLetter = (value: string) => {
    resetField("coverLetter", { defaultValue: value });
  };

  return (
    <div className="flex w-full justify-start">
      <AlertModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => {
          setIsModalOpen(false);
          onDelete();
        }}
        title="Supprimer la demande de financement"
        body="Êtes-vous sûr de vouloir supprimer cette demande ?"
        submitText="Supprimer"
      />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="w-full space-y-4">
          <div className="space-y-2">
            <FormField
              control={form.control}
              name="organization"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Société</FormLabel>
                  <FormControl>
                    <Combobox
                      options={OrganisationOptions}
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner la société ..."
                      search={{
                        notFoundText: "Pas de société trouvée.",
                        commandInputPlaceHolder: "Chercher société...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="reviewedEducationalAdvisorType"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Professeur / Prestataire principal</FormLabel>
                    <RadioGroup
                      onValueChange={value => field.onChange(value)}
                      value={field.value}
                      className="flex flex-col space-y-1"
                      disabled={isUndefinedTeacher}
                    >
                      {Object.values(EDUCATIONAL_ADVISOR).map((v, index) => (
                        <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                          <FormControl>
                            <RadioGroupItem value={v} />
                          </FormControl>
                          <FormLabel className="font-normal">{EDUCATIONAL_ADVISOR_MAP[v]}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
          <FormField
            control={form.control}
            name="reviewedEducationalAdvisorId"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  {isProvider ? (
                    <Combobox
                      options={providerOptions}
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner prestataire..."
                      search={{
                        notFoundText: "Pas de prestataire trouvé.",
                        commandInputPlaceHolder: "Chercher prestataire...",
                      }}
                    />
                  ) : (
                    <>
                      <Combobox
                        options={teacherOptions}
                        value={field.value}
                        onChange={value => field.onChange(value)}
                        placeholder="Selectionner professeur..."
                        search={{
                          notFoundText: "Pas de professeur trouvé.",
                          commandInputPlaceHolder: "Chercher professeur...",
                        }}
                        disabled={isUndefinedTeacher}
                      />
                      <div className="flex items-center space-x-3 space-y-0">
                        <Checkbox
                          checked={isUndefinedTeacher}
                          onCheckedChange={checked => setValue("isUndefinedTeacher", Boolean(checked))}
                          id="undefined-teacher"
                        />
                        <label htmlFor="undefined-teacher">
                          <span className="text-sm">Professeur principal non défini</span>
                        </label>
                      </div>
                    </>
                  )}
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Adresse du lieu des cours</FormLabel>
                <FormControl>
                  <Input placeholder="Ex: 111 rue de vaugirard" {...field} disabled={isUndefinedTeacher} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="additionalAddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Code postal, Ville</FormLabel>
                <FormControl>
                  <Input placeholder="Ex: 75006 Paris" {...field} disabled={isUndefinedTeacher} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {isProvider ? (
            <>
              <FormField
                control={form.control}
                name="reviewedProviderBatchId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Session</FormLabel>
                    <FormControl>
                      <Combobox
                        options={providerBatchesOptions}
                        value={field.value}
                        onChange={value => field.onChange(value)}
                        placeholder="Selectionner session..."
                        search={{
                          notFoundText: "Pas de session trouvée.",
                          commandInputPlaceHolder: "Chercher session...",
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="administrativeInternalTrainingId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Formation administrative</FormLabel>
                    <FormControl>
                      <Combobox
                        options={internalTrainingsOptions}
                        value={field.value}
                        onChange={value => field.onChange(value)}
                        placeholder="Selectionner formation..."
                        search={{
                          notFoundText: "Pas de formation trouvé.",
                          commandInputPlaceHolder: "Chercher formation...",
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          ) : (
            <FormField
              control={form.control}
              name="reviewedInternalTrainingId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Intitulé de la formation</FormLabel>
                  <FormControl>
                    <Combobox
                      options={internalTrainingsOptions}
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner formation..."
                      search={{
                        notFoundText: "Pas de formation trouvé.",
                        commandInputPlaceHolder: "Chercher formation...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <FormField
            control={form.control}
            name="level"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Niveau</FormLabel>
                  <RadioGroup onValueChange={value => field.onChange(value)} value={field.value} className="flex flex-col space-y-1">
                    {Object.values(TRAINING_LEVEL).map((v, index) => (
                      <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                        <FormControl>
                          <RadioGroupItem value={v} />
                        </FormControl>
                        <FormLabel className="font-normal">{TRAINING_LEVEL_LABELS[v]}</FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormItem>
            <FormLabel>Lettre de motivation</FormLabel>
            <FormControl>
              <RichText
                value={coverLetter}
                onChange={handleCoverLetterChange}
                onReset={handleResetCoverLetter}
                hasError={Boolean(
                  (errors.coverLetter && errors.coverLetter.message) || (errors.coverLetterText && errors.coverLetterText.message)
                )}
              />
            </FormControl>
            <p className="text-sm font-medium text-destructive">
              {(errors.coverLetter && errors.coverLetter.message) || (errors.coverLetterText && errors.coverLetterText.message)}
            </p>
          </FormItem>
          <PersonalizedEducationalProject fundingRequest={fundingRequest} />
          <div className="flex justify-center gap-4">
            <FormField
              control={form.control}
              name="trainingPrice"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Prix de la formation</FormLabel>
                  <FormControl className="w-[150px]">
                    <Input type="number" step="1" min={0} max={10_000} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="operaOffCommission"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Frais OF (HT)</FormLabel>
                  <FormControl className="w-[150px]">
                    <Input type="number" step="1" min={0} max={4000} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <TrainingDaysForm
            studentId={fundingRequest.student.id}
            now={now}
            dateOptions={dateOptions}
            currentMonth={month}
            onChangeMonth={(value: string) => setMonth(value)}
          />
          <FormField
            control={form.control}
            name="reviewedSpecificAccommodation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Mesures prises si aménagement spécifique</FormLabel>
                <div className="flex items-center space-x-3 space-y-0">
                  <Checkbox
                    checked={needsAccommodation}
                    onCheckedChange={checked => setNeedsAccommodation(Boolean(checked))}
                    id="reviewedSpecificAccommodation"
                  />
                  <label htmlFor="reviewedSpecificAccommodation">
                    <FormDescription>Besoin d'un aménagement spécifique pour la formation ?</FormDescription>
                  </label>
                </div>
                <FormControl>
                  <Textarea
                    maxLength={MAX_SPECIFIC_ACCOMODATION_CHARACTERS}
                    {...field}
                    disabled={!needsAccommodation}
                    placeholder={needsAccommodation ? "Saisir votre aménagement spécifique" : undefined}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="travelExpenses"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Frais de déplacement</FormLabel>
                <div className="flex items-center space-x-3 space-y-0">
                  <Checkbox
                    checked={needsTravelExpenses}
                    onCheckedChange={checked => setNeedsTravelExpenses(Boolean(checked))}
                    id="travelExpenses"
                  />
                  <label htmlFor="travelExpenses">
                    <FormDescription>Dossier comprenant des frais de déplacement</FormDescription>
                  </label>
                </div>
                <FormControl className="w-[200px]">
                  <Input type="number" step="1" min={0} max={4000} {...field} disabled={!needsTravelExpenses} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="adminCommentary"
            render={({ field }) => (
              <FormItem className="col-span-3">
                <FormLabel>Commentaire ADMIN</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="mt-4 flex justify-center">
            <div className="flex flex-col">
              {processing === "pending" ? (
                <div className="mr-3 flex items-center gap-3">
                  <Loader2 className="ml-2 animate-spin" size={24} />
                  <div className="">
                    <p className="mb-1 mt-2">Le dossier est en train d'être traité.</p>
                    <p> Veuillez patienter quelques secondes ...</p>
                  </div>
                </div>
              ) : (
                <>
                  <Button type="submit" disabled={!isDirty}>
                    Valider la demande
                  </Button>
                  <Button type="button" onClick={() => setIsModalOpen(true)} className="mt-5" variant="destructive">
                    Supprimer la demande (déconseillé)
                  </Button>
                </>
              )}
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};
