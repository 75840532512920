import { useEffect, useRef, useState } from "react";
import { formatDateToLocale, formatToCurrency } from "../../../../../../utils/formatting";
import { ColumnDef } from "@tanstack/react-table";
import { CustomCard } from "../../../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS } from "@academy-context/read/domain/types/admin/provider-batch-participation-payment";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ProviderBatchParticipationPaymentRow } from "@academy-context/read/application/use-cases/admin/provider-batch-participation-payment-list-retrieval/selectors/provider-batch-participation-payment-list-selectors";
import { ApproveProviderPaymentModal } from "@academy-context/primary/admin/shared/components/transfer/approve-provider-payment-modal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";
import { Button } from "@components/ui/button";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { RefuseProviderPaymentModal } from "@academy-context/primary/admin/shared/components/transfer/refuse-provider-payment-modal";
import { Eye } from "lucide-react";
import { ProviderPaymentDetailModal } from "@academy-context/primary/admin/shared/components/transfer/provider-payment-detail-modal";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";

const statusMap: Record<PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS, { text: string; color: string }> = {
  [PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS.TO_PAY]: { text: "À payer", color: "before:bg-gray-400" },
  [PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS.PAID]: { text: "Payé", color: "before:bg-green-500" },
  [PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS.REFUSED]: { text: "Refusé", color: "before:bg-red-500" },
};

const StatusText = ({ status }: { status: PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS }) => {
  const { text, color } = statusMap[status];
  return (
    <div>
      <span className={`before:mr-2 before:inline-block before:size-3 before:rounded-full ${color}`}>{text}</span>
    </div>
  );
};

const columns: ColumnDef<ProviderBatchParticipationPaymentRow>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => formatDateToLocale(row.original.creationDate),
    meta: {
      title: "Enregistré le",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "provider.tag",
    header: () => "Bénéficiaire",
    meta: {
      title: "Bénéficiaire",
      width: "90px",
    },
  },
  {
    accessorKey: "label",
    header: () => "Libellé",
    meta: {
      title: "Libellé",
    },
  },
  {
    accessorKey: "invoice.number",
    header: () => "N° Facture",
    meta: {
      title: "N° Facture",
      width: "90px",
    },
  },
  {
    accessorKey: "payment.amount",
    header: () => "Montant",
    cell: ({ row }) => formatToCurrency(row.original.payment.amount),
    meta: {
      title: "Montant",
      width: "90px",
    },
  },
  {
    accessorKey: "payment.amountPaid",
    header: () => "Montant versé",
    cell: ({ row }) => (row.original.payment.amountPaid != null ? formatToCurrency(row.original.payment.amountPaid) : ""),
    meta: {
      title: "Montant versé",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "payment.organization",
    header: () => "Société",
    cell: ({ row }) => {
      const {
        payment: { organization },
      } = row.original;
      return organization ? ORGANIZATION_NAME[organization].short : null;
    },
    meta: {
      title: "Société",
    },
    enableSorting: true,
  },
  {
    accessorKey: "status",
    header: () => "Statut",
    cell: ({ row }) => <div className="flex items-center justify-start pl-2">{StatusText({ status: row.original.status })}</div>,
    meta: {
      title: "Statut",
      width: "90px",
    },
  },
];

interface Props {
  providerPayments: ProviderBatchParticipationPaymentRow[];
}

export const ProviderPaymentTable = ({ providerPayments }: Props) => {
  const [dataColumns, setDataColumns] = useState<ColumnDef<ProviderBatchParticipationPaymentRow>[]>([...columns]);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState<boolean>(false);
  const [isRefusalModalOpen, setIsRefusalModalOpen] = useState<boolean>(false);
  const selectedPayment = useRef<Nullable<ProviderBatchParticipationPaymentRow>>(null);

  useEffect(() => {
    const actionColumn: ColumnDef<ProviderBatchParticipationPaymentRow> = {
      accessorKey: "id",
      header: () => "",
      enableSorting: false,
      cell: ({ row }) => {
        const payment = row.original;

        const shouldDisplay = payment.status === PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS.TO_PAY;

        if (!shouldDisplay)
          return (
            <div
              className="flex items-center justify-center"
              onClick={() => {
                selectedPayment.current = row.original;
                setIsDetailModalOpen(true);
              }}
            >
              <Eye className="size-4 cursor-pointer" />
            </div>
          );
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-[12px] p-0 align-middle">
                <span className="sr-only">Ouvrir menu</span>
                <DotsHorizontalIcon className="size-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onClick={() => {
                  selectedPayment.current = row.original;
                  setIsApprovalModalOpen(true);
                }}
              >
                Valider
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  selectedPayment.current = row.original;
                  setIsRefusalModalOpen(true);
                }}
              >
                Refuser
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      meta: {
        width: "50px",
      },
    };
    setDataColumns([...columns, actionColumn]);
  }, [providerPayments]);

  return (
    <CustomCard title="Paiement des prestataires">
      {selectedPayment.current && (
        <>
          <ApproveProviderPaymentModal
            payment={selectedPayment.current}
            isOpen={isApprovalModalOpen}
            onClose={() => setIsApprovalModalOpen(false)}
          />
          <RefuseProviderPaymentModal
            payment={selectedPayment.current}
            isOpen={isRefusalModalOpen}
            onClose={() => setIsRefusalModalOpen(false)}
          />
          <ProviderPaymentDetailModal
            payment={selectedPayment.current}
            isOpen={isDetailModalOpen}
            onClose={() => setIsDetailModalOpen(false)}
          />
        </>
      )}

      <DataTable
        columns={dataColumns}
        data={providerPayments}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher un paiement"
        pageSize={50}
        displayDataViewOptions
      />
    </CustomCard>
  );
};
