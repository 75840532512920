import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { FUNDING_REQUEST_ERROR_REASONS } from "@academy-context/read/domain/types/admin/funding-request";

export const updateFundingRequestError = createAsyncThunk<
  void,
  { id: string; error: FUNDING_REQUEST_ERROR_REASONS; sendNotificationToStudent: boolean },
  { extra: Partial<Dependencies> }
>(
  "fundingRequests/updateFundingRequestError",
  async (
    { id, error, sendNotificationToStudent }: { id: string; error: FUNDING_REQUEST_ERROR_REASONS; sendNotificationToStudent: boolean },
    { extra: { fundingRequestRepository } }
  ) => {
    return fundingRequestRepository!.updateErrorReason(id, { error, sendNotificationToStudent });
  }
);

export const resetUpdateFundingRequestError = createAction("fundingRequests/resetUpdateFundingRequestError");
