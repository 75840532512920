import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const refreshUserInvitation = createAsyncThunk<void, string, { extra: Partial<Dependencies> }>(
  "users/refreshUserInvitation",
  async (email: string, { extra: { adminUserInvitationGateway } }) => {
    return adminUserInvitationGateway!.refreshUserInvitation(email);
  }
);

export const resetRefreshUserInvitation = createAction("users/resetRefreshUserInvitation");
