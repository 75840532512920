import React from "react";
import ReactDOM from "react-dom/client";
import App from "src/App";
import { Provider } from "react-redux";
import { initReduxStore } from "@redux/store";
import { InMemoryFundingRequestRepository } from "@shared-kernel/secondary/shared/funding-request-repository/in-memory-funding-request-repository";
import { InMemoryAdminBudgetRepository } from "@shared-kernel/secondary/admin/budget-repository/in-memory-admin-budget-repository";
import { InMemoryTeacherLessonGateway } from "@academy-context/shared/secondary/repository/teacher/in-memory-lesson-gateway";
import { InMemoryTransferGateway } from "@academy-context/shared/secondary/repository/in-memory-transfer-gateway";
import { teacherListElement, teachers } from "./mocks/teachers";
import { fundingRequests } from "./mocks/funding-request";
import { budgets } from "./mocks/budgets";
import { lessons } from "./mocks/lessons";
import { transfers } from "./mocks/transfers";
import { BackendUserGateway } from "@user-management-context/shared/secondary/repository/user-repository/backend-user-gateway";
import { AdminBackendUserInvitationAdapter } from "@user-management-context/shared/secondary/repository/admin/user-invitation-adapter/backend-user-invitation-adapter";
import { CookieTokenStorageGateway } from "@shared-kernel/secondary/cookies-token-storage-gateway";
import { students, studentsWithBalance } from "./mocks/students";
import { InMemoryUserGateway } from "@user-management-context/shared/secondary/repository/user-repository/in-memory-user-gateway";
import { users } from "./mocks/users";
import { AdminBackendBudgetRepository } from "@shared-kernel/secondary/admin/budget-repository/admin-backend-budget-repository";
import { AdminBackendLessonGateway } from "@academy-context/shared/secondary/repository/admin/admin-backend-lesson-gateway";
import { AdminBackendStudentGateway } from "@user-management-context/shared/secondary/repository/admin/admin-backend-student-gateway";
import { AdminBackendTeacherGateway } from "@user-management-context/shared/secondary/repository/admin/admin-backend-teacher-gateway";
import { InMemoryAdminLessonGateway } from "@academy-context/shared/secondary/repository/admin/in-memory-admin-lesson-gateway";
import { InMemoryAdminStudentGateway } from "@user-management-context/shared/secondary/repository/admin/in-memory-admin-student-gateway";
import { InMemoryAdminTeacherGateway } from "@user-management-context/shared/secondary/repository/admin/in-memory-admin-teacher-gateway";
import { AdminBackendTransferGateway } from "@academy-context/shared/secondary/repository/admin/admin-backend-transfer-gateway";
import { BackendTeacherGateway } from "@user-management-context/shared/secondary/repository/teacher/backend-teacher-gateway";
import { BackendTeacherLessonGateway } from "@academy-context/shared/secondary/repository/teacher/backend-lesson-gateway";
import { BackendTeacherStudentGateway } from "@user-management-context/shared/secondary/repository/teacher/backend-student-gateway";
import { BackendStudentGateway } from "@user-management-context/shared/secondary/repository/student/backend-student-gateway";
import { StudentBackendLessonGateway } from "@academy-context/shared/secondary/repository/student/backend-lesson-gateway";
import { InMemoryAdminBalanceAdjustmentRepository } from "@shared-kernel/secondary/admin/balance-adjustment-repository/in-memory-admin-balance-adjustment-repository";
import { balanceAdjustments } from "./mocks/balance-adjustments";
import { BackendAdminBalanceAdjustmentRepository } from "@shared-kernel/secondary/admin/balance-adjustment-repository/backend-admin-balance-adjustment-repository";
import { StudentBackendBalanceAdjustmentRepository } from "@shared-kernel/secondary/student/balance-adjustment-repository/backend-balance-adjustment-repository";
import { AdminBackendConventionGateway } from "@academy-context/shared/secondary/repository/admin/admin-backend-convention-gateway";
import { InMemoryAdminConventionGateway } from "@academy-context/shared/secondary/repository/admin/in-memory-admin-convention-gateway";
import { InMemoryTeacherConventionGateway } from "@academy-context/shared/secondary/repository/teacher/in-memory-teacher-convention-gateway";
import { BackendTeacherConventionGateway } from "@academy-context/shared/secondary/repository/teacher/backend-convention-gateway";
import { InMemoryStudentConventionGateway } from "@academy-context/shared/secondary/repository/student/in-memory-student-convention-gateway";
import { BackendStudentConventionGateway } from "@academy-context/shared/secondary/repository/student/backend-convention-gateway";
import { InMemoryStudentBudgetGateway } from "@academy-context/shared/secondary/repository/student/in-memory-student-budget-gateway";
import { BackendStudentBudgetGateway } from "@academy-context/shared/secondary/repository/student/backend-budget-gateway";
import { BackendFundingRequestRepository } from "@shared-kernel/secondary/shared/funding-request-repository/backend-funding-request-repository";
import { BackendTrainingFollowUpGateway } from "@academy-context/shared/secondary/repository/backend-training-follow-up-gateway";
import { initCommonAdapters } from "./common-adapters";
import { RealNumberGenerator } from "@shared-kernel/secondary/number-generator/real-number-generator";
import { BackendRefundRequestGateway } from "@academy-context/shared/secondary/repository/student/backend-refund-request-gateway";
import { BackendCollaborationRequestGateway } from "@academy-context/shared/secondary/repository/teacher/backend-collaboration-request-gateway";
import { InMemoryProviderRepository } from "@user-management-context/shared/secondary/repository/provider/provider-repository/in-memory-provider-repository";
import { StudentBackendProviderRepository } from "@user-management-context/shared/secondary/repository/student/provider-repository/student-backend-provider-repository";
import { InMemoryAdminProviderRepository } from "@user-management-context/shared/secondary/repository/admin/provider-repository/in-memory-provider-repository";
import { AdminBackendProviderRepository } from "@user-management-context/shared/secondary/repository/admin/provider-repository/admin-backend-provider-repository";
import { providerBatches } from "src/mocks/provider-batches";
import { providers } from "src/mocks/providers";
import { providerTrainings } from "src/mocks/provider-trainings";
import { BackendProviderRepository } from "@user-management-context/shared/secondary/repository/provider/provider-repository/backend-provider-repository";
import { RealDateProvider } from "@shared-kernel/secondary/number-generator/real-date-provider";
import { InMemoryAdminInternalTrainingRepository } from "@shared-kernel/secondary/admin/internal-training-repository/in-memory-internal-training-repository";
import { AdminBackendInternalTrainingRepository } from "@shared-kernel/secondary/admin/internal-training-repository/admin-backend-internal-training-repository";
import { ProviderBackendTrainingFollowUpRepository } from "@shared-kernel/secondary/provider/training-follow-up-repository/provider-backend-training-follow-up-repository";
import { InMemoryStudentInternalTrainingRepository } from "@shared-kernel/secondary/student/internal-training-repository/in-memory-internal-training-repository";
import { StudentBackendInternalTrainingRepository } from "@shared-kernel/secondary/student/internal-training-repository/student-backend-internal-training-repository";
import { InMemoryDocumentUploadProvider } from "@shared-kernel/secondary/shared/document-upload-provider/in-memory-document-upload-provider";
import { BackendDocumentUploadProvider } from "@shared-kernel/secondary/shared/document-upload-provider/backend-document-upload-provider";

let adminStudentGateway;
let adminBalanceAdjustmentRepository;
let adminUserInvitationGateway;
let adminBudgetRepository;
let adminTeacherGateway;
let adminLessonGateway;
let transferGateway;
let adminConventionGateway;
let providerRepository;
let userGateway;
let adminProviderRepository;
let adminInternalTrainingRepository;
let studentInternalTrainingRepository;
let documentUploadProvider;
const tokenStorageGateway = new CookieTokenStorageGateway();

let teacherLessonGateway;
const teacherGateway = new BackendTeacherGateway(tokenStorageGateway);
const teacherStudentGateway = new BackendTeacherStudentGateway(tokenStorageGateway);
let teacherConventionGateway;

const studentGateway = new BackendStudentGateway(tokenStorageGateway);
const studentLessonGateway = new StudentBackendLessonGateway(tokenStorageGateway);
const studentBalanceAdjustmentRepository = new StudentBackendBalanceAdjustmentRepository(tokenStorageGateway);
let studentConventionGateway;
let studentBudgetGateway;
let fundingRequestRepository;

const trainingFollowUpGateway = new BackendTrainingFollowUpGateway(tokenStorageGateway);
const refundRequestGateway = new BackendRefundRequestGateway(tokenStorageGateway);
const collaborationRequestGateway = new BackendCollaborationRequestGateway(tokenStorageGateway);
const studentProviderRepository = new StudentBackendProviderRepository(tokenStorageGateway);
const providerTrainingFollowUpRepository = new ProviderBackendTrainingFollowUpRepository(tokenStorageGateway);

if ("demo" === import.meta.env["VITE_SERVICE_ENV"]) {
  adminStudentGateway = new InMemoryAdminStudentGateway();
  adminStudentGateway.feedWith(...students);
  adminStudentGateway.feedWithStudentListElement(...studentsWithBalance);

  userGateway = new InMemoryUserGateway();
  userGateway.feedWith(...users);

  adminBudgetRepository = new InMemoryAdminBudgetRepository();
  adminBudgetRepository.feedWith(...budgets);

  adminTeacherGateway = new InMemoryAdminTeacherGateway();
  adminTeacherGateway.feedWith(...teachers);
  adminTeacherGateway.feedWithTeacherListElement(...teacherListElement);

  adminLessonGateway = new InMemoryAdminLessonGateway();
  adminLessonGateway.feedWith(...lessons);

  transferGateway = new InMemoryTransferGateway();
  transferGateway.feedWith(...transfers);

  adminBalanceAdjustmentRepository = new InMemoryAdminBalanceAdjustmentRepository();
  adminBalanceAdjustmentRepository.feedWith(...balanceAdjustments);
  adminConventionGateway = new InMemoryAdminConventionGateway();

  teacherConventionGateway = new InMemoryTeacherConventionGateway();
  studentConventionGateway = new InMemoryStudentConventionGateway();
  studentBudgetGateway = new InMemoryStudentBudgetGateway();

  // TODO if I have time
  // teacherGateway = new BackendTeacherGateway({} as CookieTokenStorageGateway);
  // teacherStudentGateway = new BackendTeacherStudentGateway({} as CookieTokenStorageGateway);
  // studentGateway = new BackendStudentGateway({} as CookieTokenStorageGateway);
  // studentLessonGateway = new StudentBackendLessonGateway({} as CookieTokenStorageGateway);
  // studentBalanceAdjustmentRepository = new StudentBackendBalanceAdjustmentGateway({} as CookieTokenStorageGateway);

  teacherLessonGateway = new InMemoryTeacherLessonGateway();
  teacherLessonGateway.feedWith(...lessons);

  fundingRequestRepository = new InMemoryFundingRequestRepository();
  fundingRequestRepository.feedWith(...fundingRequests);

  adminProviderRepository = new InMemoryAdminProviderRepository();
  adminProviderRepository.feedWithProviders(...providers);
  adminProviderRepository.feedWithTrainings(...providerTrainings);
  providerRepository = new InMemoryProviderRepository();
  providerRepository.feedWith(...providerBatches);
  adminInternalTrainingRepository = new InMemoryAdminInternalTrainingRepository();
  studentInternalTrainingRepository = new InMemoryStudentInternalTrainingRepository();

  documentUploadProvider = new InMemoryDocumentUploadProvider();
} else {
  userGateway = new BackendUserGateway(tokenStorageGateway);

  adminBalanceAdjustmentRepository = new BackendAdminBalanceAdjustmentRepository(tokenStorageGateway);
  adminUserInvitationGateway = new AdminBackendUserInvitationAdapter(tokenStorageGateway);
  adminStudentGateway = new AdminBackendStudentGateway(tokenStorageGateway);
  adminBudgetRepository = new AdminBackendBudgetRepository(tokenStorageGateway);
  adminTeacherGateway = new AdminBackendTeacherGateway(tokenStorageGateway);
  adminLessonGateway = new AdminBackendLessonGateway(tokenStorageGateway);
  transferGateway = new AdminBackendTransferGateway(tokenStorageGateway);
  adminConventionGateway = new AdminBackendConventionGateway(tokenStorageGateway);

  // teacherGateway = new BackendTeacherGateway(tokenStorageGateway);
  // teacherStudentGateway = new BackendTeacherStudentGateway(tokenStorageGateway);
  // studentGateway = new BackendStudentGateway(tokenStorageGateway);
  // studentLessonGateway = new StudentBackendLessonGateway(tokenStorageGateway);
  // studentBalanceAdjustmentRepository = new StudentBackendBalanceAdjustmentGateway(tokenStorageGateway);

  teacherLessonGateway = new BackendTeacherLessonGateway(tokenStorageGateway);
  teacherConventionGateway = new BackendTeacherConventionGateway(tokenStorageGateway);

  studentConventionGateway = new BackendStudentConventionGateway(tokenStorageGateway);
  studentBudgetGateway = new BackendStudentBudgetGateway(tokenStorageGateway);
  fundingRequestRepository = new BackendFundingRequestRepository(tokenStorageGateway);
  providerRepository = new BackendProviderRepository(tokenStorageGateway);
  adminProviderRepository = new AdminBackendProviderRepository(tokenStorageGateway);
  adminInternalTrainingRepository = new AdminBackendInternalTrainingRepository(tokenStorageGateway);
  studentInternalTrainingRepository = new StudentBackendInternalTrainingRepository(tokenStorageGateway);
  documentUploadProvider = new BackendDocumentUploadProvider(tokenStorageGateway);
}

const store = initReduxStore({
  adminStudentGateway,
  fundingRequestRepository,
  userGateway,
  adminTeacherGateway,
  adminBudgetRepository,
  teacherLessonGateway,
  transferGateway,
  adminUserInvitationGateway,
  adminLessonGateway,
  teacherGateway,
  teacherStudentGateway,
  studentGateway,
  studentLessonGateway,
  adminBalanceAdjustmentRepository,
  studentBalanceAdjustmentRepository,
  adminConventionGateway,
  teacherConventionGateway,
  studentConventionGateway,
  studentBudgetGateway,
  trainingFollowUpGateway,
  refundRequestGateway,
  collaborationRequestGateway,
  adminProviderRepository,
  providerRepository,
  studentProviderRepository,
  adminInternalTrainingRepository,
  providerTrainingFollowUpRepository,
  studentInternalTrainingRepository,
  documentUploadProvider,
});

initCommonAdapters({
  numberGenerator: new RealNumberGenerator(),
  dateProvider: new RealDateProvider(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const root = ReactDOM.createRoot(document.getElementById("main") as HTMLElement);

root.render(
  // Be careful, this mode renders elements twice in local dev
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
