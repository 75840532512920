import { createReducer } from "@reduxjs/toolkit";
import { createBalanceAdjustment, resetCreateBalanceAdjustment } from "./create-balance-adjustment";
import { CreationState } from "@redux/app-state";

const initialState: CreationState = { justCreatedId: null, processing: "idle" };

export const createBalanceAdjustmentReducer = createReducer<CreationState>(initialState, builder => {
  builder.addCase(createBalanceAdjustment.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedId: payload,
      processing: "success",
    };
  });
  builder.addCase(createBalanceAdjustment.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(createBalanceAdjustment.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetCreateBalanceAdjustment, () => {
    return initialState;
  });
});
