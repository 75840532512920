import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { StudentProviderEnrollment } from "@academy-context/read/domain/types/student/provider-enrollment";
import {
  resetStudentRetrieveEligibleProviderEnrollment,
  studentRetrieveEligibleProviderEnrollment,
} from "@academy-context/read/application/use-cases/student/eligible-provider-enrollment-retrieval/retrieve-eligible-provider-enrollment";

const initialState: RetrievalState<Nullable<StudentProviderEnrollment>> = { data: null, fetching: "pending" };

export const studentRetrieveEligibleProviderEnrollmentReducer = createReducer<RetrievalState<Nullable<StudentProviderEnrollment>>>(
  initialState,
  builder => {
    builder.addCase(studentRetrieveEligibleProviderEnrollment.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(studentRetrieveEligibleProviderEnrollment.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(studentRetrieveEligibleProviderEnrollment.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
    builder.addCase(resetStudentRetrieveEligibleProviderEnrollment, () => {
      return initialState;
    });
  }
);
