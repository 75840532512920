import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { ISSUE_TYPE } from "@user-management-context/write/domain/types/shared/issue-reporting";

export enum TIMELINE_EVENT_TITLE {
  PHONE_CALL = "Appel téléphonique",
  EMAIL_EXCHANGE = "Echange d'email",
  SMS = "SMS",
}

export enum TIMELINE_EVENT_TYPE {
  ADMIN_NOTE = "admin_note",
  ISSUE_REPORT = "issue_report",
}

export type TIMELINE_EVENT_DETAILS =
  | { type: TIMELINE_EVENT_TYPE.ADMIN_NOTE; payload: { description: string } }
  | { type: TIMELINE_EVENT_TYPE.ISSUE_REPORT; payload: { type: ISSUE_TYPE; description: string } };

export type StudentTimelineEvent = {
  id: string;
  studentId: string;
  title: string;
  eventDate: string;
  creationDate: string;
  author: {
    role: ROLES;
    accountId: string;
    name: string;
  };
  details: TIMELINE_EVENT_DETAILS;
};
