import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveCollaborationRequestList } from "@academy-context/read/application/use-cases/admin/collaboration-request-list-retrieval/retrieve-collaboration-request-list";
import { resetApproveCollaborationRequest } from "@academy-context/write/application/use-cases/admin/collaboration-request-approval/approve-collaboration-request";

export const selectCollaborationRequestApproval = (state: AppState) => state.collaborationRequestApproval;

export const useCollaborationRequestApproval = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectCollaborationRequestApproval);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Demande ou refus d'ajout pris en compte !" });
      dispatch(retrieveCollaborationRequestList());
      dispatch(resetApproveCollaborationRequest());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors du traitement de la demande d'ajout d'élève", variant: "destructive" });
    }
  }, [processing, dispatch, toast]);
};
