import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const uploadDocument = createAsyncThunk<string, { document: File }, { extra: Partial<Dependencies> }>(
  "documents/uploadDocument",
  async ({ document }: { document: File }, { extra: { documentUploadProvider } }) => {
    return documentUploadProvider!.uploadDocument(document);
  }
);

export const resetUploadDocument = createAction("documents/resetUploadDocument");
