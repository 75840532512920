import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../config/backend";
import { StudentInternalTrainingRepository } from "@shared-kernel/application/ports/student/student-internal-training-repository";
import { StudentInternalTraining } from "@academy-context/read/domain/types/student/internal-training";

export class StudentBackendInternalTrainingRepository extends HttpGateway implements StudentInternalTrainingRepository {
  private _route: string = "v1/student/internal-trainings";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async list(): Promise<StudentInternalTraining[]> {
    const { data: trainings } = await this._instance.get(this._route);
    return trainings;
  }
}
