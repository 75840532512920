import { isLoadingState, sortAlphabetically } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";

export const studentSelectInternalTrainingsRetrievalForForm = createSelector(
  [(state: AppState) => state.studentInternalTrainingsRetrieval],
  internalTrainingsRetrieval => {
    const { data, fetching } = internalTrainingsRetrieval;

    const formattedData = [...data]
      .sort((a, b) => sortAlphabetically(a.title, b.title))
      .map(training => ({ label: training.title, value: training.id }));

    const orderedData = data.map(training => ({
      ...training,
      questions: [...training.questions].sort((a, b) => a.order - b.order),
    }));

    return {
      internalTrainingsOptions: formattedData,
      rawInternalTrainings: orderedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
