import { DateInterval } from "@shared-kernel/core/types/date-interval";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

export enum PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS {
  TO_PAY = "to_pay",
  PAID = "paid",
  REFUSED = "refused",
}

export enum PROVIDER_BATCH_PARTICIPATION_PAYMENT_METHOD {
  MANUAL = "manual",
}

export interface ProviderBatchParticipationPayment {
  id: string;
  status: PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS;
  invoice: { url: string; number: string };
  creationDate: string;
  adminCommentary: Nullable<string>;
  student: {
    name: string;
    lastName: string;
  };
  provider: {
    tag: string;
    name: string;
    iban: string;
  };
  training: {
    tag: string;
    title: string;
  };
  batch: {
    interval: DateInterval;
  };
  payment: {
    amount: number;
    amountPaid: Nullable<number>;
    date: Nullable<string>;
    method: Nullable<PROVIDER_BATCH_PARTICIPATION_PAYMENT_METHOD>;
    reference: Nullable<string>;
    organization: Nullable<ORGANIZATION>;
  };
}
