import { createReducer } from "@reduxjs/toolkit";
import { StudentTeachersRetrievalState } from "@redux/app-state";
import { retrieveTeachers } from "./retrieve-teachers";

const initialState: StudentTeachersRetrievalState = { fetching: "pending", data: [] };

export const studentRetrieveTeachersReducer = createReducer<StudentTeachersRetrievalState>(initialState, builder => {
  builder.addCase(retrieveTeachers.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveTeachers.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveTeachers.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
