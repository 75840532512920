import { isLoadingState } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";
import { formatDateToLocale } from "@utils/formatting";

export type ProviderBatchParticipationPaymentRow = ReturnType<typeof selectProviderBatchParticipationPaymentList>["payments"][number];

export const selectProviderBatchParticipationPaymentList = createSelector(
  [(state: AppState) => state.providerBatchParticipationPaymentListRetrieval],
  providerBatchParticipationPaymentListRetrieval => {
    const { data, fetching } = providerBatchParticipationPaymentListRetrieval;

    const formattedData = [...data]
      .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())
      .map(payment => {
        return {
          ...payment,
          student: `${payment.student.name} ${payment.student.lastName}`,
          label: `${payment.training.tag} | ${formatDateToLocale(payment.batch.interval.start)} -> ${formatDateToLocale(
            payment.batch.interval.end
          )}`,
        };
      });

    return {
      payments: formattedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
