import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { jwtDecode } from "jwt-decode";
import { ReportAnIssueBody, UserGateway } from "@user-management-context/shared/application/ports//user-gateway";
import { User, UserWithAccessToken } from "@user-management-context/read/domain/types/user";
import { UserPassword, UpdateUserBody, UserBody } from "@user-management-context/write/domain/types/user";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../../config/backend";
import { CreateStudentAccountBody } from "@user-management-context/write/domain/types/admin/student";
import { Student } from "@user-management-context/read/domain/types/student/student";
import { TokenStatus } from "@user-management-context/shared/domain/types/enums/token";
import { Teacher } from "@user-management-context/read/domain/types/admin/teacher";
import { CreateTeacherAccountBody } from "@user-management-context/write/domain/types/teacher/teacher";
import { Admin } from "@user-management-context/read/domain/types/admin/admin";
import { UserVM } from "@user-management-context/read/domain/types/admin/user";
import { ProviderStudent } from "@user-management-context/read/domain/types/provider/student";
import { CreateProviderAccountBody } from "@user-management-context/write/domain/types/provider/provider";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";

export class BackendUserGateway extends HttpGateway implements UserGateway {
  private _publicRoute: string = "public/users/v1";
  private _oldRoute: string = "users/v1";
  private _route: string = "v1/users";
  private _adminRoute: string = "v1/admin";
  private _oldAdminRoute: string = "admin/users/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async login(body: UserBody): Promise<UserWithAccessToken> {
    const route = this._publicRoute + "/login";
    const {
      data: { accessToken },
    } = await this._instance.post(route, body);
    const userInfo: User = jwtDecode(accessToken);
    return { ...userInfo, accessToken } as UserWithAccessToken;
  }

  async update(body: UpdateUserBody): Promise<void> {
    const route = `${this._adminRoute}/profile`;
    await this._instance.put(route, body);
  }

  async resetPassword(body: { email: string }): Promise<void> {
    const route = this._publicRoute + "/passwords/reset";
    await this._instance.post(route, body);
  }

  async createAdminAccount(body: UserPassword): Promise<void> {
    const route = this._publicRoute + "/admins";
    await this._instance.post(route, body);
  }

  async createStudentAccount(body: CreateStudentAccountBody): Promise<void> {
    const route = this._publicRoute + "/students";
    await this._instance.post(route, body);
  }

  async createTeacherAccount(body: CreateTeacherAccountBody): Promise<void> {
    const route = this._publicRoute + "/teachers";
    await this._instance.post(route, body);
  }

  async updatePassword(body: UserPassword): Promise<void> {
    const route = this._publicRoute + "/passwords/update";
    await this._instance.put(route, body);
  }

  async profile(): Promise<User> {
    const { data: user } = await this._instance.get(`${this._oldRoute}/profile`);
    return user;
  }

  async publicStudentProfile(userId: string): Promise<Student> {
    const { data: student } = await this._instance.get(`${this._publicRoute}/${userId}/students`);
    return student;
  }

  async publicTeacherProfile(userId: string): Promise<Teacher> {
    const { data: student } = await this._instance.get(`${this._publicRoute}/${userId}/teachers`);
    return student;
  }

  async publicAdminProfile(userId: string): Promise<Admin> {
    const { data: student } = await this._instance.get(`${this._publicRoute}/${userId}/admins`);
    return student;
  }

  async verifyTokenValidity(userId: string, token: string): Promise<{ status: TokenStatus }> {
    const { data } = await this._instance.post(`${this._publicRoute}/token/validate`, { userId, token });
    return data;
  }

  async doesUserExist(email: string): Promise<void> {
    await this._instance.head(`${this._oldAdminRoute}/accounts/${email}`);
  }

  async isEmailUsed(email: string): Promise<void> {
    await this._instance.head(`${this._oldAdminRoute}/${email}`);
  }

  async retrieveUnsubscribedUserList(): Promise<UserVM[]> {
    const { data } = await this._instance.get(`${this._oldAdminRoute}?isSubscribed=false`);
    return data;
  }

  async uploadProfilePicture(file: File): Promise<string> {
    const formDataBody = new FormData();
    formDataBody.append("profile", file);

    const res = await this._instance.post(`${this._oldRoute}/profilePictures:upload`, formDataBody);
    return res.data;
  }

  async getStudentByEmail(email: string): Promise<ProviderStudent> {
    const { data } = await this._instance.get(`v1/provider/students/${email}`);
    return data;
  }

  async createProviderAccount(body: CreateProviderAccountBody): Promise<void> {
    const route = this._publicRoute + "/providers";
    await this._instance.post(route, body);
  }

  async publicProviderProfile(userId: string): Promise<Provider> {
    const { data: provider } = await this._instance.get(`${this._publicRoute}/${userId}/providers`);
    return provider;
  }

  async reportIssue(body: ReportAnIssueBody): Promise<void> {
    const route = `${this._route}/issues:report`;
    await this._instance.put(route, body);
  }
}
