import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "../../../../../../../redux/store";
import { SubmittedToFunderFundingRequestVM } from "../../../../../domain/types/admin/funding-request";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";

export const retrieveSubmittedToFunderFundingRequests = createAsyncThunk<
  SubmittedToFunderFundingRequestVM[],
  { funders: string[]; educationalAdvisors?: EDUCATIONAL_ADVISOR[] } | undefined,
  { extra: Partial<Dependencies> }
>(
  "fundingRequests/retrieveSubmittedToFunderFundingRequests",
  async (
    filters: { funders: string[]; educationalAdvisors?: EDUCATIONAL_ADVISOR[] } | undefined,
    { extra: { fundingRequestRepository } }
  ) => {
    const requests = await fundingRequestRepository!.getAll({
      status: ["submitted_to_funder"],
      funders: filters?.funders,
      educationalAdvisors: filters?.educationalAdvisors,
    });
    const response: SubmittedToFunderFundingRequestVM[] = [];
    for (const r of requests) {
      if (r.status === "submitted_to_funder") {
        response.push(r);
      }
    }
    return response;
  }
);
