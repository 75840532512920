import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";
import { StandardPayment } from "@academy-context/read/domain/types/admin/payment";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

export const transfers: StandardPayment[] = [
  {
    id: "1",
    from: "2022-01-29T00:00:00.000Z",
    to: "2022-01-30T00:00:00.000Z",
    teacher: {
      id: "1",
      name: "Clélia",
      lastName: "Timsit",
      siret: null,
      activityRegistrationNumber: null,
    },
    amount: {
      global: 50.8,
      byOrganization: {
        [ORGANIZATION.OPERA_OFF]: 50.8,
        [ORGANIZATION.LES_ATELIERS_OO]: 0,
      },
    },
    amountPaid: null,
    status: PaymentStatus.PENDING,
    reference: null,
    paymentDate: null,
    organization: null,
  },
  {
    id: "2",
    from: "2022-01-29T00:00:00.000Z",
    to: "2022-01-30T00:00:00.000Z",
    teacher: {
      id: "2",
      name: "Gérard",
      lastName: "Darmon",
      siret: null,
      activityRegistrationNumber: null,
    },
    amount: {
      global: 50.8,
      byOrganization: {
        [ORGANIZATION.OPERA_OFF]: 50.8,
        [ORGANIZATION.LES_ATELIERS_OO]: 0,
      },
    },
    amountPaid: 50.8,
    status: PaymentStatus.PAID,
    reference: "OperaOff_mai_2022_reymond",
    paymentDate: "2022-01-30T00:00:00.000Z",
    organization: ORGANIZATION.LES_ATELIERS_OO,
  },
  {
    id: "3",
    from: "2021-01-29T00:00:00.000Z",
    to: "2021-01-30T00:00:00.000Z",
    teacher: {
      id: "1",
      name: "Clélia",
      lastName: "Timsit",
      siret: null,
      activityRegistrationNumber: null,
    },
    amount: {
      global: 60.8,
      byOrganization: {
        [ORGANIZATION.OPERA_OFF]: 60.8,
        [ORGANIZATION.LES_ATELIERS_OO]: 0,
      },
    },
    amountPaid: null,
    status: PaymentStatus.PENDING,
    reference: null,
    paymentDate: null,
    organization: null,
  },
  {
    id: "4",
    from: "2022-03-29T00:00:00.000Z",
    to: "2022-03-30T00:00:00.000Z",
    teacher: {
      id: "2",
      name: "Gérard",
      lastName: "Darmon",
      siret: null,
      activityRegistrationNumber: null,
    },
    amount: {
      global: 90,
      byOrganization: {
        [ORGANIZATION.OPERA_OFF]: 90,
        [ORGANIZATION.LES_ATELIERS_OO]: 0,
      },
    },
    amountPaid: null,
    status: PaymentStatus.PENDING,
    paymentDate: null,
    organization: null,
    reference: null,
  },
  {
    id: "5",
    from: "2022-04-30T23:59:59.999Z",
    to: "2022-04-30T23:59:59.999Z",
    teacher: {
      id: "2",
      name: "Gérard",
      lastName: "Darmon",
      siret: null,
      activityRegistrationNumber: null,
    },
    amount: {
      global: 90,
      byOrganization: {
        [ORGANIZATION.OPERA_OFF]: 90,
        [ORGANIZATION.LES_ATELIERS_OO]: 0,
      },
    },
    amountPaid: null,
    status: PaymentStatus.PENDING,
    paymentDate: null,
    organization: null,
    reference: null,
  },
  {
    id: "6",
    from: "2022-05-01T00:00:00.000Z",
    to: "2022-05-30T00:00:00.000Z",
    amount: {
      global: 90,
      byOrganization: {
        [ORGANIZATION.OPERA_OFF]: 90,
        [ORGANIZATION.LES_ATELIERS_OO]: 0,
      },
    },
    teacher: {
      id: "2",
      name: "Gérard",
      lastName: "Darmon",
      siret: null,
      activityRegistrationNumber: null,
    },
    amountPaid: null,
    status: PaymentStatus.PENDING,
    paymentDate: null,
    organization: null,
    reference: null,
  },
  {
    id: "7",
    from: "2022-06-30T23:59:59.999Z",
    to: "2022-06-30T23:59:59.999Z",
    amount: {
      global: 90,
      byOrganization: {
        [ORGANIZATION.OPERA_OFF]: 90,
        [ORGANIZATION.LES_ATELIERS_OO]: 0,
      },
    },
    teacher: {
      id: "2",
      name: "Gérard",
      lastName: "Darmon",
      siret: null,
      activityRegistrationNumber: null,
    },
    amountPaid: null,
    status: PaymentStatus.PENDING,
    paymentDate: null,
    organization: null,
    reference: null,
  },
  {
    id: "8",
    from: "2022-07-01T00:00:00.000Z",
    to: "2022-07-30T00:00:00.000Z",
    amount: {
      global: 90,
      byOrganization: {
        [ORGANIZATION.OPERA_OFF]: 90,
        [ORGANIZATION.LES_ATELIERS_OO]: 0,
      },
    },
    amountPaid: null,
    teacher: {
      id: "2",
      name: "Gérard",
      lastName: "Darmon",
      siret: null,
      activityRegistrationNumber: null,
    },
    status: PaymentStatus.PENDING,
    paymentDate: null,
    organization: null,
    reference: null,
  },
];
