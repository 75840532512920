import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

export interface TrainingFollowUpSession {
  id: string;
  studentSignatureUrl: Nullable<string>;
  teacherSignatureUrl: Nullable<string>;
  scheduledDate: string;
  number: number;
}

export interface TrainingFollowUpObjective {
  id: string;
  title: string;
  quantifiableObjective: string;
  done: Nullable<string>;
  number: number;
}

export interface AdminTrainingFollowUpObjective {
  id: string;
  title: string;
  quantifiableObjective: string;
  doneByStudent: Nullable<string>;
  doneByTeacher: Nullable<string>;
  number: number;
}

interface TrainingFollowUpEducationalAdvisor {
  id: string;
  name: string;
  type: EDUCATIONAL_ADVISOR;
}

export interface Student {
  id: string;
  name: string;
  lastName: string;
  email: string;
  mobile: string;
  profilePictureUrl: Nullable<string>;
}

export interface TrainingFollowUpListVM {
  id: string;
  date: string;
  title: string;
  // Only filled if educational advisor is provider
  providerBatchTitle: Nullable<string>;
  studentDetails: Student;
  educationalAdvisor: TrainingFollowUpEducationalAdvisor;
  isAvailableAction: boolean;
}

export interface StudentTrainingFollowUpVM {
  id: string;
  date: string;
  title: string;
  student: {
    name: string;
    lastName: string;
  };
  educationalAdvisor: string;
  externalFundingRequestId: string;
  attendanceSheetsStatus: ACTION_URGENCY_LEVEL;
  preTrainingSurveyStatus: ACTION_URGENCY_LEVEL;
  postTrainingSurveyStatus: ACTION_URGENCY_LEVEL;
  objectivesStatus: ACTION_URGENCY_LEVEL;
  trainingInvitationUrl: string;
  attendanceCertificateUrl: Nullable<string>;
  completionCertificateUrl: Nullable<string>;
}

export interface EducationalAdvisorTrainingFollowUpVM {
  id: string;
  date: string;
  title: string;
  student: string;
  externalFundingRequestId: string;
  attendanceSheetsStatus: ACTION_URGENCY_LEVEL;
  objectivesStatus: ACTION_URGENCY_LEVEL;
  convention: {
    signed: boolean;
    url: string;
  };
}

export enum ADMIN_ACTION_URGENCY_LEVEL {
  NONE = "none",
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export interface AdminTrainingFollowUpListVM {
  id: string;
  date: string;
  internshipEndDate: string;
  title: string;
  availableActionUrgency: {
    convention: ADMIN_ACTION_URGENCY_LEVEL;
    studentSession: ADMIN_ACTION_URGENCY_LEVEL;
    teacherSession: ADMIN_ACTION_URGENCY_LEVEL;
    surveys: {
      preTraining: ADMIN_ACTION_URGENCY_LEVEL;
      postTraining: ADMIN_ACTION_URGENCY_LEVEL;
    };
    studentObjectives: ADMIN_ACTION_URGENCY_LEVEL;
    teacherObjectives: ADMIN_ACTION_URGENCY_LEVEL;
  };
  student: string;
  educationalAdvisor: string;
  isDunningProcessPaused: boolean;
  organization: ORGANIZATION;
}

export enum ACTION_URGENCY_LEVEL {
  NOT_AVAILABLE = "not_available",
  LOW = "low",
  HIGH = "high",
  DONE = "done",
}

export interface AdminTrainingFollowUpVM {
  id: string;
  date: string;
  title: string;
  afdasId: string;
  student: {
    name: string;
    attendanceSheetsStatus: ACTION_URGENCY_LEVEL;
    objectivesStatus: ACTION_URGENCY_LEVEL;
    preTrainingSurveyStatus: ACTION_URGENCY_LEVEL;
    postTrainingSurveyStatus: ACTION_URGENCY_LEVEL;
    trainingInvitationUrl: Nullable<string>;
    attendanceCertificateUrl: Nullable<string>;
    completionCertificateUrl: Nullable<string>;
  };
  teacher: {
    name: string;
    attendanceSheetsStatus: ACTION_URGENCY_LEVEL;
    objectivesStatus: ACTION_URGENCY_LEVEL;
    convention: {
      unsignedUrl: string;
      signedUrl: Nullable<string>;
    };
  };
}
