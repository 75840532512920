import { Nullable } from "@shared-kernel/core/types/nullable";
import { FUNDER } from "@academy-context/shared/domain/types/enums/funders";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

export const FUNDER_OPTIONS: Record<FUNDER, string> = {
  [FUNDER.AFDAS]: "AFDAS",
  [FUNDER.ATLAS]: "ATLAS",
  [FUNDER.FIFPL]: "FIFPL",
  [FUNDER.FRANCE_TRAVAIL]: "FRANCE TRAVAIL",
  [FUNDER.AGEFICE]: "AGEFICE",
  [FUNDER.AKTO]: "AKTO",
};

export interface Budget {
  id: string;
  obtentionDate: string;
  internshipEndDate: string;
  studentId: string;
  funder: FUNDER;
  allocatedAmount: number;
  operaOffCommission: number;
  expirationTimeInMonths: Nullable<number>;
  createdAt: string;
  commentary: Nullable<string>;
  externalFundingRequestId: Nullable<string>;
  fundingRequestId: string;
}

export interface AdminBudgetListVM {
  id: string;
  creationDate: string;
  internshipEndDate: string;
  student: string;
  funder: FUNDER;
  allocatedAmount: number;
  operaOffCommission: number;
  totalAmount: number;
  commentary: Nullable<string>;
  externalFundingRequestId: Nullable<string>;
  organization: ORGANIZATION;
}

export type AdminBudgetExtended = AdminBudgetListVM & { localeDate: string };
