import { object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { InviteStandardUserBody } from "@user-management-context/shared/application/ports/user-invitation-port";

export type FormInputs = {
  email: Nullable<{ label: string; value: string }>;
  role: ROLES.PROVIDER | ROLES.STUDENT | ROLES.TEACHER;
};

export const ROLES_MAP = {
  [ROLES.ADMIN]: "Administrateur",
  [ROLES.STUDENT]: "Elève",
  [ROLES.TEACHER]: "Professeur",
  [ROLES.PROVIDER]: "Prestataire",
};

export const defaultValues: {
  email: { label: string; value: string };
  role: ROLES.PROVIDER | ROLES.STUDENT | ROLES.TEACHER;
} = {
  email: { label: "", value: "" },
  role: ROLES.STUDENT,
};

export const schema = object().shape({
  email: object().shape({
    label: string().required(REQUIRED_FIELD),
    value: string().email("Email incorrect").required(REQUIRED_FIELD),
  }),
  role: string().required(REQUIRED_FIELD).oneOf([ROLES.PROVIDER, ROLES.STUDENT, ROLES.TEACHER]),
});

export const formatFormDataToBodyData = (user: FormInputs): InviteStandardUserBody => {
  return {
    email: user.email ? user.email.value.toLowerCase().trim() : "",
    role: user.role,
  };
};
