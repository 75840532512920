import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const revertFundingRequestToReview = createAsyncThunk<void, string, { extra: Partial<Dependencies> }>(
  "fundingRequests/revertFundingRequestToReview",
  async (id: string, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.revertFundingRequestToReview(id);
  }
);

export const resetRevertFundingRequestToReview = createAction("fundingRequests/resetRevertFundingRequestToReview");
