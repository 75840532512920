import { array, boolean, date, number, object, string } from "yup";
import { FundingRequestVM } from "../../../../read/domain/types/admin/funding-request";
import { ReviewFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { MAX_SPECIFIC_ACCOMODATION_CHARACTERS } from "@academy-context/primary/student/funding-request/form-validation/funding-request";
import { TRAINING_LEVEL } from "@academy-context/shared/domain/types/enums/training-levels";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

const defaultPrice = 2400;

export type FormInputs = {
  reviewedEducationalAdvisorType: EDUCATIONAL_ADVISOR;
  isUndefinedTeacher: boolean;
  reviewedEducationalAdvisorId: string;
  reviewedInternalTrainingId: string;
  reviewedProviderBatchId: string;
  administrativeInternalTrainingId: string;
  address: string;
  additionalAddress: string;
  coverLetter: string;
  coverLetterText: string;
  personalizedEducationalProject: {
    title: { label: string; value: string };
    quantifiableObjective: string;
    quantifiableObjectiveText: string;
  }[];
  trainingPrice: number;
  day1: string;
  day2: string;
  day3: string;
  day4: string;
  day5: string;
  day6: string;
  day7: string;
  day8: string;
  day9: string;
  day10: string;
  reviewedSpecificAccommodation: string;
  operaOffCommission?: number;
  travelExpenses?: number;
  level: TRAINING_LEVEL;
  adminCommentary: string;
  organization: ORGANIZATION;
};

export const defaultValues = {
  reviewedEducationalAdvisorType: EDUCATIONAL_ADVISOR.TEACHER,
  isUndefinedTeacher: false,
  reviewedEducationalAdvisorId: "",
  reviewedInternalTrainingId: "",
  reviewedProviderBatchId: "",
  administrativeInternalTrainingId: "",
  address: "",
  additionalAddress: "",
  coverLetter: "",
  coverLetterText: "",
  personalizedEducationalProject: [
    {
      title: { label: "", value: "" },
      quantifiableObjective: "",
      quantifiableObjectiveText: "",
    },
  ],
  trainingPrice: defaultPrice,
  day1: "",
  day2: "",
  day3: "",
  day4: "",
  day5: "",
  day6: "",
  day7: "",
  day8: "",
  day9: "",
  day10: "",
  reviewedSpecificAccommodation: "",
  operaOffCommission: undefined,
  travelExpenses: undefined,
  level: TRAINING_LEVEL.BEGINNER,
  adminCommentary: "",
  organization: ORGANIZATION.LES_ATELIERS_OO,
};

export const schema = object().shape({
  reviewedEducationalAdvisorType: string().max(255).required(REQUIRED_FIELD).oneOf(Object.values(EDUCATIONAL_ADVISOR)),
  isUndefinedTeacher: boolean().required(REQUIRED_FIELD),
  reviewedEducationalAdvisorId: string()
    .max(255)
    .when("reviewedEducationalAdvisorType", {
      is: EDUCATIONAL_ADVISOR.TEACHER,
      then: string().nullable(),
      otherwise: string().required(REQUIRED_FIELD),
    }),
  reviewedInternalTrainingId: string()
    .max(255)
    .when("reviewedEducationalAdvisorType", {
      is: EDUCATIONAL_ADVISOR.TEACHER,
      then: string().required(REQUIRED_FIELD),
      otherwise: string().nullable(),
    }),
  reviewedProviderBatchId: string()
    .max(255)
    .when("reviewedEducationalAdvisorType", {
      is: EDUCATIONAL_ADVISOR.PROVIDER,
      then: string().required(REQUIRED_FIELD),
      otherwise: string().nullable(),
    }),
  administrativeInternalTrainingId: string()
    .max(255)
    .when("reviewedEducationalAdvisorType", {
      is: EDUCATIONAL_ADVISOR.PROVIDER,
      then: string().required(REQUIRED_FIELD),
      otherwise: string().nullable(),
    }),
  address: string()
    .max(255)
    .when("reviewedEducationalAdvisorType", {
      is: EDUCATIONAL_ADVISOR.TEACHER,
      then: string().nullable(),
      otherwise: string().max(255).required(REQUIRED_FIELD),
    }),
  additionalAddress: string()
    .max(255)
    .when("reviewedEducationalAdvisorType", {
      is: EDUCATIONAL_ADVISOR.TEACHER,
      then: string().nullable(),
      otherwise: string().max(255).required(REQUIRED_FIELD),
    }),
  coverLetter: string().max(4000).required(REQUIRED_FIELD),
  coverLetterText: string().max(4000).required(REQUIRED_FIELD),
  trainingPrice: number()
    .typeError(`Minimum 0€`)
    .positive(`Minimum 0€`)
    .min(0, `Minimum 0€`)
    .max(10_000, `Maximum 10 000€`)
    .required(REQUIRED_FIELD),
  day1: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day2: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day3: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day4: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day5: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day6: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day7: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day8: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day9: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day10: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  personalizedEducationalProject: array()
    .min(1, "Requiert au moins un objectif")
    .of(
      object().shape({
        title: object().shape({
          label: string().max(255).required(REQUIRED_FIELD),
          value: string().max(255).required(REQUIRED_FIELD),
        }),
        quantifiableObjective: string().required(REQUIRED_FIELD),
        quantifiableObjectiveText: string().required(REQUIRED_FIELD),
      })
    ),
  reviewedSpecificAccommodation: string().max(MAX_SPECIFIC_ACCOMODATION_CHARACTERS),
  operaOffCommission: number().min(1).max(2000).required(REQUIRED_FIELD),
  travelExpenses: number().min(1).max(2000).nullable(),
  level: string().required(REQUIRED_FIELD).oneOf(Object.values(TRAINING_LEVEL)),
  adminCommentary: string().max(255),
  organization: string().max(255).required(REQUIRED_FIELD).oneOf(Object.values(ORGANIZATION)),
});

export const formatFormDataToBodyDataReviewFundingRequest = (fundingRequest: FormInputs): ReviewFundingRequestBody => {
  const {
    reviewedEducationalAdvisorId,
    isUndefinedTeacher,
    reviewedEducationalAdvisorType,
    reviewedInternalTrainingId,
    reviewedProviderBatchId,
    administrativeInternalTrainingId,
    address,
    additionalAddress,
    coverLetter,
    personalizedEducationalProject,
    trainingPrice,
    day1,
    day2,
    day3,
    day4,
    day5,
    day6,
    day7,
    day8,
    day9,
    day10,
    reviewedSpecificAccommodation,
    operaOffCommission,
    travelExpenses,
    level,
    adminCommentary,
    organization,
  } = fundingRequest;

  const reviewedEducationalAdvisor = isUndefinedTeacher
    ? {
        id: null,
        type: EDUCATIONAL_ADVISOR.TEACHER,
      }
    : {
        id: reviewedEducationalAdvisorId,
        type: reviewedEducationalAdvisorType,
      };

  return {
    reviewedEducationalAdvisor,
    reviewedInternalTrainingId: reviewedInternalTrainingId || null,
    reviewedProviderBatchId: reviewedProviderBatchId || null,
    administrativeInternalTrainingId: administrativeInternalTrainingId || null,
    address: address || null,
    additionalAddress: additionalAddress || null,
    coverLetter,
    reviewedPersonalizedEducationalProject: personalizedEducationalProject.map(p => ({
      title: p.title.value,
      quantifiableObjective: p.quantifiableObjective,
    })),
    trainingPrice,
    trainingDays: {
      day1,
      day2,
      day3,
      day4,
      day5,
      day6,
      day7,
      day8,
      day9,
      day10,
    },
    reviewedSpecificAccommodation: reviewedSpecificAccommodation || null,
    operaOffCommission: operaOffCommission || 0,
    travelExpenses: travelExpenses || null,
    level,
    adminCommentary: adminCommentary || null,
    organization,
  };
};

export const formatFundingRequestDataToFormData = (fundingRequest: FundingRequestVM): FormInputs => {
  const {
    educationalAdvisor,
    reviewedEducationalAdvisor,
    internalTrainingId,
    reviewedInternalTrainingId,
    providerBatch,
    reviewedProviderBatchId,
    administrativeInternalTrainingId,
    reviewedCoverLetter,
    coverLetter,
    reviewedPersonalizedEducationalProject,
    trainingPrice,
    trainingDays,
    address,
    additionalAddress,
    reviewedSpecificAccommodation,
    level,
    specificAccommodation,
    operaOffCommission,
    travelExpenses,
    adminCommentary,
    organization,
  } = fundingRequest;

  const reviewedEducationalAdvisorId = reviewedEducationalAdvisor?.id ?? educationalAdvisor.id;
  const reviewedEducationalAdvisorType = reviewedEducationalAdvisor?.type ?? educationalAdvisor.type;
  let commission = operaOffCommission;
  if (commission == null) {
    const isProvider = reviewedEducationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;
    commission = isProvider ? 400 : 550;
  }

  return {
    reviewedEducationalAdvisorId: reviewedEducationalAdvisorId ?? defaultValues.reviewedEducationalAdvisorId,
    isUndefinedTeacher: !reviewedEducationalAdvisorId,
    reviewedEducationalAdvisorType,
    reviewedInternalTrainingId: reviewedInternalTrainingId ?? internalTrainingId ?? defaultValues.reviewedInternalTrainingId,
    reviewedProviderBatchId: reviewedProviderBatchId ?? providerBatch?.id ?? defaultValues.reviewedProviderBatchId,
    administrativeInternalTrainingId: administrativeInternalTrainingId ?? defaultValues.administrativeInternalTrainingId,
    coverLetter: reviewedCoverLetter || coverLetter || "",
    coverLetterText: reviewedCoverLetter || coverLetter || "",
    reviewedSpecificAccommodation: reviewedSpecificAccommodation ?? specificAccommodation ?? defaultValues.reviewedSpecificAccommodation,
    personalizedEducationalProject: reviewedPersonalizedEducationalProject
      ? reviewedPersonalizedEducationalProject.map(o => ({
          title: { label: o.title, value: o.title },
          quantifiableObjective: o.quantifiableObjective,
          quantifiableObjectiveText: o.quantifiableObjective,
        }))
      : [],
    trainingPrice: trainingPrice || defaultPrice,
    day1: trainingDays?.day1 ?? defaultValues.day1,
    day2: trainingDays?.day2 ?? defaultValues.day2,
    day3: trainingDays?.day3 ?? defaultValues.day3,
    day4: trainingDays?.day4 ?? defaultValues.day4,
    day5: trainingDays?.day5 ?? defaultValues.day5,
    day6: trainingDays?.day6 ?? defaultValues.day6,
    day7: trainingDays?.day7 ?? defaultValues.day7,
    day8: trainingDays?.day8 ?? defaultValues.day8,
    day9: trainingDays?.day9 ?? defaultValues.day9,
    day10: trainingDays?.day10 ?? defaultValues.day10,
    address: address ?? defaultValues.address,
    additionalAddress: additionalAddress ?? defaultValues.additionalAddress,
    level: level ?? defaultValues.level,
    operaOffCommission: commission,
    travelExpenses: travelExpenses ?? defaultValues.travelExpenses,
    adminCommentary: adminCommentary ?? defaultValues.adminCommentary,
    organization: organization ?? defaultValues.organization,
  };
};
