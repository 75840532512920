import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { FundingRequestVM } from "../../../../domain/types/admin/funding-request";

export const retrieveFundingRequest = createAsyncThunk<FundingRequestVM, string, { extra: Partial<Dependencies> }>(
  "fundingRequests/retrieveFundingRequest",
  async (id: string, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.get(id);
  }
);

export const resetRetrieveFundingRequest = createAction("fundingRequests/resetRetrieveFundingRequest");
