import { Nullable } from "@shared-kernel/core/types/nullable";

export enum EDUCATIONAL_ADVISOR {
  TEACHER = "teacher",
  PROVIDER = "provider",
}

export type FundingRequestEducationalAdvisor = {
  // Can only be null in the case of a teacher when student doesn't know at funding request creation which teacher will be assigned
  id: Nullable<string>;
  type: EDUCATIONAL_ADVISOR;
};

export const EDUCATIONAL_ADVISOR_MAP: Record<EDUCATIONAL_ADVISOR, string> = {
  [EDUCATIONAL_ADVISOR.TEACHER]: "Professeur",
  [EDUCATIONAL_ADVISOR.PROVIDER]: "Prestataire",
};
