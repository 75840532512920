import { useEffect } from "react";
import { setUserInfoCookie } from "../../../write/application/use-cases/user-login/login-user-reducer";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";

const TWO_DAYS = 48 * 3600;

export const useUserLogin = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { data: user, fetching, error } = useAppSelector((state: AppState) => state.userLogin);

  useEffect(() => {
    if (fetching === "success" && user) {
      toast({ description: "Bienvenu(e) !" });
      setUserInfoCookie(user, TWO_DAYS);
    } else if (fetching === "failed") {
      if (error === "401 unauthenticated") toast({ description: "Mot de passe ou email invalide", variant: "destructive" });
      else if (error === "403 unauthorized")
        toast({ description: "Vous n'êtes pas autorisé à effectuer cette action", variant: "destructive" });
      else if (error === "440 Login Timeout") toast({ description: "Vous devez vous reconnecter à la plateforme", variant: "destructive" });
      else toast({ description: "Erreur lors de la connexion. Veuillez contacter l'administrateur", variant: "destructive" });
    }
  }, [fetching, dispatch, user, error, toast]);
};
