import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FundingRequestForm } from "./funding-request-form.component";
import { createFundingRequest } from "../../../write/application/use-cases/student/funding-request-creation/create-funding-request";
import { CreateFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { useFundingRequestCreate } from "./use-funding-request-create";
import { retrieveTeachers } from "../../../../user-management-context/read/application/use-cases/student/teachers-retrieval/retrieve-teachers";
import { resetUploadBio } from "../../../write/application/use-cases/student/bio-upload/upload-bio";
import { resetUploadResume } from "../../../write/application/use-cases/student/resume-upload/upload-resume";
import { retrieveProviders } from "@user-management-context/read/application/use-cases/student/providers-retrieval/retrieve-providers";
import { studentRetrieveInternalTrainings } from "@academy-context/read/application/use-cases/student/internal-trainings-retrieval/retrieve-internal-trainings";
import { studentSelectInternalTrainingsRetrievalForForm } from "@academy-context/read/application/use-cases/student/internal-trainings-retrieval/selectors/internal-training-list-for-form-selectors";
import { AppState } from "@redux/app-state";
import { studentSelectProviderBatchesRetrievalForList } from "@academy-context/read/application/use-cases/student/provider-batch-list-retrieval/selectors/provider-batch-list-selectors";
import { studentRetrieveEligibleProviderEnrollment } from "@academy-context/read/application/use-cases/student/eligible-provider-enrollment-retrieval/retrieve-eligible-provider-enrollment";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { FormSkeleton } from "@shared-kernel/primary/shared/skeletons/form.skeleton";
import { isLoadingState } from "@utils/utils";
import { selectStudentProfileRetrieval } from "@user-management-context/read/application/use-cases/student/profile-retrieval/selectors/student-profile-selectors";

export const FundingRequestCreateContainer = () => {
  const dispatch = useAppDispatch();
  const {
    internalTrainingsOptions,
    rawInternalTrainings,
    isLoading: internalTrainingsIsLoading,
  } = useAppSelector(studentSelectInternalTrainingsRetrievalForForm);
  const { data: teachers, fetching: teachersFetching } = useAppSelector((state: AppState) => state.studentTeachersRetrieval);
  const { data: providers } = useAppSelector((state: AppState) => state.studentProvidersRetrieval);
  const { providerBatchesOptions, rawProviderBatches } = useAppSelector(studentSelectProviderBatchesRetrievalForList);
  const { processing } = useAppSelector((state: AppState) => state.fundingRequestCreate);
  const { data: studentProviderEnrollment, fetching: studentProviderEnrollmentFetching } = useAppSelector(
    (state: AppState) => state.studentEligibleProviderEnrollmentRetrieval
  );
  const student = useAppSelector(selectStudentProfileRetrieval);

  useEffect(() => {
    dispatch(retrieveTeachers());
    dispatch(retrieveProviders());
    dispatch(studentRetrieveInternalTrainings());
    dispatch(studentRetrieveEligibleProviderEnrollment());
    return () => {
      dispatch(resetUploadBio());
      dispatch(resetUploadResume());
    };
  }, [dispatch]);

  const saveFundingRequest = (fundingRequest: CreateFundingRequestBody) => {
    dispatch(createFundingRequest(fundingRequest));
  };

  useFundingRequestCreate();

  const isLoading = internalTrainingsIsLoading || isLoadingState(teachersFetching) || isLoadingState(studentProviderEnrollmentFetching);

  return (
    <CustomCard title="Nouvelle demande de financement">
      {isLoading ? (
        <FormSkeleton className="h-[800px]" />
      ) : (
        <FundingRequestForm
          onSubmit={saveFundingRequest}
          internalTrainingsOptions={internalTrainingsOptions}
          rawInternalTrainings={rawInternalTrainings}
          teachers={teachers}
          providers={providers}
          providerBatchesOptions={providerBatchesOptions}
          rawProviderBatches={rawProviderBatches}
          processing={processing}
          studentProviderEnrollment={studentProviderEnrollment}
          student={student}
        />
      )}
    </CustomCard>
  );
};
