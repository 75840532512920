import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { InviteAdminUserBody } from "@user-management-context/shared/application/ports/user-invitation-port";

export const inviteAdminUser = createAsyncThunk<string, InviteAdminUserBody, { extra: Partial<Dependencies> }>(
  "users/inviteAdminUser",
  async (body: InviteAdminUserBody, { extra: { adminUserInvitationGateway } }) => {
    return adminUserInvitationGateway!.inviteAdminUser(body);
  }
);

export const resetInviteAdminUser = createAction("users/resetInviteAdminUser");
