import { createReducer } from "@reduxjs/toolkit";
import { BalanceAdjustmentsRetrievalState } from "@redux/app-state";
import { retrieveBalanceAdjustments } from "./retrieve-balance-adjustments";

const initialState: BalanceAdjustmentsRetrievalState = { fetching: "pending", data: [] };

export const retrieveBalanceAdjustmentsReducer = createReducer<BalanceAdjustmentsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveBalanceAdjustments.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveBalanceAdjustments.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveBalanceAdjustments.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
