import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentInternalTraining } from "@academy-context/read/domain/types/student/internal-training";

export const studentRetrieveInternalTrainings = createAsyncThunk<StudentInternalTraining[], void, { extra: Partial<Dependencies> }>(
  "internalTrainings/retrieveInternalTrainings",
  async (_, { extra: { studentInternalTrainingRepository } }) => {
    return studentInternalTrainingRepository!.list();
  }
);

export const resetStudentRetrieveInternalTrainings = createAction("internalTrainings/resetStudentRetrieveInternalTrainings");
