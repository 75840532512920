import { createReducer } from "@reduxjs/toolkit";
import { CreationState } from "@redux/app-state";
import { createFundingRequest, resetCreateFundingRequest } from "./create-funding-request";

const initialState: CreationState = { justCreatedId: null, processing: "idle" };

export const createFundingRequestReducer = createReducer<CreationState>(initialState, builder => {
  builder.addCase(createFundingRequest.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedId: payload,
      processing: "success",
    };
  });
  builder.addCase(createFundingRequest.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(createFundingRequest.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetCreateFundingRequest, () => {
    return initialState;
  });
});
