import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateConventionBody } from "@academy-context/write/domain/types/admin/convention";
import { ConventionCreate } from "./convention-create.component";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { retrieveConventions } from "../../../read/application/use-cases/admin/conventions-retrieval/retrieve-conventions";
import { createConvention } from "../../../write/application/use-cases/admin/convention-creation/create-convention";
import { adminRetrieveTeachers } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import { retrieveApprovedFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/approved/retrieve-approved-funding-requests";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Card, CardContent } from "@components/ui/card";
import { ConventionsList } from "@academy-context/primary/admin/convention-detail/convention-list.components";
import { selectConventionsRetrievalForList } from "@academy-context/read/application/use-cases/admin/conventions-retrieval/selectors/conventions-list-selectors";
import { useConventionCancellation } from "@academy-context/primary/admin/convention-list/use-convention-cancelled";
import { useConventionEnding } from "@academy-context/primary/admin/convention-list/use-convention-ended";
import { FormSkeleton } from "@shared-kernel/primary/shared/skeletons/form.skeleton";
import { AppState } from "@redux/app-state";
import { selectConventionCreate } from "@academy-context/primary/admin/convention-detail/use-convention-create";
import { selectRegisteredStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/registered-students-selectors";

export const ConventionAdminDetail = () => {
  const dispatch = useAppDispatch();
  const { data: conventions, isLoading } = useAppSelector(selectConventionsRetrievalForList);
  const { data: fundingRequests } = useAppSelector((state: AppState) => state.approvedFundingRequestsRetrieval);
  const { data: teachers } = useAppSelector((state: AppState) => state.teachersRetrieval);
  const { processing } = useAppSelector(selectConventionCreate);
  const students = useAppSelector(selectRegisteredStudents);

  useEffect(() => {
    dispatch(retrieveStudents());
    dispatch(adminRetrieveTeachers());
    dispatch(retrieveConventions());
    dispatch(retrieveApprovedFundingRequests({ educationalAdvisors: [EDUCATIONAL_ADVISOR.TEACHER] }));
  }, [dispatch]);

  const saveConvention = async (convention: CreateConventionBody) => {
    await dispatch(createConvention(convention));
  };

  useConventionCancellation();
  useConventionEnding();

  if (isLoading) return <FormSkeleton />;

  return (
    <>
      <CustomCard title="Nouvelle convention">
        <ConventionCreate
          onSubmit={saveConvention}
          fundingRequests={fundingRequests}
          processing={processing}
          teachers={teachers}
          students={students}
        />
      </CustomCard>
      <Card className="mt-4">
        <CardContent className="flex flex-col items-center pt-3 text-black dark:text-white">
          <ConventionsList conventions={conventions} />
        </CardContent>
      </Card>
    </>
  );
};
