import { AppState } from "@redux/app-state";
import { isLoadingState } from "@utils/utils";

export const selectBalanceAdjustmentsRetrievalForList = (state: AppState) => {
  const {
    balanceAdjustmentsRetrieval: { data, fetching },
  } = state;

  const sortedData = [...data].sort((a, b) => {
    const dateAInMs = new Date(a.creationDate).getTime();
    const dateBInMs = new Date(b.creationDate).getTime();

    if (dateBInMs < dateAInMs) {
      return -1;
    } else if (dateBInMs > dateAInMs) {
      return 1;
    }
    return 0;
  });

  return {
    data: sortedData.map(b => {
      return {
        ...b,
        localeDate: new Date(b.creationDate).toLocaleDateString("fr-FR"),
        isCredited: b.amount > 0,
      };
    }),
    isLoading: isLoadingState(fetching),
  };
};
