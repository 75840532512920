import { FUNDER } from "@academy-context/shared/domain/types/enums/funders";
import { Budget } from "@academy-context/read/domain/types/admin/budget";
import { fundingRequestId } from "src/test/utils/common";

const budget: Budget = {
  obtentionDate: "2022-04-03",
  internshipEndDate: "2022-06-02",
  studentId: "studentId",
  funder: FUNDER.AFDAS,
  allocatedAmount: 3000,
  operaOffCommission: 200,
  id: "123abc",
  expirationTimeInMonths: 8,
  createdAt: "2022-06-02T12:00:00.000Z",
  commentary: "Très bon élève",
  externalFundingRequestId: null,
  fundingRequestId,
};
const budget2: Budget = {
  obtentionDate: "2022-04-03",
  internshipEndDate: "2022-07-03",
  studentId: "studentId2",
  funder: FUNDER.AFDAS,
  allocatedAmount: 1000,
  operaOffCommission: 100,
  id: "456def",
  expirationTimeInMonths: 8,
  createdAt: "2022-06-01T12:00:00.000Z",
  commentary: "Très bon élève",
  externalFundingRequestId: null,
  fundingRequestId,
};
const budget3: Budget = {
  obtentionDate: "2022-04-03",
  internshipEndDate: "2022-09-02",
  studentId: "studentId3",
  funder: FUNDER.AFDAS,
  allocatedAmount: 2000,
  operaOffCommission: 500,
  id: "budgetId",
  expirationTimeInMonths: 8,
  createdAt: "2022-06-08T12:00:00.000Z",
  commentary: "Très bon élève",
  externalFundingRequestId: null,
  fundingRequestId,
};

export const budgets = [budget, budget2, budget3];
