import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import {
  resetStudentRetrieveInternalTrainings,
  studentRetrieveInternalTrainings,
} from "@academy-context/read/application/use-cases/student/internal-trainings-retrieval/retrieve-internal-trainings";
import { StudentInternalTraining } from "@academy-context/read/domain/types/student/internal-training";

// It's ok to start with pending because the component needs to dispatch this action as soon as it mounts
const initialState: RetrievalState<StudentInternalTraining[]> = { data: [], fetching: "pending" };

export const studentRetrieveInternalTrainingsReducer = createReducer<RetrievalState<StudentInternalTraining[]>>(initialState, builder => {
  builder.addCase(studentRetrieveInternalTrainings.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(studentRetrieveInternalTrainings.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(studentRetrieveInternalTrainings.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetStudentRetrieveInternalTrainings, () => {
    return initialState;
  });
});
