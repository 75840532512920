import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const submitFundingRequestToFunder = createAsyncThunk<
  void,
  { id: string; hasBeenSubmitted: boolean },
  { extra: Partial<Dependencies> }
>(
  "fundingRequests/submitFundingRequestToFunder",
  async ({ id, hasBeenSubmitted }: { id: string; hasBeenSubmitted: boolean }, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.submitToFunder(id, { hasBeenSubmitted });
  }
);

export const resetSubmitFundingRequestToFunder = createAction("fundingRequests/resetSubmitFundingRequestToFunder");
