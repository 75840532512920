import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateBudgetBody } from "@academy-context/write/domain/types/admin/budget";

export const createBudget = createAsyncThunk<string, CreateBudgetBody, { extra: Partial<Dependencies> }>(
  "budgets/createBudget",
  async (budget: CreateBudgetBody, { extra: { adminBudgetRepository } }) => {
    return adminBudgetRepository!.create(budget);
  }
);

export const resetCreateBudget = createAction("budgets/resetCreateBudget");
