import { useEffect, useState } from "react";
import { CardContent, CardFooter } from "@components/ui/card";
import { Input } from "@components/ui/input";
import { Button } from "@components/ui/button";
import { Mail } from "lucide-react";
import { FetchingState } from "@redux/app-state";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData } from "./form-validation/admin-user-invitation";
import { InviteAdminUserBody } from "@user-management-context/shared/application/ports/user-invitation-port";

interface Props {
  onSubmitAdminUser: (user: InviteAdminUserBody) => void;
  checkIfUserExist: (email: string) => void;
  resetCheckIfUserExist: () => void;
  doesAccountExist: boolean;
  processing: FetchingState;
}

export const AdminUserInvitationFormComponent = ({
  onSubmitAdminUser,
  doesAccountExist,
  processing,
  checkIfUserExist,
  resetCheckIfUserExist,
}: Props) => {
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const [disableSubmit, setDisableSubmit] = useState(false);

  const {
    reset,
    formState: { isDirty },
    setError,
    clearErrors,
    watch,
    handleSubmit,
    control,
  } = form;

  const email = watch("email");

  useEffect(() => {
    if (email && doesAccountExist) {
      setError("email", { message: "Cet utilisateur dispose déjà d'un compte", type: "custom" });
      setDisableSubmit(true);
    } else {
      clearErrors("email");
      setDisableSubmit(false);
    }
  }, [clearErrors, doesAccountExist, email, setError]);

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmitAdminUser(body);
  };

  // This part is used to check if user account is already registered
  useEffect(() => {
    if (email) checkIfUserExist(email);
  }, [checkIfUserExist, email]);

  useEffect(() => {
    return () => {
      reset();
      setDisableSubmit(false);
      resetCheckIfUserExist();
    };
  }, [clearErrors, reset, resetCheckIfUserExist]);

  useEffect(() => {
    if (processing === "success") {
      reset();
      setDisableSubmit(false);
      resetCheckIfUserExist();
    }
  }, [reset, resetCheckIfUserExist, processing]);

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <CardContent className="grid gap-2">
          <FormField
            control={control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Prénom</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nom</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} type="email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </CardContent>
        <CardFooter className="flex items-center justify-center">
          <Button type="submit" disabled={!isDirty || processing === "pending" || disableSubmit || !email}>
            <Mail />
            Inviter
          </Button>
        </CardFooter>
      </form>
    </Form>
  );
};
