import { Nullable } from "@shared-kernel/core/types/nullable";

export enum FUNDER {
  AFDAS = "afdas",
  FRANCE_TRAVAIL = "france_travail",
  FIFPL = "fifpl",
  ATLAS = "atlas",
  AKTO = "akto",
  AGEFICE = "agefice",
}

export enum AFDAS_STUDENT_STATUS {
  INTERMITTENT_DU_SPECTACLE = "intermittent_du_spectacle",
  ARTISTE_AUTEUR = "artiste_auteur",
  ENTREPRISE = "entreprise",
}

export const AFDAS_STUDENT_STATUS_OPTIONS: Record<AFDAS_STUDENT_STATUS, string> = {
  [AFDAS_STUDENT_STATUS.ARTISTE_AUTEUR]: "Artiste auteur (AA)",
  [AFDAS_STUDENT_STATUS.ENTREPRISE]: "Entreprise (Opéra de Paris, Radio France, autre)",
  [AFDAS_STUDENT_STATUS.INTERMITTENT_DU_SPECTACLE]: "Intermittent du spectacle (IS)",
};

interface AFDAS {
  type: FUNDER.AFDAS;
  payload: {
    id: string;
    // Becomes null after a while
    password: Nullable<string>;
    studentStatus: AFDAS_STUDENT_STATUS;
  };
}

interface WRITE_AFDAS {
  type: FUNDER.AFDAS;
  payload: {
    id: string;
    password: string;
    studentStatus: AFDAS_STUDENT_STATUS;
  };
}

interface FRANCE_TRAVAIL {
  type: FUNDER.FRANCE_TRAVAIL;
  payload: {
    id: string;
    department: string;
  };
}

interface FUNDER_WITHOUT_PAYLOAD {
  type: FUNDER.AGEFICE | FUNDER.AKTO | FUNDER.ATLAS | FUNDER.FIFPL;
}

// This distinction between READ and WRITE is temporary, until the migration has been fully completed (ie all password of running funding requests have been encrypted).
// In a few months (around March 2025), remove this distinction and only keep the FUNDER_WITH_PAYLOAD type with an afdas password: string.
export type READ_FUNDER_WITH_PAYLOAD = AFDAS | FRANCE_TRAVAIL | FUNDER_WITHOUT_PAYLOAD;
export type WRITE_FUNDER_WITH_PAYLOAD = WRITE_AFDAS | FRANCE_TRAVAIL | FUNDER_WITHOUT_PAYLOAD;
