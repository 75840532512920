import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "../../../../../../../redux/store";
import { ApprovedFundingRequestVM } from "../../../../../domain/types/admin/funding-request";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";

export const retrieveApprovedFundingRequests = createAsyncThunk<
  ApprovedFundingRequestVM[],
  { funders?: string[]; educationalAdvisors?: EDUCATIONAL_ADVISOR[] } | undefined,
  { extra: Partial<Dependencies> }
>(
  "fundingRequests/retrieveApprovedFundingRequests",
  async (
    filters: { funders?: string[]; educationalAdvisors?: EDUCATIONAL_ADVISOR[] } | undefined,
    { extra: { fundingRequestRepository } }
  ) => {
    const requests = await fundingRequestRepository!.getAll({
      status: ["approved"],
      funders: filters?.funders,
      educationalAdvisors: filters?.educationalAdvisors,
    });
    const response: ApprovedFundingRequestVM[] = [];
    for (const r of requests) {
      if (r.status === "approved") {
        response.push(r);
      }
    }
    return response;
  }
);
