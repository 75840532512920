import { retrieveUserInvitations } from "../../../../read/application/use-cases/admin/user-invitations-retrieval/retrieve-user-invitations";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { resetRefreshUserInvitation } from "@user-management-context/write/application/use-cases/admin/refresh-user-invitation/refresh-user-invitation";

const selectUserInvitationRefresh = (state: AppState) => state.userInvitationRefresh;

export const useInvitationRefresh = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectUserInvitationRefresh);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Invitation renvoyée !" });
      dispatch(resetRefreshUserInvitation());
      dispatch(retrieveUserInvitations());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors du renvoi de l'invitation", variant: "destructive" });
    }
  }, [processing, dispatch, toast]);
};
