import { isLoadingState, sortAlphabetically } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";

export type AdminProviderTrainingRow = {
  id: string;
  creationDate: string;
  providerId: string;
  tag: string;
  title: string;
  objective: string;
  description: string;
  team: string;
  price: number;
  chips: string[];
  preview: string;
};

export const adminSelectProviderTrainingsRetrievalForList = createSelector(
  [(state: AppState) => state.adminProviderTrainingsRetrieval],
  providerTrainingsRetrieval => {
    const { data, fetching } = providerTrainingsRetrieval;

    const formattedData = [...data]
      .sort((a, b) => sortAlphabetically(a.title, b.title))
      .map(training => {
        const chips: string[] = [];
        chips.push(training.tag);
        return {
          ...training,
          chips,
          preview: training.objective,
        };
      });

    return {
      trainings: formattedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
