import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const deleteFundingRequest = createAsyncThunk<void, string, { extra: Partial<Dependencies> }>(
  "fundingRequests/deleteFundingRequest",
  async (id: string, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.delete(id);
  }
);

export const resetDeleteFundingRequest = createAction("fundingRequests/resetDeleteFundingRequest");
