import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { InviteStandardUserBody } from "@user-management-context/shared/application/ports/user-invitation-port";

export const inviteStandardUser = createAsyncThunk<string, InviteStandardUserBody, { extra: Partial<Dependencies> }>(
  "users/inviteStandardUser",
  async (body: InviteStandardUserBody, { extra: { adminUserInvitationGateway } }) => {
    return adminUserInvitationGateway!.inviteStandardUser(body);
  }
);

export const resetInviteStandardUser = createAction("users/resetInviteStandardUser");
