import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { FundingRequest } from "@academy-context/read/domain/types/student/funding-request";

const existingFundingRequest: FundingRequest = {
  id: "id",
  educationalAdvisor: { id: "teacherId", type: EDUCATIONAL_ADVISOR.TEACHER },
  providerBatchId: null,
  resumeUrl: "https://resume.com",
  bioUrl: "https://bio.com",
  coverLetter: "Je suis très motivé",
  personalizedEducationalProject: "J'adore le travail de technique vocale pure",
};

export const fundingRequests = [existingFundingRequest];
