import config from "../../../../config/backend";
import { StudentBalanceAdjustmentRepository } from "@shared-kernel/application/ports/student/student-balance-adjustment-repository";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { StudentBalanceAdjustmentListVM } from "@academy-context/read/domain/types/student/balance-adjustment";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";

export class StudentBackendBalanceAdjustmentRepository extends HttpGateway implements StudentBalanceAdjustmentRepository {
  private _route: string = "v1/student/balance-adjustments";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<StudentBalanceAdjustmentListVM[]> {
    const { data: balanceAdjustments } = await this._instance.get(this._route);
    return balanceAdjustments;
  }
}
