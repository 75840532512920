import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../config/backend";
import { AdminBalanceAdjustmentListVM } from "@academy-context/read/domain/types/admin/balance-adjustment";
import {
  AdminBalanceAdjustmentRepository,
  CreateBalanceAdjustmentBody,
} from "@shared-kernel/application/ports/admin/balance-adjustment-repository";

export class BackendAdminBalanceAdjustmentRepository extends HttpGateway implements AdminBalanceAdjustmentRepository {
  private _route: string = "v1/admin";
  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<AdminBalanceAdjustmentListVM[]> {
    const { data: balanceAdjustments } = await this._instance.get(`${this._route}/balance-adjustments`);
    return balanceAdjustments;
  }

  async create(balanceAdjustment: CreateBalanceAdjustmentBody): Promise<string> {
    const { data: balanceAdjustmentId } = await this._instance.put(
      `${this._route}/students/${balanceAdjustment.studentId}/balance-adjustments`,
      balanceAdjustment
    );
    return balanceAdjustmentId;
  }
}
