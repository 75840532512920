import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateBalanceAdjustmentBody } from "@shared-kernel/application/ports/admin/balance-adjustment-repository";

export const createBalanceAdjustment = createAsyncThunk<string, CreateBalanceAdjustmentBody, { extra: Partial<Dependencies> }>(
  "balanceAdjustments/createBalanceAdjustment",
  async (balanceAdjustment: CreateBalanceAdjustmentBody, { extra: { adminBalanceAdjustmentRepository } }) => {
    return adminBalanceAdjustmentRepository!.create(balanceAdjustment);
  }
);

export const resetCreateBalanceAdjustment = createAction("balanceAdjustments/resetCreateBalanceAdjustment");
