import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { Label } from "@components/ui/label";
import { Briefcase, GraduationCap, School, Settings } from "lucide-react";
import { FetchingState } from "@redux/app-state";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { AdminUserInvitationFormComponent } from "@user-management-context/primary/admin/user-invitations/form/admin-user-invitation-form.components";
import { InviteAdminUserBody, InviteStandardUserBody } from "@user-management-context/shared/application/ports/user-invitation-port";
import { cn } from "@components/utils/utils";
import { StandardUserInvitationFormComponent } from "@user-management-context/primary/admin/user-invitations/form/standard-user-invitation-form.components";

interface Props {
  onSubmitStandardUser: (user: InviteStandardUserBody) => void;
  onSubmitAdminUser: (user: InviteAdminUserBody) => void;
  checkIfUserExist: (email: string) => void;
  resetCheckIfUserExist: () => void;
  teacherEmails: ComboboxOptions[];
  studentEmails: ComboboxOptions[];
  providerEmails: ComboboxOptions[];
  doesAccountExist: boolean;
  standardUserInviteProcessing: FetchingState;
  adminUserInviteProcessing: FetchingState;
}

const roles = [
  {
    label: "Elève",
    value: ROLES.STUDENT,
    icon: <GraduationCap className="mb-3 size-6" />,
  },
  {
    label: "Prof.",
    value: ROLES.TEACHER,
    icon: <Briefcase className="mb-3 size-6" />,
  },
  {
    label: "Presta.",
    value: ROLES.PROVIDER,
    icon: <School className="mb-3 size-6" />,
  },
  {
    label: "Admin.",
    value: ROLES.ADMIN,
    icon: <Settings className="mb-3 size-6" />,
  },
];

export const UserInvitationFormComponent = ({
  onSubmitStandardUser,
  onSubmitAdminUser,
  teacherEmails,
  studentEmails,
  providerEmails,
  doesAccountExist,
  standardUserInviteProcessing,
  adminUserInviteProcessing,
  checkIfUserExist,
  resetCheckIfUserExist,
}: Props) => {
  const [role, setRole] = useState(ROLES.STUDENT);

  const isAdmin = role === ROLES.ADMIN;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Nouvel utilisateur</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-6">
        <Label>Rôle</Label>
        <RadioGroup onValueChange={value => setRole(value as ROLES)} defaultValue={role} className="grid grid-cols-4 gap-4">
          {roles.map(({ icon, label, value }) => {
            const isChecked = role === value;
            return (
              <div key={value}>
                <RadioGroupItem value={value} id={value} className="peer sr-only" />
                <Label
                  htmlFor={value}
                  className={cn(
                    "flex flex-col items-center justify-between rounded-md border-2 border-muted p-4 hover:bg-accent hover:text-accent-foreground",
                    isChecked && "border-primary"
                  )}
                >
                  {icon}
                  {label}
                </Label>
              </div>
            );
          })}
        </RadioGroup>
        {isAdmin ? (
          <AdminUserInvitationFormComponent
            onSubmitAdminUser={onSubmitAdminUser}
            processing={adminUserInviteProcessing}
            doesAccountExist={doesAccountExist}
            checkIfUserExist={checkIfUserExist}
            resetCheckIfUserExist={resetCheckIfUserExist}
          />
        ) : (
          <StandardUserInvitationFormComponent
            role={role}
            onSubmit={onSubmitStandardUser}
            checkIfUserExist={checkIfUserExist}
            resetCheckIfUserExist={resetCheckIfUserExist}
            teacherEmails={teacherEmails}
            studentEmails={studentEmails}
            providerEmails={providerEmails}
            doesAccountExist={doesAccountExist}
            processing={standardUserInviteProcessing}
          />
        )}
      </CardContent>
    </Card>
  );
};
