import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { resetSubmitFundingRequestToFunder, submitFundingRequestToFunder } from "./submit-funding-request-to-funder";

const initialState: SimpleState = { processing: "idle" };

export const submitFundingRequestToFunderReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(submitFundingRequestToFunder.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(submitFundingRequestToFunder.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(submitFundingRequestToFunder.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetSubmitFundingRequestToFunder, () => {
    return initialState;
  });
});
