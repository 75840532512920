import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminBalanceAdjustmentListVM } from "../../../../domain/types/admin/balance-adjustment";

export const retrieveBalanceAdjustments = createAsyncThunk<AdminBalanceAdjustmentListVM[], void, { extra: Partial<Dependencies> }>(
  "balanceAdjustments/retrieveBalanceAdjustments",
  async (_: void, { extra: { adminBalanceAdjustmentRepository } }) => {
    return adminBalanceAdjustmentRepository!.all();
  }
);
