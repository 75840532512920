import { createReducer } from "@reduxjs/toolkit";
import { CreationState } from "@redux/app-state";
import { createBudget, resetCreateBudget } from "./create-budget";

const initialState: CreationState = { justCreatedId: null, processing: "idle" };

export const createBudgetReducer = createReducer<CreationState>(initialState, builder => {
  builder.addCase(createBudget.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedId: payload,
      processing: "success",
    };
  });
  builder.addCase(createBudget.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(createBudget.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetCreateBudget, () => {
    return initialState;
  });
});
