import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";

export const adminRetrieveProviderBatches = createAsyncThunk<ProviderBatchItem[], string, { extra: Partial<Dependencies> }>(
  "providers/adminRetrieveProviderBatches",
  async (providerId: string, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.batchList(providerId);
  }
);

export const resetAdminRetrieveProviderBatches = createAction("providers/resetAdminRetrieveProviderBatches");
