import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";

export enum ORGANIZATION {
  OPERA_OFF = "opera_off",
  LES_ATELIERS_OO = "les_ateliers_oo",
}

export const ORGANIZATION_NAME: Record<ORGANIZATION, { long: string; short: string }> = {
  [ORGANIZATION.OPERA_OFF]: { long: "OPERA Off", short: "OO" },
  [ORGANIZATION.LES_ATELIERS_OO]: { long: "Les Ateliers OO", short: "LAOO" },
};

export const OrganisationOptions: ComboboxOptions[] = Object.values(ORGANIZATION).map(o => ({
  label: ORGANIZATION_NAME[o].long,
  value: o,
}));
