import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { reportIssue } from "./report-issue";

const initialState: SimpleState = { processing: "idle" };

export const reportIssueReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(reportIssue.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(reportIssue.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(reportIssue.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
