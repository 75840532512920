import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FundingRequestForm } from "./funding-request-form.component";
import { useFundingRequestReviewed } from "./use-funding-request-reviewed";
import { useParams } from "react-router-dom";
import {
  resetRetrieveFundingRequest,
  retrieveFundingRequest,
} from "../../../read/application/use-cases/admin/funding-request-retrieval/retrieve-funding-request";
import { ReviewFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import {
  deleteFundingRequest,
  resetDeleteFundingRequest,
} from "../../../write/application/use-cases/admin/funding-request-deletion/delete-funding-request";
import { useFundingRequestDeleted } from "./use-funding-request-deleted";
import { adminRetrieveTeachers } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import {
  resetReviewFundingRequest,
  reviewFundingRequest,
} from "@academy-context/write/application/use-cases/admin/funding-request-review/review-funding-request";
import { adminRetrieveProviders } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers";
import { selectProviderRetrievalForList } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/selectors/provider-list-selectors";
import { selectTeachersRetrievalForList } from "@user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/selectors/teachers-list-selectors";
import { adminRetrieveInternalTrainings } from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/retrieve-internal-trainings";
import { adminSelectInternalTrainingsRetrievalForForm } from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/selectors/internal-training-list-for-form-selectors";
import { adminSelectProviderBatchesRetrievalForList } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/selectors/provider-batch-list-selectors";
import { StudentFundingRequestForm } from "@academy-context/primary/admin/funding-request-detail/student-funding-request-form.component";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { FormSkeleton } from "@shared-kernel/primary/shared/skeletons/form.skeleton";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { adminSelectFundingRequest } from "@academy-context/read/application/use-cases/admin/funding-request-retrieval/selectors/funding-request-detail-selectors";

export const FundingRequestContainer = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { fundingRequest, isLoading } = useAppSelector(adminSelectFundingRequest);
  const { providers } = useAppSelector(selectProviderRetrievalForList);
  const { data: teachers } = useAppSelector(selectTeachersRetrievalForList);
  const { trainings: internalTrainings } = useAppSelector(adminSelectInternalTrainingsRetrievalForForm);
  const { batches: providerBatches } = useAppSelector(adminSelectProviderBatchesRetrievalForList);

  useEffect(() => {
    if (id) dispatch(retrieveFundingRequest(id));
    dispatch(adminRetrieveTeachers());
    dispatch(adminRetrieveProviders());
    dispatch(adminRetrieveInternalTrainings());
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetDeleteFundingRequest());
      dispatch(resetReviewFundingRequest());
      dispatch(resetRetrieveFundingRequest());
    };
  }, [dispatch]);

  const saveFundingRequest = async (fundingRequest: ReviewFundingRequestBody) => {
    if (id) await dispatch(reviewFundingRequest({ id, body: fundingRequest }));
  };

  const onDeleteFundingRequest = async () => {
    if (id) await dispatch(deleteFundingRequest(id));
  };

  const teacherOptions: ComboboxOptions[] = teachers.map(t => ({ label: `${t.name} ${t.lastName}`, value: t.teacherId }));
  const providerOptions: ComboboxOptions[] = useMemo(() => {
    const allProviderOptions = providers.map(p => ({ label: p.name, value: p.id }));
    return allProviderOptions;
  }, [providers]);

  useFundingRequestReviewed();
  useFundingRequestDeleted();

  return (
    <div className="grid grid-cols-2 gap-3">
      <div>
        <CustomCard title="Demande de financement élève">
          {isLoading || !fundingRequest ? (
            <FormSkeleton className="h-[800px]" />
          ) : (
            <StudentFundingRequestForm
              fundingRequest={fundingRequest}
              teacherOptions={teacherOptions}
              providerOptions={providerOptions}
              internalTrainingsOptions={internalTrainings}
            />
          )}
        </CustomCard>
      </div>
      <div>
        <CustomCard title="Revue de la demande de financement">
          {isLoading || !fundingRequest ? (
            <FormSkeleton className="h-[800px]" />
          ) : (
            <FundingRequestForm
              onSubmit={saveFundingRequest}
              onDelete={onDeleteFundingRequest}
              fundingRequest={fundingRequest}
              providers={providers}
              teachers={teachers}
              teacherOptions={teacherOptions}
              providerOptions={providerOptions}
              providerBatchesOptions={providerBatches}
              internalTrainingsOptions={internalTrainings}
            />
          )}
        </CustomCard>
      </div>
    </div>
  );
};
