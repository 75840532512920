import { number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { CreateBalanceAdjustmentBody } from "@shared-kernel/application/ports/admin/balance-adjustment-repository";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

export const MAX_BALANCE_ADJUSTMENT_DESCRIPTION_CHARACTERS = 255;
export const MAX_BALANCE_ADJUSTMENT_PRICE = 4000;
export const MIN_BALANCE_ADJUSTMENT_PRICE = -4000;

export type FormInputs = {
  student: string;
  label: { label: string; value: string };
  amount: number;
  commentary: string;
  documentUrl: string;
  organization: ORGANIZATION;
};

export const defaultValues = {
  student: "",
  label: { value: "", label: "" },
  amount: undefined,
  commentary: "",
  documentUrl: "",
  organization: ORGANIZATION.OPERA_OFF,
};

export const schema = object().shape({
  student: string().max(255).required(REQUIRED_FIELD),
  label: object().shape({
    label: string().max(255).required(REQUIRED_FIELD),
    value: string().max(255).required(REQUIRED_FIELD),
  }),
  amount: number()
    .typeError("Une valeur est nécessaire")
    .min(MIN_BALANCE_ADJUSTMENT_PRICE, `minimum ${MIN_BALANCE_ADJUSTMENT_PRICE}€`)
    .max(MAX_BALANCE_ADJUSTMENT_PRICE, `maximum ${MAX_BALANCE_ADJUSTMENT_PRICE}€`)
    .required(REQUIRED_FIELD),
  commentary: string().max(MAX_BALANCE_ADJUSTMENT_DESCRIPTION_CHARACTERS),
  documentUrl: string().url().max(255),
  organization: string().max(255).required(REQUIRED_FIELD).oneOf(Object.values(ORGANIZATION)),
});

export const formatFormDataToBodyData = (balanceAdjustment: FormInputs): CreateBalanceAdjustmentBody => {
  return {
    studentId: balanceAdjustment.student,
    label: balanceAdjustment.label.value,
    amount: balanceAdjustment.amount,
    commentary: balanceAdjustment.commentary || null,
    documentUrl: balanceAdjustment.documentUrl || null,
    organization: balanceAdjustment.organization,
  };
};
