import { useFieldArray, useFormContext } from "react-hook-form";
import { Objective } from "./objective";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { PlusCircle } from "lucide-react";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { useEffect } from "react";
import { FormInputs } from "./form-validation/funding-request";
import { FundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";

interface Props {
  fundingRequest: FundingRequestVM;
}

export const PersonalizedEducationalProject = ({ fundingRequest }: Props) => {
  const { control, resetField } = useFormContext<FormInputs>();
  const { append, remove } = useFieldArray({
    control,
    name: "personalizedEducationalProject",
  });

  useEffect(() => {
    const personalizedEducationalProject: FormInputs["personalizedEducationalProject"] = [];
    if (fundingRequest.reviewedPersonalizedEducationalProject) {
      for (const objective of fundingRequest.reviewedPersonalizedEducationalProject) {
        personalizedEducationalProject.push({
          title: { label: objective.title, value: objective.title },
          quantifiableObjective: objective.quantifiableObjective,
          quantifiableObjectiveText: objective.quantifiableObjective,
        });
      }
    }
    resetField("personalizedEducationalProject");
    append(personalizedEducationalProject);
  }, [append, fundingRequest, resetField]);

  return (
    <FormField
      control={control}
      name="personalizedEducationalProject"
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>Projet pédagogique personnalisé</FormLabel>
            <FormControl className="w-[100px]">
              <>
                {field.value.map((_, index) => (
                  <Objective key={index} index={index} onRemove={() => remove(index)} />
                ))}
              </>
            </FormControl>
            <FormMessage />
            <div>
              <Button
                onClick={() =>
                  append({
                    title: { label: "", value: "" },
                    quantifiableObjective: "",
                    quantifiableObjectiveText: "",
                  })
                }
                className="mt-2"
                type="button"
                variant="outline"
              >
                <PlusCircle className="mr-3" />
                Ajouter un nouvel objectif
              </Button>
            </div>
          </FormItem>
        );
      }}
    />
  );
};
