import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateLessonBody } from "@academy-context/write/domain/types/admin/lesson";
import { LessonCreate } from "./lesson-create.component";
import { adminRetrieveTeachers } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import { adminCreateLesson } from "../../../write/application/use-cases/admin/lesson-creation/create-lesson";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { LessonsList } from "@academy-context/primary/admin/lesson-admin-detail/lesson-list.components";
import { adminRetrieveLessons } from "@academy-context/read/application/use-cases/admin/lessons-retrieval/all/retrieve-lessons";
import { selectLessonsRetrievalForList } from "@academy-context/read/application/use-cases/admin/lessons-retrieval/all/selectors/lesson-list-selectors";

export const LessonAdminDetail = () => {
  const dispatch = useAppDispatch();
  const { data: lessons } = useAppSelector(selectLessonsRetrievalForList);

  useEffect(() => {
    dispatch(adminRetrieveLessons());
    dispatch(retrieveStudents());
    dispatch(adminRetrieveTeachers());
  }, [dispatch]);

  const saveLesson = async (lesson: CreateLessonBody) => {
    await dispatch(adminCreateLesson(lesson));
  };

  return (
    <div className="space-y-5">
      <LessonCreate onSubmit={saveLesson} />
      <LessonsList lessons={lessons} />
    </div>
  );
};
