import { retrieveUserInvitations } from "../../../../read/application/use-cases/admin/user-invitations-retrieval/retrieve-user-invitations";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { resetInviteStandardUser } from "../../../../write/application/use-cases/admin/standard-user-invitation/invite-standard-user";
import { resetInviteAdminUser } from "@user-management-context/write/application/use-cases/admin/admin-user-invitation/invite-admin-user";

const selectStandardUserInvite = (state: AppState) => state.standardUserInvite;
const selectAdminUserInvite = (state: AppState) => state.adminUserInvite;

export const useInvitationSent = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justInvitedUserId: justInvitedStandardUserId, processing: standardUserInvitationProcessing } =
    useAppSelector(selectStandardUserInvite);
  const { justInvitedUserId: justInvitedAdminUserId, processing: adminUserInvitationProcessing } = useAppSelector(selectAdminUserInvite);

  useEffect(() => {
    if (justInvitedStandardUserId || justInvitedAdminUserId) {
      toast({ description: "Invitation envoyée !" });
      dispatch(resetInviteStandardUser());
      dispatch(resetInviteAdminUser());
      dispatch(retrieveUserInvitations());
    } else if (standardUserInvitationProcessing === "failed" || adminUserInvitationProcessing === "failed") {
      toast({ description: "Erreur lors de l'envoi de l'invitation", variant: "destructive" });
    }
  }, [dispatch, toast, justInvitedStandardUserId, justInvitedAdminUserId, standardUserInvitationProcessing, adminUserInvitationProcessing]);
};
