import { ReactNode } from "react";
import { EMAIL_CONTACT } from "../../../../config/constant";
import { FundingRequestNonEligibilityReasons } from "@academy-context/read/domain/types/student/funding-request";
import { AppState } from "@redux/app-state";
import { useAppSelector } from "@redux/hooks";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { FundingRequestCreateContainer } from "@academy-context/primary/student/funding-request/funding-request-create.container";
import { FormSkeleton } from "@shared-kernel/primary/shared/skeletons/form.skeleton";

const notEligibleForAFundingRequestContent = (reasons: FundingRequestNonEligibilityReasons): ReactNode => {
  return (
    <div>
      Vous ne pouvez pas faire une nouvelle demande de financement car:
      <ul>
        {reasons.hasARunningFundingRequest && <li>Vous avez déjà un dossier en cours d’étude auprès d’OPERA Off et d'un financeur.</li>}
        {reasons.isBalanceTooHigh && <li>Votre solde est supérieur à 3000€</li>}
        {reasons.isFundingRequestAvailable && !reasons.isFundingRequestAvailable.value && (
          <li>
            Votre carence AFDAS se termine le{" "}
            <strong>{new Date(reasons.isFundingRequestAvailable.details.nextFundingRequestDate).toLocaleDateString()}</strong>, vous ne
            pourrez faire une demande de financement AFDAS que 5 mois avant la fin de votre carence soit le{" "}
            <strong>{new Date(reasons.isFundingRequestAvailable.details.nextAnticipatedFundingRequestDate).toLocaleDateString()}</strong>.
          </li>
        )}
      </ul>
      Si vous n’etes pas dans ces cas là, veuillez envoyer un mail à{" "}
      <a href={`mailto:${EMAIL_CONTACT}`} target="_blank" rel="noreferrer" className="text-theme">
        {EMAIL_CONTACT}
      </a>
    </div>
  );
};

// TODO: Finish when I'll have instructions for other funders
// const eligibleForAFundingRequestContent: ReactNode = (
//   <div>
//     Vous souhaitez faire une demande de financement à l’AFDAS:
//     <div className="mt-3 flex justify-center">
//       <img src={instructions} alt="afdas instructions" />
//     </div>
//     <div className="mt-3 flex justify-end">
//       {/* Cannot use the ROLE_BASED_URLS map here */}
//       <NavLink to="/funding-request/new">
//         <Button>J’ai créé mon espace AFDAS / J’ai renouvelé mon mot de passe AFDAS</Button>
//       </NavLink>
//     </div>
//   </div>
// );

export const FundingRequestHomeScreen = () => {
  const { data } = useAppSelector((state: AppState) => state.fundingRequestEligibilityRetrieval);

  if (data?.isEligibleForAFundingRequest) return <FundingRequestCreateContainer />;

  return (
    <CustomCard title="Nouvelle demande de financement">
      {data && data.reasons ? <div className="w-full">{notEligibleForAFundingRequestContent(data.reasons)}</div> : <FormSkeleton />}
    </CustomCard>
  );
};
