import { object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { AddTimelineEventBody, UpdateTimelineEventBody } from "@user-management-context/shared/application/ports/admin-student-gateway";
import { StudentTimelineEvent, TIMELINE_EVENT_TITLE } from "@academy-context/read/domain/types/admin/student-timeline";

export type TimelineEventFormInputs = {
  title: { label: string; value: string };
  eventDate: string;
  description: string;
};

export const defaultTimelineEventValues = {
  title: { value: TIMELINE_EVENT_TITLE.PHONE_CALL, label: TIMELINE_EVENT_TITLE.PHONE_CALL },
  eventDate: new Date().toISOString(),
  description: "",
};

export const timelineEventSchema = object().shape({
  title: object().shape({
    label: string().max(255).required(REQUIRED_FIELD),
    value: string().max(255).required(REQUIRED_FIELD),
  }),
  eventDate: string().max(255).required(REQUIRED_FIELD),
  description: string().required(REQUIRED_FIELD),
});

export const formatFormDataToAdditionBodyData = (studentId: string, timelineEvent: TimelineEventFormInputs): AddTimelineEventBody => {
  const { title, eventDate, description } = timelineEvent;

  return {
    studentId,
    title: title.value,
    eventDate: new Date(eventDate).toISOString(),
    description,
  };
};

export const formatFormDataToUpdateBodyData = (
  { studentId, timelineEventId }: { studentId: string; timelineEventId: string },
  timelineEvent: TimelineEventFormInputs
): UpdateTimelineEventBody => {
  const { title, eventDate, description } = timelineEvent;

  return {
    studentId,
    timelineEventId,
    title: title.value,
    eventDate: new Date(eventDate).toISOString(),
    description,
  };
};

export const formatDataToFormData = (event: StudentTimelineEvent): TimelineEventFormInputs => {
  return {
    title: { label: event.title, value: event.title },
    eventDate: event.eventDate,
    description: event.details.payload.description,
  };
};
