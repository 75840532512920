import { Toaster } from "@components/ui/toaster";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import LoginOutlet from "@shared-kernel/primary/shared/login-outlet";
import LoginPage from "@user-management-context/primary/user/login/login-page";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { createBrowserRouter, Navigate, Outlet, RouterProvider, ScrollRestoration, useLocation } from "react-router-dom";
import "./input.css";
import { PasswordUpdatePage } from "@user-management-context/primary/user/password-update/password-update.components";
import { AdminAccountCreation } from "@academy-context/primary/admin/account-creation/admin-account-creation.components";
import { StudentAccountCreation } from "@user-management-context/primary/student/student-account-creation/student-account-creation.components";
import { TeacherAccountCreation } from "@user-management-context/primary/teacher/teacher-account-creation/teacher-account-creation.components";
import { ProviderAccountCreationContainer } from "@user-management-context/primary/provider/provider-account-creation/provider-account-creation.container";
import { adminRoutes, AppRoute, providerRoutes, studentRoutes, teacherRoutes } from "src/routes";
import { resetRetrieveUserProfile } from "@user-management-context/read/application/use-cases/user-profile/retrieve-user-profile";
import { resetStudentRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/student/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { resetTeacherRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/teacher/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { resetRetrieveFundingRequestEligibility } from "@academy-context/read/application/use-cases/student/funding-request-eligibility-retrieval/retrieve-funding-request-eligibility";
import { logout } from "@user-management-context/write/application/use-cases/user-login/login-user";
import { SideBar } from "@shared-kernel/primary/layout/side-bar/side-bar";
import { resetProviderRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/provider/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "@components/ui/sidebar";
import { HeaderBar } from "@shared-kernel/primary/shared/app-header";

const ProtectedRoute = () => {
  const dispatch = useAppDispatch();
  const { data: user } = useAppSelector((state: AppState) => state.userLogin);

  const onLogout = () => {
    dispatch(resetRetrieveUserProfile());
    dispatch(resetStudentRetrieveTrainingFollowUps());
    dispatch(resetTeacherRetrieveTrainingFollowUps());
    dispatch(resetProviderRetrieveTrainingFollowUps());
    dispatch(resetRetrieveFundingRequestEligibility());

    dispatch(logout(null));
  };
  const isAuthenticated = Object.values(ROLES).includes(user?.role as ROLES);

  const { pathname } = useLocation();
  const isAdmin = user?.role === ROLES.ADMIN;

  return isAuthenticated ? (
    <>
      <ScrollRestoration />
      <SidebarProvider>
        <SideBar logout={onLogout} />
        <SidebarInset className="relative">
          {isAdmin ? <HeaderBar /> : <SidebarTrigger className="absolute left-2 top-2" />}
          <div className="mx-auto my-5 w-[90%]">
            <Outlet />
          </div>
        </SidebarInset>
      </SidebarProvider>
    </>
  ) : (
    <Navigate to="/login" replace state={{ from: pathname }} />
  );
};

function NotFoundPage() {
  return <h1>404 - Page Not Found</h1>;
}

const generateRoutes = (role: ROLES | null) => {
  const { routes, defaultRoute } = generateRoleBasedRoutes(role);

  return createBrowserRouter([
    {
      element: <LoginOutlet />,
      children: [
        {
          path: "/login",
          element: <LoginPage />,
        },
        {
          path: "password/reset",
          element: <PasswordUpdatePage />,
        },
        {
          path: "invite",
          children: [
            {
              path: "admin",
              element: <AdminAccountCreation />,
            },
            {
              path: "student",
              element: <StudentAccountCreation />,
            },
            {
              path: "teacher",
              element: <TeacherAccountCreation />,
            },
            {
              path: "provider",
              element: <ProviderAccountCreationContainer />,
            },
          ],
        },
      ],
    },
    {
      element: <ProtectedRoute />,
      children: [
        ...routes.map(r => ({
          path: r.path,
          element: r.element,
        })),
        {
          path: "/",
          element: <Navigate replace to={defaultRoute} />,
        },

        {
          path: "*",
          element: <NotFoundPage />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/login" />,
    },
  ]);
};

const generateRoleBasedRoutes = (role: ROLES | null) => {
  const isStudent = role === ROLES.STUDENT;
  const isTeacher = role === ROLES.TEACHER;
  const isAdmin = role === ROLES.ADMIN;
  const isProvider = role === ROLES.PROVIDER;

  let routes: AppRoute[] = [];
  let defaultRoute = "dashboard";
  if (isAdmin) {
    routes = adminRoutes;
    defaultRoute = "payments";
  } else if (isStudent) routes = studentRoutes;
  else if (isTeacher) routes = teacherRoutes;
  else if (isProvider) {
    routes = providerRoutes;
  }

  return { routes, defaultRoute };
};

export default function App() {
  const { data: user } = useAppSelector((state: AppState) => state.userLogin);

  return (
    // Dark Theme
    // <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
    <>
      <RouterProvider router={generateRoutes(user?.role ?? null)} />
      <Toaster />
    </>
    // </ThemeProvider>
  );
}
