import { useEffect, useState } from "react";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { Button } from "@components/ui/button";
import { Plus } from "lucide-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Form } from "@components/ui/form";
import { ItemList } from "@academy-context/primary/shared/trainings/items-list.component";
import { InternalTrainingRow } from "@academy-context/read/domain/types/admin/internal-training";
import { InternalTrainingForm } from "@academy-context/primary/admin/internal-trainings/list/internal-training-form.component";
import {
  internalTrainingdefaultValues,
  formatFormDataToBodyData,
  InternalTrainingFormInputs,
  internalTrainingSchema,
} from "@academy-context/primary/admin/internal-trainings/list/form-validation/internal-training";
import {
  AdminCreateInternalTrainingBody,
  AdminUpdateInternalTrainingBody,
} from "@shared-kernel/application/ports/admin/admin-internal-training-repository";

interface Props {
  trainings: InternalTrainingRow[];
  onCreate: (body: AdminCreateInternalTrainingBody) => void;
  onUpdate: (body: AdminUpdateInternalTrainingBody) => void;
  onDelete: (trainingId: string) => void;
}

const newIdPrefix = "new-id";

export const InternalTrainingListComponent = ({ trainings, onCreate, onUpdate, onDelete }: Props) => {
  const [selectedItemId, setSelectedItemId] = useState<Nullable<string>>(null);
  const [selectedItem, setSelectedItem] = useState<Nullable<InternalTrainingRow>>(null);
  // This array is used to display the list of trainings including possible new trainings
  const [trainingsReadOnly, setTrainingsReadOnly] = useState<InternalTrainingRow[]>(trainings);
  const [newIdCounter, setNewIdCounter] = useState(0);
  const form = useForm<InternalTrainingFormInputs>({
    resolver: yupResolver(internalTrainingSchema),
    defaultValues: internalTrainingdefaultValues,
  });
  const { watch, reset } = form;
  const isNewTrainingInList = trainingsReadOnly.some(training => training.id.includes(newIdPrefix));

  // Handles initialization
  useEffect(() => {
    const firstElement = trainings[0];
    if (firstElement) setSelectedItemId(firstElement.id);
  }, [trainings]);

  // Handles the item selection
  useEffect(() => {
    const hasChanged = selectedItem?.id !== selectedItemId;
    if (hasChanged) {
      const item = trainingsReadOnly.find(item => item.id === selectedItemId);
      setSelectedItem(item ?? null);
    }
  }, [selectedItem?.id, selectedItemId, trainingsReadOnly]);

  const title = watch("title");
  const objective = watch("objective");
  const tag = watch("tag");

  // Handles the syncronization between form and list
  useEffect(() => {
    const copy = [...trainingsReadOnly];
    const modifiedTrainingIndex = copy.findIndex(item => item.id === selectedItemId);
    if (modifiedTrainingIndex >= 0) {
      const selectedTraining = copy.splice(modifiedTrainingIndex, 1);
      const chips = [];
      if (tag) chips.push(tag);
      if (selectedTraining.length) {
        // Verified above
        const updatedTraining = { ...selectedTraining[0]!, title, preview: objective, chips, id: selectedTraining[0]!.id };
        copy.splice(modifiedTrainingIndex, 0, updatedTraining);
        setTrainingsReadOnly(copy);
      }
    }
    // trainingsReadOnly is not included otherwise it triggers an infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objective, tag, title]);

  // Resets list when changing selection
  useEffect(() => {
    const isNewAdded = selectedItemId?.includes(newIdPrefix);
    if (isNewAdded) {
      const newTrainings = trainingsReadOnly.filter(training => training.id.includes(newIdPrefix));
      setTrainingsReadOnly([...newTrainings, ...trainings]);
    } else {
      setTrainingsReadOnly(trainings);
    }
    // Disabled other triggers an infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemId, trainings]);

  const handleOnSubmit = async (formBody: InternalTrainingFormInputs) => {
    const isAnUpdate = !selectedItemId?.includes(newIdPrefix);
    const body = formatFormDataToBodyData(formBody);
    if (isAnUpdate && selectedItem) {
      onUpdate({ ...body, trainingId: selectedItem.id });
      reset(formBody);
    } else {
      onCreate(body);
    }
  };

  const addNewTraining = () => {
    reset(internalTrainingdefaultValues);
    const newTraining: InternalTrainingRow = {
      id: `${newIdPrefix}-${newIdCounter}`,
      creationDate: new Date().toISOString(),
      tag: internalTrainingdefaultValues.tag,
      objective: internalTrainingdefaultValues.objective,
      description: internalTrainingdefaultValues.description,
      team: internalTrainingdefaultValues.team,
      price: internalTrainingdefaultValues.price ?? 0,
      title: internalTrainingdefaultValues.title,
      questions: internalTrainingdefaultValues.questions,
      chips: [],
      preview: internalTrainingdefaultValues.objective,
    };
    setTrainingsReadOnly([newTraining, ...trainingsReadOnly]);
    setSelectedItemId(newTraining.id);
    setNewIdCounter(newIdCounter + 1);
  };

  const isNewSelected = Boolean(selectedItemId?.includes(newIdPrefix));

  return (
    <div className="grid grid-cols-6">
      <div className="col-span-2 flex flex-col items-center space-y-2 p-2">
        <Button onClick={addNewTraining} disabled={isNewTrainingInList}>
          <Plus />
          Ajouter une formation
        </Button>
        <ItemList items={trainingsReadOnly} selectedItemId={selectedItemId} setSelectedItemId={setSelectedItemId} />
      </div>
      <div className="col-span-4">
        {Boolean(trainingsReadOnly.length) && (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleOnSubmit)}>
              <InternalTrainingForm training={selectedItem} onDelete={onDelete} isNew={isNewSelected} />
            </form>
          </Form>
        )}
      </div>
    </div>
  );
};
