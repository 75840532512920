import { useState } from "react";
import { extractCSV } from "../../../../utils/download";
import { formatDateToLocale, formatNumberToLocale, formatToCurrency } from "../../../../utils/formatting";
import { Modal } from "@shared-kernel/primary/shared/modal/modal";
import { format, endOfDay, startOfDay } from "date-fns";
import { getLocationPrice } from "@academy-context/write/domain/services/locations";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { cn } from "@components/utils/utils";
import { selectLessonsRetrievalForList } from "@academy-context/read/application/use-cases/admin/lessons-retrieval/all/selectors/lesson-list-selectors";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";

const underConventionFormatter = (s: string | number, isUnderConvention: boolean) => (
  <span className={cn(isUnderConvention && "font-bold text-theme")}>{s}</span>
);

type SelectorReturnType = ReturnType<typeof selectLessonsRetrievalForList>["data"][number];

const columns: ColumnDef<SelectorReturnType>[] = [
  {
    accessorKey: "isUnderConvention",
    header: () => {},
    cell: () => {},
    enableSorting: false,
    meta: {
      getCellContext: (context: CellContext<SelectorReturnType, unknown>) => {
        const isUnderConvention = context.getValue();
        return {
          className: isUnderConvention ? "bg-theme" : "",
        };
      },
      title: "Sous convention",
      width: "10px",
    },
  },
  {
    accessorKey: "creationDate",
    header: () => "Date de création",
    cell: ({ row }) => {
      const { isUnderConvention, creationDate } = row.original;
      const localeDate = formatDateToLocale(creationDate);
      return underConventionFormatter(localeDate, isUnderConvention);
    },
    meta: {
      title: "Date de création",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "date",
    header: () => "Date du cours",
    cell: ({ row }) => {
      const { isUnderConvention, date } = row.original;
      const localeDate = formatDateToLocale(date);
      return underConventionFormatter(localeDate, isUnderConvention);
    },
    meta: {
      title: "Date du cours",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    cell: ({ row }) => {
      const { isUnderConvention, student } = row.original;
      return underConventionFormatter(student, isUnderConvention);
    },
    enableSorting: true,
    meta: {
      title: "Elève",
    },
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    cell: ({ row }) => {
      const { isUnderConvention, teacher } = row.original;
      return underConventionFormatter(teacher, isUnderConvention);
    },
    enableSorting: true,
    meta: {
      title: "Professeur",
    },
  },
  {
    accessorKey: "hours",
    header: () => "Nbr. heures",
    cell: ({ row }) => {
      const { isUnderConvention, hours } = row.original;
      return underConventionFormatter(hours, isUnderConvention);
    },
    meta: {
      title: "Nbr. heures",
      width: "60px",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire",
    cell: ({ row }) => {
      const { isUnderConvention, hourlyPrice } = row.original;
      return underConventionFormatter(formatToCurrency(hourlyPrice), isUnderConvention);
    },
    meta: {
      title: "Tarif horaire",
      width: "70px",
    },
  },
  {
    accessorKey: "placeName",
    header: () => "Studio",
    cell: ({ row }) => {
      const { isUnderConvention, placeName } = row.original;
      return underConventionFormatter(placeName, isUnderConvention);
    },
    meta: {
      title: "Studio",
      width: "120px",
    },
  },
  {
    accessorKey: "place.price",
    header: () => "Tarif horaire studio",
    cell: ({ row }) => {
      const { isUnderConvention, place } = row.original;
      return underConventionFormatter(formatToCurrency(place.price ?? 0), isUnderConvention);
    },
    meta: {
      title: "Tarif horaire studio",
      width: "70px",
    },
  },
  {
    accessorKey: "amountChargedOnStudentBalance",
    header: () => "Montant imputé sur le solde de l'élève",
    cell: ({ row }) => {
      const { isUnderConvention, amountChargedOnStudentBalance } = row.original;
      return underConventionFormatter(formatToCurrency(amountChargedOnStudentBalance), isUnderConvention);
    },
    meta: {
      title: "Montant imputé sur le solde de l'élève",
      width: "70px",
    },
  },
  {
    accessorKey: "paidToTeacher",
    header: () => "Règlement professeur",
    cell: ({ row }) => {
      const { isUnderConvention, paidToTeacher } = row.original;
      return underConventionFormatter(formatToCurrency(paidToTeacher), isUnderConvention);
    },
    meta: {
      title: "Règlement professeur",
      width: "80px",
    },
  },
  {
    accessorKey: "organization",
    header: () => "Société",
    cell: ({ row }) => {
      const { isUnderConvention, organization } = row.original;
      return underConventionFormatter(ORGANIZATION_NAME[organization].short, isUnderConvention);
    },
    meta: {
      title: "Société",
    },
    enableSorting: true,
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    meta: {
      title: "Commentaire",
    },
  },
];

const isValidInterval = (from: Date | null, to: Date | null): boolean => {
  if (!(from && to)) return false;
  return from <= to;
};

interface Props {
  lessons: SelectorReturnType[];
}

export const LessonsList = ({ lessons }: Props) => {
  const now = new Date();
  const [from, setFrom] = useState<Date>(now);
  const [to, setTo] = useState<Date>(now);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onExport = () => {
    const start = startOfDay(from);
    const end = endOfDay(to);
    const filteredLessons = lessons.filter(l => new Date(l.date) >= start && new Date(l.date) <= end);
    const dataToExport = filteredLessons
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .map(l => {
        const isPersonalTeacherStudio = l.place.price === null;
        const placePrice = !isPersonalTeacherStudio
          ? formatNumberToLocale(l.place.price!)
          : l.place.name
          ? getLocationPrice(l.place.name)
          : null;
        return [
          l.id,
          l.studentLastName,
          l.studentName,
          l.teacher,
          formatDateToLocale(l.date),
          formatNumberToLocale(l.hours, 2),
          formatNumberToLocale(l.hourlyPrice, 2),
          l.placeName,
          placePrice,
          formatNumberToLocale(l.amountChargedOnStudentBalance, 2),
          formatNumberToLocale(l.paidToTeacher, 2),
          l.commentary,
          l.isUnderConvention,
          l.isUnderOldConvention,
          l.isUnderConvention && !l.isUnderOldConvention,
        ];
      });
    const fields = [
      "identifiant",
      "nom",
      "prenom",
      "professeur",
      "date du cours",
      "heures",
      "taux horaire",
      "studio",
      "taux horaire studio",
      "total imputé à l'élève",
      "règlement professeur",
      "commentaire",
      "sous_convention",
      "sous_ancienne_convention",
      "sous_nouvelle_convention",
    ];

    const fileName = `lessons_extract_${format(from!, "yyyy-MM-dd")}_${format(to!, "yyyy-MM-dd")}.csv`;

    extractCSV(dataToExport, fields, fileName);
  };

  const onModalSubmit = () => {
    onExport();
    setFrom(now);
    setTo(now);
    setIsModalOpen(false);
  };

  const modalBody = (
    <>
      <div className="grid grid-cols-4 items-center">
        <Label className="col-span-1">A partir de:</Label>
        <div className="col-span-3">
          <PopoverCalendar value={from.toISOString()} onChange={value => setFrom(value)} />
        </div>
      </div>
      <div className="grid grid-cols-4 items-center">
        <Label className="col-span-1">Jusqu'au:</Label>
        <div className="col-span-3">
          <PopoverCalendar value={to.toISOString()} onChange={value => setTo(value)} />
        </div>
      </div>
    </>
  );

  return (
    <>
      <CustomCard title="Cours" headerChildren={<Button onClick={() => setIsModalOpen(true)}>Télécharger les cours</Button>}>
        <DataTable
          columns={columns}
          data={lessons}
          sortField="date"
          order="desc"
          searchPlaceHolder="Rechercher un cours"
          displayDataViewOptions
          pageSize={50}
        />
      </CustomCard>
      <Modal
        title="Télécharger les cours"
        body={modalBody}
        onSubmit={onModalSubmit}
        onClose={() => setIsModalOpen(false)}
        submitText="Télécharger"
        submitDisabled={!isValidInterval(from, to)}
        isOpen={isModalOpen}
      />
    </>
  );
};
