import { createReducer } from "@reduxjs/toolkit";
import { StudentBalanceAdjustmentsRetrievalState } from "@redux/app-state";
import { studentRetrieveBalanceAdjustments } from "./retrieve-balance-adjustments";

const initialState: StudentBalanceAdjustmentsRetrievalState = { fetching: "pending", data: [] };

export const studentRetrieveBalanceAdjustmentsReducer = createReducer<StudentBalanceAdjustmentsRetrievalState>(initialState, builder => {
  builder.addCase(studentRetrieveBalanceAdjustments.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(studentRetrieveBalanceAdjustments.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(studentRetrieveBalanceAdjustments.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
