import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { retrieveReviewedFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/reviewed-funding-request-list-retrieval/retrieve-reviewed-funding-requests";
import { retrieveSubmittedToFunderFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/submitted-to-funder/retrieve-submitted-to-funder-funding-requests";
import { AppState } from "src/redux/app-state";
import { retrieveToReviewFundingRequests } from "@academy-context/read/application/use-cases/admin/funding-request-list/to-review-funding-request-list-retrieval/retrieve-to-review-funding-requests";
import { resetDeleteFundingRequest } from "@academy-context/write/application/use-cases/admin/funding-request-deletion/delete-funding-request";
import { resetSubmitFundingRequestToFunder } from "@academy-context/write/application/use-cases/admin/funding-request-submission-to-funder/submit-funding-request-to-funder";
import { resetRevertFundingRequestToReview } from "@academy-context/write/application/use-cases/admin/funding-request-reversion/revert-funding-request-to-review";
import { resetUpdateFundingRequestError } from "@academy-context/write/application/use-cases/admin/funding-request-error-update/update-funding-request-error";

const selectfundingRequestSubmissionToFunder = (state: AppState) => state.fundingRequestSubmissionToFunder;
const selectFundingRequestErrorUpdate = (state: AppState) => state.fundingRequestErrorUpdate;
const selectFundingRequestReversion = (state: AppState) => state.fundingRequestReversion;
const selectFundingRequestDeletion = (state: AppState) => state.fundingRequestDeletion;

export const useFundingRequestUpdated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing: fundingRequestSubmissionToFunderProcessing } = useAppSelector(selectfundingRequestSubmissionToFunder);
  const { processing: fundingRequestErrorUpdateProcessing } = useAppSelector(selectFundingRequestErrorUpdate);
  const { processing: reversionProcessing } = useAppSelector(selectFundingRequestReversion);
  const { processing: deleteProcessing } = useAppSelector(selectFundingRequestDeletion);

  useEffect(() => {
    if (
      fundingRequestSubmissionToFunderProcessing === "success" ||
      fundingRequestErrorUpdateProcessing === "success" ||
      reversionProcessing === "success"
    ) {
      toast({ description: "Demande de financement mise à jour !" });
      dispatch(retrieveToReviewFundingRequests());
      dispatch(retrieveReviewedFundingRequests());
      dispatch(retrieveSubmittedToFunderFundingRequests());
    } else if (
      fundingRequestSubmissionToFunderProcessing === "failed" ||
      fundingRequestErrorUpdateProcessing === "failed" ||
      reversionProcessing === "failed"
    ) {
      toast({
        description: "Erreur lors de la mise à jour de la demande de financement. Veuillez contacter l'administrateur",
        variant: "destructive",
      });
    }
  }, [reversionProcessing, dispatch, fundingRequestSubmissionToFunderProcessing, fundingRequestErrorUpdateProcessing, toast]);

  useEffect(() => {
    if (deleteProcessing === "success") {
      toast({ description: "Demande de financement supprimée !" });
      dispatch(retrieveToReviewFundingRequests());
      dispatch(retrieveReviewedFundingRequests());
      dispatch(retrieveSubmittedToFunderFundingRequests());
    } else if (deleteProcessing === "failed") {
      toast({
        description: "Erreur lors de la suppression de la demande de financement. Veuillez contacter l'administrateur",
        variant: "destructive",
      });
    }
  }, [dispatch, deleteProcessing, toast]);

  useEffect(() => {
    return () => {
      if (
        ["success", "failed"].includes(fundingRequestSubmissionToFunderProcessing) ||
        ["success", "failed"].includes(fundingRequestErrorUpdateProcessing) ||
        ["success", "failed"].includes(reversionProcessing) ||
        ["success", "failed"].includes(deleteProcessing)
      ) {
        dispatch(resetDeleteFundingRequest());
        dispatch(resetSubmitFundingRequestToFunder());
        dispatch(resetRevertFundingRequestToReview());
        dispatch(resetUpdateFundingRequestError());
      }
    };
  }, [dispatch, fundingRequestSubmissionToFunderProcessing, fundingRequestErrorUpdateProcessing, reversionProcessing, deleteProcessing]);
};
