import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { studentRetrieveBalanceAdjustments } from "../../../read/application/use-cases/student/balance-adjustments-retrieval/retrieve-balance-adjustments";
import { selectStudentBalanceAdjustmentsRetrievalForList } from "../../../read/application/use-cases/student/balance-adjustments-retrieval/selectors/balance-adjustments-list-selectors";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { TableSkeleton } from "@shared-kernel/primary/shared/skeletons/table.skeleton";
import { SquareArrowOutUpRight } from "lucide-react";
import { cn } from "@components/utils/utils";

type SelectorReturnType = ReturnType<typeof selectStudentBalanceAdjustmentsRetrievalForList>["data"][number];

const columns: ColumnDef<SelectorReturnType>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => formatDateToLocale(row.original.creationDate),
    enableSorting: true,
    meta: {
      title: "Enregistré le",
      width: "140px",
    },
  },
  {
    accessorKey: "label",
    header: () => "Type",
    enableSorting: true,
    meta: {
      title: "Type",
      width: "140px",
    },
  },
  {
    accessorKey: "amount",
    header: () => "Montant",
    cell: info => {
      const { amount, isCredited } = info.row.original;
      let transactionSign = "";
      if (isCredited) transactionSign = "+";
      return (
        <span className={cn(isCredited && "font-bold text-success")}>
          {transactionSign}
          {formatToCurrency(amount)}
        </span>
      );
    },
    enableSorting: true,
    meta: {
      title: "Montant",
      width: "140px",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Description",
    meta: {
      title: "Description",
    },
  },
  {
    accessorKey: "documentUrl",
    header: () => "Document",
    cell: info =>
      info.row.original.documentUrl ? (
        <>
          <a href={info.row.original.documentUrl} target="_blank" rel="noreferrer">
            <SquareArrowOutUpRight size={16} className="cursor-pointer text-blue-600 visited:text-purple-600 dark:text-blue-500" />
          </a>
        </>
      ) : null,
    meta: {
      title: "Document",
      width: "80px",
    },
  },
];

export const StudentBalanceAdjustmentListContainer = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(selectStudentBalanceAdjustmentsRetrievalForList);

  useEffect(() => {
    dispatch(studentRetrieveBalanceAdjustments());
  }, [dispatch]);

  return (
    <CustomCard title="HISTORIQUE DES AJUSTEMENTS DE SOLDE">
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <DataTable
          columns={columns}
          data={data}
          searchPlaceHolder="Rechercher un ajustement de solde"
          sortField="creationDate"
          order="desc"
        />
      )}
    </CustomCard>
  );
};
