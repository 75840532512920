import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { BalanceAdjustmentCreate } from "./balance-adjustment-create.component";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { retrieveBalanceAdjustments } from "../../../read/application/use-cases/admin/balance-adjustments-retrieval/retrieve-balance-adjustments";
import { createBalanceAdjustment } from "../../../write/application/use-cases/admin/balance-adjustment-creation/create-balance-adjustment";
import { CreateBalanceAdjustmentBody } from "@shared-kernel/application/ports/admin/balance-adjustment-repository";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { BalanceAdjustmentsList } from "@academy-context/primary/admin/balance-adjustment-detail/balance-adjustment-list.components";
import { selectBalanceAdjustmentsRetrievalForList } from "@academy-context/read/application/use-cases/admin/balance-adjustments-retrieval/selectors/balance-adjustment-list-selectors";
import { selectRegisteredStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/registered-students-selectors";
import { selectBalanceAdjustmentCreate } from "@academy-context/primary/admin/balance-adjustment-detail/use-balance-adjustment-create";
import { FormSkeleton } from "@shared-kernel/primary/shared/skeletons/form.skeleton";
import { Card, CardContent } from "@components/ui/card";

export const BalanceAdjustmentAdminDetailContainer = () => {
  const dispatch = useAppDispatch();
  const { data: balanceAdjustments, isLoading } = useAppSelector(selectBalanceAdjustmentsRetrievalForList);
  const students = useAppSelector(selectRegisteredStudents);
  const { processing } = useAppSelector(selectBalanceAdjustmentCreate);

  useEffect(() => {
    dispatch(retrieveStudents());
    dispatch(retrieveBalanceAdjustments());
  }, [dispatch]);

  const saveBalanceAdjustment = async (balanceAdjustment: CreateBalanceAdjustmentBody) => {
    await dispatch(createBalanceAdjustment(balanceAdjustment));
  };

  if (isLoading) return <FormSkeleton />;

  return (
    <>
      <CustomCard title="Nouvel ajustement de solde">
        <BalanceAdjustmentCreate onSubmit={saveBalanceAdjustment} students={students} processing={processing} />
      </CustomCard>
      <Card className="mt-4">
        <CardContent className="flex flex-col items-center pt-3 text-black dark:text-white">
          <BalanceAdjustmentsList balanceAdjustments={balanceAdjustments} />
        </CardContent>
      </Card>
    </>
  );
};
