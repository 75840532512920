import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

import { retrieveLessons } from "../../../read/application/use-cases/teacher/lessons-retrieval/retrieve-lessons";
import { selectTeacherLessonsRetrievalForList } from "../../../read/application/use-cases/teacher/lessons-retrieval/selectors/lessons-list-selectors";
import { TeacherLessonListVM } from "@academy-context/read/domain/types/teacher/lesson";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { getPlaceName } from "@academy-context/write/domain/services/locations";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import clsx from "clsx";

const underConventionFormatter = (s: string | number, isUnderConvention: boolean) => (
  <span className={clsx(isUnderConvention && "font-bold text-theme")}>{s}</span>
);

const columns: ColumnDef<TeacherLessonListVM>[] = [
  {
    accessorKey: "isUnderConvention",
    header: () => {},
    cell: () => {},
    enableSorting: false,
    meta: {
      getCellContext: (context: CellContext<TeacherLessonListVM, unknown>) => {
        const isUnderConvention = context.getValue();
        return {
          className: isUnderConvention ? "bg-theme" : "",
        };
      },
      title: "Sous convention",
      width: "20px",
    },
  },
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => {
      const { isUnderConvention, creationDate } = row.original;
      const localeDate = formatDateToLocale(creationDate);
      return underConventionFormatter(localeDate, isUnderConvention);
    },
    meta: {
      title: "Enregistré le",
      width: "95px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "date",
    header: () => "Date du cours",
    cell: ({ row }) => {
      const { isUnderConvention, date } = row.original;
      const localeDate = formatDateToLocale(date);
      return underConventionFormatter(localeDate, isUnderConvention);
    },
    meta: {
      title: "Date du cours",
      width: "95px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    cell: ({ row }) => {
      const { isUnderConvention, student } = row.original;
      return underConventionFormatter(student, isUnderConvention);
    },
    enableSorting: true,
    meta: {
      title: "Elève",
    },
  },
  {
    accessorKey: "place",
    header: () => "Studio",
    cell: ({ row }) => {
      const { isUnderConvention, place } = row.original;
      return underConventionFormatter(getPlaceName(place.paidByTeacher, place.name), isUnderConvention);
    },
    meta: {
      title: "Studio",
    },
  },
  {
    accessorKey: "hours",
    header: () => "Durée du cours (heures)",
    cell: ({ row }) => {
      const { isUnderConvention, hours } = row.original;
      return underConventionFormatter(hours, isUnderConvention);
    },
    meta: {
      title: "Durée du cours",
      width: "90px",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire (€/heure)",
    cell: ({ row }) => {
      const { isUnderConvention, hourlyPrice } = row.original;
      return underConventionFormatter(formatToCurrency(hourlyPrice), isUnderConvention);
    },
    meta: {
      title: "Tarif horaire professeur",
      width: "90px",
    },
  },
  {
    accessorKey: "paidToTeacher",
    header: () => "Règlement professeur",
    cell: ({ row }) => {
      const { isUnderConvention, paidToTeacher } = row.original;
      return underConventionFormatter(formatToCurrency(paidToTeacher), isUnderConvention);
    },
    meta: {
      title: "Règlement professeur",
      width: "90px",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    enableSorting: false,
    meta: {
      title: "Commentaire",
    },
  },
];

export const TeacherLessonsList = () => {
  const dispatch = useAppDispatch();
  const { data: lessons } = useAppSelector(selectTeacherLessonsRetrievalForList);

  useEffect(() => {
    dispatch(retrieveLessons());
  }, [dispatch]);

  return (
    <CustomCard title="HISTORIQUE DES COURS">
      <DataTable
        columns={columns}
        data={lessons}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher un cours"
        displayDataViewOptions
      />
    </CustomCard>
  );
};
