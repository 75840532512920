import { retrieveBalanceAdjustments } from "../../../read/application/use-cases/admin/balance-adjustments-retrieval/retrieve-balance-adjustments";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { UseFormReset } from "react-hook-form";
import { FormInputs } from "./form-validation/balance-adjustment";
import { resetCreateBalanceAdjustment } from "../../../write/application/use-cases/admin/balance-adjustment-creation/create-balance-adjustment";
import { retrieveStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { resetUploadDocument } from "@academy-context/write/application/use-cases/admin/document-upload/upload-document";

export const selectBalanceAdjustmentCreate = (state: AppState) => state.balanceAdjustmentCreate;

interface Props {
  reset: UseFormReset<FormInputs>;
}

export const useBalanceAdjustmentCreate = ({ reset }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedId, processing } = useAppSelector(selectBalanceAdjustmentCreate);

  useEffect(() => {
    if (justCreatedId) {
      toast({ description: "Ajustement de solde ajouté !" });
      reset();
      dispatch(resetCreateBalanceAdjustment());
      dispatch(retrieveBalanceAdjustments());
      dispatch(retrieveStudents());
      dispatch(resetUploadDocument());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'ajustement du solde", variant: "destructive" });
    }
  }, [justCreatedId, processing, dispatch, toast, reset]);
};
