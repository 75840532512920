import { object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { ReportAnIssueBody } from "@user-management-context/shared/application/ports/user-gateway";
import { ISSUE_TYPE } from "@user-management-context/write/domain/types/shared/issue-reporting";

export type IssueReportingFormInputs = {
  type: ISSUE_TYPE;
  description: string;
};

export const issueReportingdefaultValues = {
  type: ISSUE_TYPE.UNEXPECTED_EVENT,
  description: "",
};

export const issueReportingSchema = object().shape({
  type: string().oneOf(Object.values(ISSUE_TYPE)).required(REQUIRED_FIELD),
  description: string().max(8000).required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (training: IssueReportingFormInputs): Omit<ReportAnIssueBody, "providerId"> => {
  const { type, description } = training;

  return {
    type,
    description,
  };
};
