import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveTrainingFollowUpSessions } from "../../../read/application/use-cases/shared/training-follow-up-sessions-retrieval/retrieve-training-follow-up-sessions";
import { teacherRetrieveTrainingFollowUps } from "../../../read/application/use-cases/teacher/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { studentRetrieveTrainingFollowUps } from "../../../read/application/use-cases/student/training-follow-ups-retrieval/retrieve-training-follow-ups";

export const useAttendanceSheetSignature = (id: string | undefined, role: "student" | "teacher", isAdmin: boolean) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector((state: AppState) => state.attendanceSheetSignature);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Signature enregistrée !" });
      if (id) dispatch(retrieveTrainingFollowUpSessions(id));

      if (!isAdmin) {
        // Used to display correct status on teacher / student sidebar
        if (role === "student") dispatch(studentRetrieveTrainingFollowUps());
        else dispatch(teacherRetrieveTrainingFollowUps());
      }
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'enregistrement de la signature", variant: "destructive" });
    }
  }, [dispatch, processing, id, role, toast, isAdmin]);
};
