import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";

export const createFundingRequest = createAsyncThunk<string, CreateFundingRequestBody, { extra: Partial<Dependencies> }>(
  "fundingRequests/createFundingRequest",
  async (fundingRequest: CreateFundingRequestBody, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.create(fundingRequest);
  }
);

export const resetCreateFundingRequest = createAction("fundingRequests/resetCreateFundingRequest");
