import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { IssueReportingForm } from "@user-management-context/primary/shared/issue-reporting/issue-reporting-form.component";
import { useIssueReported } from "@user-management-context/primary/shared/issue-reporting/use-issue-reported";
import { ReportAnIssueBody } from "@user-management-context/shared/application/ports/user-gateway";
import { reportIssue } from "@user-management-context/write/application/use-cases/shared/issue-reporting/report-issue";

export const IssueReportingContainer = () => {
  const dispatch = useAppDispatch();
  const { processing } = useAppSelector((state: AppState) => state.issueReporting);

  const onSubmit = (body: ReportAnIssueBody) => {
    dispatch(reportIssue(body));
  };

  useIssueReported();

  return <IssueReportingForm onSubmit={onSubmit} processing={processing} />;
};
