import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../config/backend";
import { DocumentUploadProvider } from "@shared-kernel/application/ports/shared/document-upload-provider";

export class BackendDocumentUploadProvider extends HttpGateway implements DocumentUploadProvider {
  private _route: string = "v1/admin";
  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async uploadDocument(file: File): Promise<string> {
    const formDataBody = new FormData();
    formDataBody.append("document", file);

    const res = await this._instance.put(`${this._route}/documents:upload`, formDataBody);
    return res.data;
  }
}
