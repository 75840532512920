import { object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { InviteAdminUserBody } from "@user-management-context/shared/application/ports/user-invitation-port";

export type FormInputs = {
  email: string;
  name: string;
  lastName: string;
};

export const defaultValues = {
  email: "",
  name: "",
  lastName: "",
};

export const schema = object().shape({
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (user: FormInputs): InviteAdminUserBody => {
  return {
    email: user.email ? user.email.toLowerCase().trim() : "",
    name: user.name,
    lastName: user.lastName,
  };
};
