import { useEffect, useState } from "react";
import { UserInvitation } from "../../../../read/application/use-cases/admin/user-invitations-retrieval/selectors/user-invitation-list-selectors";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale } from "../../../../../utils/formatting";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@shared-kernel/primary/shared/shadcn/ui/tabs";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { RefreshCw } from "lucide-react";

const columns: ColumnDef<UserInvitation>[] = [
  {
    accessorKey: "updatedAt",
    header: () => "Date d'envoi",
    cell: ({ row }) => formatDateToLocale(row.original.updatedAt),
    enableSorting: true,
    meta: {
      title: "Date d'envoi",
      width: "140px",
    },
  },
  {
    accessorKey: "roleLabel",
    header: () => "Rôle",
    enableSorting: true,
    meta: {
      title: "Rôle",
      width: "140px",
    },
  },
  {
    accessorKey: "name",
    header: () => "Prénom",
    enableSorting: true,
    meta: {
      title: "Prénom",
      width: "250px",
    },
  },
  {
    accessorKey: "lastName",
    header: () => "Nom",
    enableSorting: true,
    meta: {
      title: "Nom",
      width: "150px",
    },
  },
  {
    accessorKey: "email",
    header: () => "Email",
    enableSorting: true,
    meta: {
      title: "Email",
    },
  },
];

interface Props {
  valid: UserInvitation[];
  expired: UserInvitation[];
  onRefresh: (email: string) => void;
}

export const InvitationsListComponent = ({ valid, expired, onRefresh }: Props) => {
  const [dataColumns, setDataColumns] = useState<ColumnDef<UserInvitation>[]>([...columns]);

  useEffect(() => {
    const refreshColumn: ColumnDef<UserInvitation> = {
      accessorKey: "refresh",
      header: () => <></>,
      cell: ({ row }) => {
        const { email } = row.original;
        return (
          <RefreshCw
            key={email}
            onClick={() => {
              onRefresh(email);
            }}
            className="cursor-pointer hover:text-gray-500"
          />
        );
      },
      meta: {
        width: "30px",
      },
    };
    setDataColumns([...columns, refreshColumn]);
  }, [valid, expired, onRefresh]);

  return (
    <Tabs defaultValue="current" className="mt-5">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="current">Invitations en cours</TabsTrigger>
        <TabsTrigger value="expired">Invitations expirées</TabsTrigger>
      </TabsList>
      <TabsContent value="current">
        <Card>
          <CardContent className="space-y-2">
            <DataTable
              columns={dataColumns}
              data={valid}
              sortField="updatedAt"
              order="desc"
              searchPlaceHolder="Rechercher une invitation"
            />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="expired">
        <Card>
          <CardContent className="space-y-2">
            <DataTable
              columns={dataColumns}
              data={expired}
              sortField="updatedAt"
              order="desc"
              searchPlaceHolder="Rechercher une invitation"
            />
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
};
