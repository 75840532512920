import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../config/backend";
import { AdminBudgetListVM } from "@academy-context/read/domain/types/admin/budget";
import { CreateBudgetBody } from "@academy-context/write/domain/types/admin/budget";
import { AdminBudgetRepository } from "@shared-kernel/application/ports/admin/budget-repository";

export class AdminBackendBudgetRepository extends HttpGateway implements AdminBudgetRepository {
  private _route: string = "v1/admin/budgets";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<AdminBudgetListVM[]> {
    const { data: budgets } = await this._instance.get(this._route);
    return budgets;
  }

  async create(budget: CreateBudgetBody): Promise<string> {
    const { data: budgetId } = await this._instance.put(this._route, budget);
    return budgetId;
  }
}
