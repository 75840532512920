import { ORGANIZATION } from "@shared-kernel/domain/organisation";
import { PLACE_VALUE } from "../../services/locations";
import { Nullable } from "@shared-kernel/core/types/nullable";

// TODO: Change?
export interface Lesson {
  id: string;
  creationDate: string;
  date: string;
  studentId: string;
  teacherId: string;
  hours: number;
  hourlyPrice: number;
  commentary: string;
  place: string;
  createdAt: string;
}

export interface AdminLessonListVM {
  id: string;
  creationDate: string;
  date: string;
  studentName: string;
  studentLastName: string;
  teacher: string;
  hours: number;
  hourlyPrice: number;
  place: {
    name: Nullable<PLACE_VALUE>;
    price: Nullable<number>;
    paidByTeacher: boolean;
  };
  amountChargedOnStudentBalance: number;
  paidToTeacher: number;
  commentary: string;
  isUnderConvention: boolean;
  isUnderOldConvention: boolean;
  organization: ORGANIZATION;
}

export enum STUDIO_CHOICES {
  FREE = "free",
  PAID_BY_TEACHER = "paidByTeacher",
  PAID_BY_OPERA_OFF = "paidByOperaOff",
}

export const StudioChoicesMap: Record<STUDIO_CHOICES, string> = {
  [STUDIO_CHOICES.FREE]: "Studio non payant (perso)",
  [STUDIO_CHOICES.PAID_BY_OPERA_OFF]: "Studio payé par Opera OFF",
  [STUDIO_CHOICES.PAID_BY_TEACHER]: "Studio payé par le professeur",
};
