import { Page } from "@shared-kernel/primary/shared/page";
import { IssueReportingContainer } from "@user-management-context/primary/shared/issue-reporting/issue-reporting.container";

export const IssueReportingPage = () => {
  return (
    <Page title="Nouveau signalement">
      <IssueReportingContainer />
    </Page>
  );
};
