import { isPossiblePhoneNumber } from "react-phone-number-input";
import { number, object, string } from "yup";
import { Teacher } from "../../../../../user-management-context/read/domain/types/admin/teacher";
import { AdminUpdateTeacherBody } from "../../../../../user-management-context/write/domain/types/admin/teacher";
import { MAX_HOURLY_PRICE } from "../../../../write/domain/constants/shared";
import { isValid } from "iban";
import { ACTIVITY_REGISTRATION_NUMBER_ERROR_MESSAGE, REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { Nullable } from "@shared-kernel/core/types/nullable";

export type FormInputs = {
  name: string;
  lastName: string;
  email: string;
  mobile: string;
  defaultPrice: Nullable<number>;
  address: string;
  additionalAddress: string;
  siret: string;
  iban: string;
  adminNotes: string;
  activityRegistrationNumber: string;
  subcontractingContractUrl: string;
};

export const defaultValues = {
  name: "",
  lastName: "",
  email: "",
  mobile: "",
  defaultPrice: null,
  address: "",
  additionalAddress: "",
  siret: "",
  iban: "",
  adminNotes: "",
  activityRegistrationNumber: "",
  subcontractingContractUrl: "",
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
  mobile: string().test("mobile", `Numéro de téléphone invalide`, value => {
    if (value) {
      return isPossiblePhoneNumber(value);
    }
    // mobile is empty which is valid since it can be null
    return true;
  }),
  defaultPrice: number()
    .nullable()
    .transform(value => (!value ? null : value))
    .max(MAX_HOURLY_PRICE, `Maximum ${MAX_HOURLY_PRICE}€`),
  address: string().max(255),
  additionalAddress: string().max(255),
  siret: string().max(14),
  iban: string()
    .max(255)
    .test("iban", "L'IBAN renseigné est invalide", value => {
      if (value) {
        return isValid(value);
      }
      // IBAN is empty which is valid since it can be null
      return true;
    }),
  adminNotes: string(),
  activityRegistrationNumber: string()
    .max(11, ACTIVITY_REGISTRATION_NUMBER_ERROR_MESSAGE)
    .test("activityRegistrationNumber", ACTIVITY_REGISTRATION_NUMBER_ERROR_MESSAGE, value => {
      if (value) {
        return /^\d{11}$/.test(value);
      }
      // activityRegistrationNumber is empty which is valid since it can be null
      return true;
    }),
  subcontractingContractUrl: string().url().max(255),
});

export const formatTeacherDataToFormData = (teacher: Teacher): FormInputs => {
  return {
    ...defaultValues,
    name: teacher.name,
    lastName: teacher.lastName,
    email: teacher.email,
    mobile: teacher.mobile ?? "",
    defaultPrice: teacher.defaultPrice ?? null,
    address: teacher.address ?? "",
    additionalAddress: teacher.additionalAddress ?? "",
    siret: teacher.siret ?? "",
    iban: teacher.iban ?? "",
    adminNotes: teacher.adminNotes ?? "",
    activityRegistrationNumber: teacher.activityRegistrationNumber ?? "",
    subcontractingContractUrl: teacher.subcontractingContractUrl ?? "",
  };
};

export const formatFormDataToBodyData = (teacher: FormInputs): AdminUpdateTeacherBody => {
  return {
    name: teacher.name,
    lastName: teacher.lastName,
    email: teacher.email.toLowerCase().trim(),
    mobile: teacher.mobile || null,
    defaultPrice: teacher.defaultPrice,
    address: teacher.address || null,
    additionalAddress: teacher.additionalAddress || null,
    siret: teacher.siret || null,
    iban: teacher.iban || null,
    adminNotes: teacher.adminNotes || null,
    activityRegistrationNumber: teacher.activityRegistrationNumber || null,
    subcontractingContractUrl: teacher.subcontractingContractUrl || null,
  };
};
