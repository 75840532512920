import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ReportAnIssueBody } from "@user-management-context/shared/application/ports/user-gateway";

export const reportIssue = createAsyncThunk<void, ReportAnIssueBody, { extra: Partial<Dependencies> }>(
  "users/reportIssue",
  async (body: ReportAnIssueBody, { extra: { userGateway } }) => {
    return userGateway!.reportIssue(body);
  }
);
