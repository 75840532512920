import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateBudgetBody } from "@academy-context/write/domain/types/admin/budget";
import { BudgetCreate } from "./budget-create.component";
import { createBudget } from "../../../write/application/use-cases/admin/admin-budget-creation/create-budget";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { retrieveSubmittedToFunderFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/submitted-to-funder/retrieve-submitted-to-funder-funding-requests";
import { Card, CardContent } from "@components/ui/card";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { AppState } from "@redux/app-state";
import { selectBudgetCreate } from "@academy-context/primary/admin/budget-detail/use-budget-create";
import { FormSkeleton } from "@shared-kernel/primary/shared/skeletons/form.skeleton";
import { BudgetsList } from "@academy-context/primary/admin/budget-detail/budget-list.components";
import { selectBudgetsRetrievalForList } from "@academy-context/read/application/use-cases/admin/budgets-retrieval/selectors/budgets-list-selectors";
import { retrieveBudgets } from "@academy-context/read/application/use-cases/admin/budgets-retrieval/retrieve-budgets";

export const BudgetAdminDetail = () => {
  const dispatch = useAppDispatch();
  const { data: budgets, isLoading } = useAppSelector(selectBudgetsRetrievalForList);
  const { data: fundingRequests } = useAppSelector((state: AppState) => state.submittedToFunderFundingRequestsRetrieval);
  const { processing } = useAppSelector(selectBudgetCreate);

  useEffect(() => {
    dispatch(retrieveStudents());
    dispatch(retrieveSubmittedToFunderFundingRequests());
    dispatch(retrieveBudgets());
  }, [dispatch]);

  const saveBudget = async (budget: CreateBudgetBody) => {
    await dispatch(createBudget(budget));
  };

  if (isLoading) return <FormSkeleton />;

  return (
    <>
      <CustomCard title="Nouveau financement">
        <BudgetCreate onSubmit={saveBudget} fundingRequests={fundingRequests} processing={processing} />
      </CustomCard>
      <Card className="mt-4">
        <CardContent className="flex flex-col items-center pt-3 text-black dark:text-white">
          <BudgetsList budgets={budgets} />
        </CardContent>
      </Card>
    </>
  );
};
