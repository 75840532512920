import { Nullable } from "@shared-kernel/core/types/nullable";
import { formatNumberToLocale } from "../../../../utils/formatting";
import { useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { Link } from "react-router-dom";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import clsx from "clsx";

interface Props {
  balance: number;
  localeNextFundingDate: Nullable<string>;
  nextFundingDate: Nullable<string>;
}

export const BudgetSummary = ({ balance, localeNextFundingDate }: Props) => {
  const { data } = useAppSelector((state: AppState) => state.fundingRequestEligibilityRetrieval);

  return (
    <CustomCard title="MON SOLDE / MA CARENCE" cardClassname="h-full">
      <span className="mb-3">Votre solde chez OPERA Off est de:</span>
      <span className={clsx("mb-3 text-4xl font-bold", balance > 0 ? "text-green-500" : "text-red-500")}>
        {formatNumberToLocale(balance, 2)}€
      </span>
      <span className="mb-3">
        <span className="text-red-500">Attention</span> : Les professeurs peuvent déclarer les cours en retard, veuillez en tenir compte
        pour déterminer votre solde réel.
      </span>
      {data && (
        <div className="font-bold">
          {data.isEligibleForAFundingRequest ? (
            <div className="flex flex-col items-center">
              <span>Vous êtes éligible à une demande de financement.</span>
              <span className="mx-2 text-center">
                Pour relancer un dossier de financement et nous transmettre vos documents, il suffit d'aller sur ce{" "}
                <Link to={ROLE_BASED_URLS[ROLES.STUDENT].fundingRequest.base} className="text-theme">
                  lien
                </Link>
                .
              </span>
            </div>
          ) : (
            <>
              {localeNextFundingDate && (
                <>
                  <span>Votre carence AFDAS se termine en </span>
                  <span className="font-bold text-red-500">{localeNextFundingDate}</span>
                </>
              )}
            </>
          )}
        </div>
      )}
    </CustomCard>
  );
};
