import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const uploadResume = createAsyncThunk<string, { resume: File }, { extra: Partial<Dependencies> }>(
  "fundingRequests/uploadResume",
  async ({ resume }: { resume: File }, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.uploadDocument(resume);
  }
);

export const resetUploadResume = createAction("fundingRequests/resetUploadResume");
