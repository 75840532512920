import { getPlaceName } from "@academy-context/write/domain/services/locations";
import { AppState } from "@redux/app-state";

export const selectLessonsRetrievalForList = (state: AppState) => {
  const {
    adminLessonsRetrieval: { data, fetching },
  } = state;

  return {
    data: data.map(lesson => {
      const { place, studentName, studentLastName } = lesson;
      return {
        ...lesson,
        student: `${studentName} ${studentLastName}`,
        placeName: getPlaceName(place.paidByTeacher, place.name),
      };
    }),
    fetching,
  };
};
