import { resetCreateBudget } from "../../../write/application/use-cases/admin/admin-budget-creation/create-budget";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { UseFormReset } from "react-hook-form";
import { FormInputs } from "./form-validation/budget/budget";
import { retrieveSubmittedToFunderFundingRequests } from "@academy-context/read/application/use-cases/admin/funding-request-list/submitted-to-funder/retrieve-submitted-to-funder-funding-requests";
import { retrieveBudgets } from "@academy-context/read/application/use-cases/admin/budgets-retrieval/retrieve-budgets";

export const selectBudgetCreate = (state: AppState) => state.budgetCreate;

interface Props {
  reset: UseFormReset<FormInputs>;
}

export const useBudgetCreate = ({ reset }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedId, processing } = useAppSelector(selectBudgetCreate);

  useEffect(() => {
    if (justCreatedId) {
      toast({ description: "Budget ajouté !" });
      reset();
      dispatch(resetCreateBudget());
      dispatch(retrieveBudgets());
      dispatch(retrieveSubmittedToFunderFundingRequests());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'ajout du budget", variant: "destructive" });
    }
  }, [reset, justCreatedId, processing, dispatch, toast]);
};
