import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const uploadBio = createAsyncThunk<string, { bio: File }, { extra: Partial<Dependencies> }>(
  "fundingRequests/uploadBio",
  async ({ bio }: { bio: File }, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.uploadDocument(bio);
  }
);

export const resetUploadBio = createAction("fundingRequests/resetUploadBio");
