import { Table } from "@tanstack/react-table";
import { X } from "lucide-react";

import { Button } from "@components/ui/button";
import { Input } from "@components/ui/input";

import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import { DataTableViewOptions } from "@components/ui/data-table-view-options";
import { InputHTMLAttributes, useEffect, useState } from "react";

// /!\ FOR MULTIPLE SELECTION, DON'T FORGET TO ADD in the columns definition
// filterFn: (row, id, value) => {
//   return value.includes(row.getValue(id));
// }
export type DataTableFilter = {
  label: string;
  value: string;
  options: {
    value: string;
    label: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
};

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  displayDataViewOptions?: boolean;
  searchPlaceHolder?: string;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  filters?: DataTableFilter[];
}

export function DataTableToolbar<TData>({
  table,
  displayDataViewOptions = false,
  searchPlaceHolder,
  globalFilter,
  setGlobalFilter,
  filters,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between py-4 my-0">
      <div className="flex flex-1 items-center space-x-2">
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={value => setGlobalFilter(String(value))}
          className="h-8 w-[150px] lg:w-[250px]"
          placeholder={searchPlaceHolder}
        />
        {filters &&
          filters.map(filter => {
            return (
              table.getColumn(filter.value) && (
                <DataTableFacetedFilter
                  key={filter.value}
                  column={table.getColumn(filter.value)}
                  title={filter.label}
                  options={filter.options}
                />
              )
            );
          })}
        {isFiltered && (
          <Button variant="ghost" onClick={() => table.resetColumnFilters()} className="h-8 px-2 lg:px-3">
            Réinitialiser
            <X />
          </Button>
        )}
      </div>
      {displayDataViewOptions && <DataTableViewOptions table={table} />}
    </div>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return <Input {...props} value={value} onChange={e => setValue(e.target.value)} />;
}
