import { AlertModal } from "../modal/alert-modal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const LostDataModal = ({ isOpen, onClose, onSubmit }: Props) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onClose={() => onClose()}
      onSubmit={() => onSubmit()}
      title="Quitter sans sauvegarder ?"
      body="Si vous quittez la page maintenant, les changements réalisés seront perdus"
      submitText="Quitter sans sauvegarder"
      cancelText="Continuer l'édition"
    />
  );
};
