import { AppState } from "@redux/app-state";
import { convertDateToMonthAndYear } from "../../../../../../../utils/formatting";
import { FUNDER_OPTIONS } from "@academy-context/read/domain/types/admin/budget";
import { FUNDER } from "@academy-context/shared/domain/types/enums/funders";

const labels: Record<
  "balance_adjustment" | "standard_lesson" | "lesson_under_convention" | "budget" | "convention" | "refund" | "participation",
  string
> = {
  balance_adjustment: "Ajustement de solde",
  standard_lesson: "Cours",
  lesson_under_convention: "Cours sous convention",
  budget: "Financement",
  convention: "Convention",
  refund: "Remboursement via demande",
  participation: "Stage prestataire",
};

export const selectStudentSummaryRetrieval = (state: AppState) => {
  const {
    studentSummaryRetrieval: { data: summary },
  } = state;

  if (!summary)
    return { data: { lastEntries: [], lastActiveConvention: null, nextFundingDate: null, localeNextFundingDate: null, balance: 0 } };

  const entries = [...summary.lastEntries]
    .map(l => {
      const isBudget = ["budget"].includes(l.type);
      const isBalanceAdjustment = ["balance_adjustment"].includes(l.type);
      const isCredited = isBudget || (isBalanceAdjustment && l.totalAmount > 0);
      return {
        date: l.date,
        localeDate: new Date(l.date).toLocaleDateString("fr-FR"),
        category: labels[l.type],
        label: isBudget ? FUNDER_OPTIONS[l.detail as FUNDER] : l.detail,
        totalAmount: l.totalAmount,
        isUnderConvention: l.type === "lesson_under_convention",
        commentary: l.commentary,
        isCredited,
        isBalanceAdjustment,
      };
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return {
    data: {
      ...summary,
      localeNextFundingDate: summary.nextFundingDate ? convertDateToMonthAndYear(summary.nextFundingDate) : null,
      lastEntries: entries,
    },
  };
};
