export enum ISSUE_TYPE {
  UNEXPECTED_EVENT = "unexpected_event",
  DIFFICULTY = "difficulty",
  COMPLAINT = "complaint",
}

export const ISSUE_TYPE_LABEL: Record<ISSUE_TYPE, string> = {
  [ISSUE_TYPE.COMPLAINT]: "Réclamation",
  [ISSUE_TYPE.DIFFICULTY]: "Difficulté",
  [ISSUE_TYPE.UNEXPECTED_EVENT]: "Aléa",
};
