import { AdminBalanceAdjustmentListVM } from "@academy-context/read/domain/types/admin/balance-adjustment";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

const balanceAdjustment: AdminBalanceAdjustmentListVM = {
  student: "studentId",
  label: "masterclass",
  amount: 300,
  commentary: "Chant",
  id: "123abc",
  creationDate: "2022-06-02T12:00:00.000Z",
  documentUrl: null,
  organization: ORGANIZATION.LES_ATELIERS_OO,
};
const balanceAdjustment2: AdminBalanceAdjustmentListVM = {
  student: "studentId2",
  label: "masterclass",
  amount: 100,
  commentary: "Chant",
  id: "456def",
  creationDate: "2022-06-01T12:00:00.000Z",
  documentUrl: null,
  organization: ORGANIZATION.LES_ATELIERS_OO,
};
const balanceAdjustment3: AdminBalanceAdjustmentListVM = {
  student: "studentId3",
  label: "masterclass",
  amount: 20,
  commentary: "Chant",
  id: "balanceAdjustmentId",
  creationDate: "2022-06-08T12:00:00.000Z",
  documentUrl: null,
  organization: ORGANIZATION.LES_ATELIERS_OO,
};

export const balanceAdjustments = [balanceAdjustment, balanceAdjustment2, balanceAdjustment3];
