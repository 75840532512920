import { createSelector } from "@reduxjs/toolkit";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";
import { USER_STATUS } from "@user-management-context/read/domain/types/admin/student";
import { isLoadingState } from "@utils/utils";
import { AppState } from "src/redux/app-state";

export interface AdminStudentListItem {
  studentId: string;
  name: string;
  lastName: string;
  balance: {
    global: number;
    byOrganization: Record<ORGANIZATION, number>;
  };
  nextFundingDate: Nullable<string>;
  email: string;
  userStatus: USER_STATUS;
  isBlacklisted: boolean;
  isFundingRequestInProgress: boolean;
}

export const selectStudents = createSelector([(state: AppState) => state.studentsRetrieval], studentsRetrieval => {
  const { data, fetching } = studentsRetrieval;

  const students: AdminStudentListItem[] = data.map(s => {
    return {
      studentId: s.details.studentId,
      name: s.name,
      email: s.email,
      lastName: s.lastName,
      nextFundingDate: s.details.nextFundingDate,
      userStatus: s.userStatus,
      isBlacklisted: s.details.isBlacklisted,
      isFundingRequestInProgress: s.details.isFundingRequestInProgress,
      balance: s.details.balance,
      convention: s.details.convention,
    };
  });

  return { students, isLoading: isLoadingState(fetching) };
});
