import { AppState } from "@redux/app-state";
import { isLoadingState } from "@utils/utils";

export type ExtendedConventionListItemVM = ReturnType<typeof selectConventionsRetrievalForList>["data"][number];

export const selectConventionsRetrievalForList = (state: AppState) => {
  const {
    adminConventionsRetrieval: { data, fetching },
  } = state;

  const sortedData = [...data].sort((a, b) => {
    const dateAInMs = new Date(a.date).getTime();
    const dateBInMs = new Date(b.date).getTime();

    if (dateBInMs < dateAInMs) {
      return -1;
    } else if (dateBInMs > dateAInMs) {
      return 1;
    }
    return 0;
  });

  const extendedData = sortedData.map(c => {
    return {
      ...c,
      totalPrice: c.hourlyPrice * c.hours,
    };
  });

  return {
    data: extendedData,

    isLoading: isLoadingState(fetching),
  };
};
