import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useNavigate } from "react-router-dom";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

export const selectRequestRefund = (state: AppState) => state.refundRequest;

interface Props {
  onReset: () => void;
}

export const useRefundRequest = ({ onReset }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { fetching } = useAppSelector(selectRequestRefund);

  useEffect(() => {
    if (fetching === "success") {
      toast({ description: "Demande de remboursement envoyée !" });
      onReset();
      navigate(ROLE_BASED_URLS[ROLES.STUDENT].refundRequest.success);
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de l'envoi de la demande de remboursement", variant: "destructive" });
    }
  }, [fetching, dispatch, toast, onReset, navigate]);
};
