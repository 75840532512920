import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import {
  adminRetrieveProviderBatches,
  resetAdminRetrieveProviderBatches,
} from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";
import { inviteStudent } from "@academy-context/write/application/use-cases/admin/student-invitation/invite-student";
import { PROVIDER_BATCH_ENROLLMENT_STATUS } from "@academy-context/read/domain/types/enums/provider-batch-enrollment-status";

const initialState: RetrievalState<ProviderBatchItem[]> = { data: [], fetching: "idle" };

export const adminRetrieveProviderBatchesReducer = createReducer<RetrievalState<ProviderBatchItem[]>>(initialState, builder => {
  builder.addCase(adminRetrieveProviderBatches.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveProviderBatches.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveProviderBatches.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  // We use an extra reducer to handle the inviteStudent action because the status update is handled by an event handler that triggers afterwards.
  // This means that we have to make an optimistic UI update to make the user experience better.
  builder.addCase(
    inviteStudent.fulfilled,
    (
      state,
      {
        meta: {
          arg: { now, studentId },
        },
      }
    ) => {
      if (!state.data) return;

      state.data.forEach(batch => {
        batch.enrollments.forEach(enrollment => {
          if (enrollment.student.id === studentId) {
            enrollment.status = PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_SENT_BY_ADMIN;
            enrollment.lastUpdatedStatusDate = now.toISOString();
          }
        });
      });
      state.fetching = "success";
    }
  );
  builder.addCase(resetAdminRetrieveProviderBatches, () => {
    return initialState;
  });
});
