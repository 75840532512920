import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentBalanceAdjustmentListVM } from "../../../../domain/types/student/balance-adjustment";

export const studentRetrieveBalanceAdjustments = createAsyncThunk<StudentBalanceAdjustmentListVM[], void, { extra: Partial<Dependencies> }>(
  "balanceAdjustments/studentRetrieveBalanceAdjustments",
  async (_: void, { extra: { studentBalanceAdjustmentRepository } }) => {
    return studentBalanceAdjustmentRepository!.all();
  }
);
