import { createReducer } from "@reduxjs/toolkit";
import { resetInviteStandardUser, inviteStandardUser } from "./invite-standard-user";
import { UserInviteState } from "@redux/app-state";

const initialState: UserInviteState = { justInvitedUserId: null, processing: "idle" };

export const inviteStandardUserReducer = createReducer<UserInviteState>(initialState, builder => {
  builder.addCase(inviteStandardUser.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justInvitedUserId: payload,
      processing: "success",
    };
  });
  builder.addCase(inviteStandardUser.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(inviteStandardUser.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetInviteStandardUser, () => {
    return initialState;
  });
});
