import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { UseFormReset } from "react-hook-form";
import { FormInputs } from "./form-validation/lesson";
import { resetCreateLesson } from "../../../write/application/use-cases/teacher/lesson-creation/create-lesson";
import { retrieveLessons } from "../../../read/application/use-cases/teacher/lessons-retrieval/retrieve-lessons";
import { retrieveTeacherStudents } from "../../../../user-management-context/read/application/use-cases/teacher/students-retrieval/retrieve-students";
import { retrieveOldConventions } from "@academy-context/read/application/use-cases/teacher/old-conventions-retrieval/retrieve-old-conventions";
import { retrieveConventions } from "@academy-context/read/application/use-cases/teacher/conventions-retrieval/retrieve-conventions";

export const selectLessonCreate = (state: AppState) => state.lessonCreate;

interface Props {
  reset: UseFormReset<FormInputs>;
}

export const useLessonCreate = ({ reset }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedLessonId, fetching } = useAppSelector(selectLessonCreate);

  useEffect(() => {
    if (fetching === "success" && justCreatedLessonId) {
      toast({ description: "Cours ajouté !" });
      reset();
      dispatch(resetCreateLesson());
      dispatch(retrieveLessons());
      // Need to fetch convention hours left
      dispatch(retrieveTeacherStudents());
      // Need to update state of Active convention cards
      dispatch(retrieveConventions());
      dispatch(retrieveOldConventions());
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de l'ajout du cours", variant: "destructive" });
    }
  }, [justCreatedLessonId, dispatch, fetching, toast, reset]);
};
