import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { InternalTraining } from "@academy-context/read/domain/types/admin/internal-training";

export const adminRetrieveInternalTrainings = createAsyncThunk<InternalTraining[], void, { extra: Partial<Dependencies> }>(
  "internalTrainings/retrieveInternalTrainings",
  async (_, { extra: { adminInternalTrainingRepository } }) => {
    return adminInternalTrainingRepository!.list();
  }
);

export const resetAdminRetrieveInternalTrainings = createAction("internalTrainings/resetAdminRetrieveInternalTrainings");
