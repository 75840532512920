import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { addMonths, subMonths } from "date-fns";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { TrainingDays } from "@academy-context/shared/domain/types/admin/funding-request";
import { retrieveStudentSummary } from "@academy-context/read/application/use-cases/student/summary-retrieval/retrieve-student-summary";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { AppState } from "src/redux/app-state";
import { formatDateToLocale } from "@utils/formatting";

interface Props {
  studentId: string;
  now: Date;
  dateOptions: ComboboxOptions[];
  currentMonth: string;
  onChangeMonth: (month: string) => void;
}

export const TrainingDaysForm = ({ studentId, now, dateOptions, currentMonth, onChangeMonth }: Props) => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: AppState) => state.studentSummaryRetrieval);
  const { control, watch } = useFormContext<TrainingDays>();

  useEffect(() => {
    dispatch(retrieveStudentSummary(studentId));
  }, [studentId, dispatch]);

  const maxDate = addMonths(now, 6);
  const minDate = subMonths(now, 6);

  let isDay1BeforeNextFundingDate = false;
  if (data?.nextFundingDate != null) {
    const day1 = watch("day1");
    isDay1BeforeNextFundingDate = new Date(data.nextFundingDate).getTime() >= new Date(day1).getTime();
  }

  return (
    <div className="space-y-4">
      {isDay1BeforeNextFundingDate && data?.nextFundingDate && (
        <p className="font-bold text-red-500">
          Attention, la date de debut de stage choisie est antérieure à la date de carence calculée :{" "}
          {formatDateToLocale(data.nextFundingDate)}
        </p>
      )}

      <FormItem>
        <FormLabel>Jours de formation</FormLabel>
        <FormControl>
          <Combobox
            options={dateOptions}
            value={currentMonth}
            onChange={onChangeMonth}
            placeholder="Selectionner les dates..."
            search={{
              notFoundText: "Pas de date trouvée.",
              commandInputPlaceHolder: "Chercher date...",
            }}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1 flex flex-col items-center space-y-2">
          {Array.from({ length: 5 }, (_, i) => i + 1).map(day => (
            <FormField
              key={`day${day}`}
              control={control}
              name={`day${day}` as keyof TrainingDays}
              defaultValue=""
              render={({ field }) => {
                return (
                  <FormItem className="flex w-full flex-col items-center">
                    <FormLabel>{`Jour ${day}`}</FormLabel>
                    <PopoverCalendar maxDate={maxDate} minDate={minDate} value={field.value} onChange={value => field.onChange(value)} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          ))}
        </div>
        <div className="col-span-1 flex flex-col items-center space-y-2">
          {Array.from({ length: 5 }, (_, i) => i + 1).map(day => (
            <FormField
              key={`day${day + 5}`}
              control={control}
              name={`day${day + 5}` as keyof TrainingDays}
              defaultValue=""
              render={({ field }) => {
                return (
                  <FormItem className="flex w-full flex-col items-center">
                    <FormLabel>{`Jour ${day + 5}`}</FormLabel>
                    <PopoverCalendar maxDate={maxDate} minDate={minDate} value={field.value} onChange={value => field.onChange(value)} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
