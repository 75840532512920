import { TokenStorageGateway } from "../../../../../../shared-kernel/application/ports/token-storage-gateway";
import {
  AdminUserInvitationPort,
  InviteAdminUserBody,
  InviteStandardUserBody,
} from "@user-management-context/shared/application/ports/user-invitation-port";
import { UserInvitation } from "../../../../../read/domain/types/admin/user-invitation";
import { HttpGateway } from "../../../../../../shared-kernel/secondary/http-gateway";
import config from "../../../../../../config/backend";

export class AdminBackendUserInvitationAdapter extends HttpGateway implements AdminUserInvitationPort {
  private _route: string = "v1/admin/accounts";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async inviteStandardUser(user: InviteStandardUserBody): Promise<string> {
    const route = this._route + "/invitations:invite-standard";
    const { data: userId } = await this._instance.post(route, user);
    return userId;
  }

  async inviteAdminUser(user: InviteAdminUserBody): Promise<string> {
    const route = this._route + "/invitations:invite-admin";
    const { data: userId } = await this._instance.post(route, user);
    return userId;
  }

  async refreshUserInvitation(email: string): Promise<void> {
    const route = this._route + "/invitations:refresh";
    await this._instance.post(route, { email });
  }

  async all(): Promise<UserInvitation[]> {
    const route = this._route + "/invitations";
    const { data: invitations } = await this._instance.get(route);
    return invitations;
  }
}
