import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ReviewFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";

export const reviewFundingRequest = createAsyncThunk<
  void,
  { id: string; body: ReviewFundingRequestBody },
  { extra: Partial<Dependencies> }
>(
  "fundingRequests/reviewFundingRequest",
  async ({ id, body }: { id: string; body: ReviewFundingRequestBody }, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.review(id, body);
  }
);

export const resetReviewFundingRequest = createAction("fundingRequests/resetReviewFundingRequest");
