import { useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { FormProvider, useForm } from "react-hook-form";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData, formatRefundDataToFormData } from "./form-validation/refund/refund";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatToCurrency } from "../../../../../../utils/formatting";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { FormControl, FormField, FormItem, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { PopoverCalendar } from "../../../../../../shared-kernel/primary/shared/calendar/popover-calendar";
import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";
import { Refund } from "@academy-context/read/domain/types/admin/refunds";
import { updateRefund } from "@academy-context/write/application/use-cases/admin/refund-update/update-refund";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@shared-kernel/primary/shared/shadcn/ui/alert-dialog";
import { OrganisationOptions } from "@shared-kernel/domain/organisation";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";

interface Props {
  refund: Refund;
  isPaid: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const UpdateRefundModal = ({ refund, isPaid, isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
    watch,
  } = form;

  const handleOnSubmit = async (formBody: FormInputs) => {
    onClose();
    const body = formatFormDataToBodyData(formBody);
    dispatch(updateRefund({ id: refund.id, body }));
  };

  useEffect(() => {
    resetForm();
    const status = isPaid ? PaymentStatus.PENDING : PaymentStatus.PAID;
    setValue("status", status, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refund]);

  const resetForm = () => {
    if (refund) reset(formatRefundDataToFormData({ ...refund, paymentDate: refund.paymentDate ?? today }));
  };

  const label = isPaid ? "Êtes-vous sûr de vouloir annuler ce virement ?" : "Êtes-vous sûr de vouloir réaliser ce virement ?";
  const Amountlabel = isPaid && refund ? `Montant versé` : `Montant à verser`;
  const today = new Date().toISOString();

  const amountPaidRaw = watch("amountPaid");
  const amountPaid = Number(amountPaidRaw);
  const isDistinctAmount = refund.totalAmount !== amountPaid;
  const shouldDisplayWarning = isPaid ? false : isDistinctAmount;

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FormProvider {...form}>
        <AlertDialog open={isOpen} onOpenChange={onClose}>
          <AlertDialogContent className="sm:max-w-[425px]">
            <AlertDialogHeader>
              <AlertDialogTitle>Modifier un virement</AlertDialogTitle>
              <AlertDialogDescription />
            </AlertDialogHeader>
            <div className="grid gap-4 py-4">
              <p>{label}</p>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="teacher" className="text-right">
                  Elève
                </Label>
                <Input id="teacher" value={refund.student} className="col-span-3" disabled />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="amount" className="text-right">
                  {Amountlabel}
                </Label>
                <Input id="amount" value={formatToCurrency(refund.totalAmount)} className="col-span-3" disabled />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="amountPaid" className="text-right">
                  Montant versé
                </Label>
                <FormField
                  control={form.control}
                  name="amountPaid"
                  render={({ field }) => (
                    <FormItem className="col-span-3 flex flex-col">
                      <FormControl>
                        <Input disabled={isPaid} type="number" min={0} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {shouldDisplayWarning && (
                  <span className="col-span-full text-sm text-red-500">Le montant renseigné est différent du montant calculé</span>
                )}
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="paymentDate" className="text-right">
                  Date du virement
                </Label>
                <FormField
                  control={form.control}
                  name="paymentDate"
                  render={({ field }) => {
                    return (
                      <FormItem className="col-span-3 flex flex-col">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} disabled={isPaid} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="organization" className="text-right">
                  Société
                </Label>
                <FormField
                  control={form.control}
                  name="organization"
                  render={({ field }) => (
                    <FormItem className="col-span-3 flex flex-col">
                      <FormControl>
                        <Combobox
                          options={OrganisationOptions}
                          value={field.value ?? ""}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner la société ..."
                          search={{
                            notFoundText: "Pas de société trouvée.",
                            commandInputPlaceHolder: "Chercher société...",
                          }}
                          disabled={isPaid}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="adminCommentary" className="text-right">
                  Commentaire
                </Label>
                <FormField
                  control={form.control}
                  name="adminCommentary"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Input type="text" {...field} disabled={isPaid} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={onClose} type="button">
                Annuler
              </AlertDialogCancel>
              <AlertDialogAction type="submit" disabled={!isDirty} onClick={handleSubmit(d => handleOnSubmit(d))}>
                Valider
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </FormProvider>
    </form>
  );
};
