import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateBudgetBody } from "@academy-context/write/domain/types/admin/budget";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData, MIN_FUNDING_AMOUNT } from "./form-validation/budget/budget";
import { useBudgetCreate } from "./use-budget-create";
import { FetchingState } from "@redux/app-state";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { addMonths, subMonths } from "date-fns";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { Textarea } from "@shared-kernel/primary/shared/shadcn/ui/textarea";
import { Equal, Minus } from "lucide-react";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { FUNDER_OPTIONS } from "@academy-context/read/domain/types/admin/budget";
import { FUNDER } from "@academy-context/shared/domain/types/enums/funders";
import { SubmittedToFunderFundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";

interface Props {
  onSubmit: (body: CreateBudgetBody) => void;
  fundingRequests: SubmittedToFunderFundingRequestVM[];
  processing: FetchingState;
}

const today = new Date();
const maxDate = addMonths(today, 6);
const minDate = subMonths(today, 6);

export const BudgetCreate = ({ onSubmit, fundingRequests, processing }: Props) => {
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const {
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = form;
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  useBudgetCreate({ reset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const allocatedAmount = watch("allocatedAmount");
  const operaOffCommission = watch("operaOffCommission");
  const totalAmount = allocatedAmount - operaOffCommission;
  const fundingRequestId = watch("fundingRequestId");
  const isAfdasFunder = watch("isAfdasFunder");

  const fundingRequestOptions = useMemo(() => {
    return fundingRequests.map(r => {
      const funder = FUNDER_OPTIONS[r.funder];
      return {
        value: r.id,
        label: `${r.student.name} | ${r.educationalAdvisor.name} | ${funder ?? r.funder} | ${new Date(r.submissionDate).toLocaleDateString(
          "fr-FR"
        )} | ${ORGANIZATION_NAME[r.organization].short}`,
      };
    });
  }, [fundingRequests]);

  useEffect(() => {
    setValue("externalFundingRequestId", "");
    setValue("expirationTimeInMonths", undefined);
  }, [setValue, fundingRequestId]);

  useEffect(() => {
    const selectedFundingRequest = fundingRequests.find(r => r.id === fundingRequestId);
    if (selectedFundingRequest) {
      setValue("internshipEndDate", selectedFundingRequest.training.endDate);
      setValue("allocatedAmount", selectedFundingRequest.training.price);
      setValue("isAfdasFunder", selectedFundingRequest.funder === FUNDER.AFDAS);
      // This is done to handle the migration from funding request V1 to V2
      // It can be removed once all current funding requests are migrated (ie have an operaOffCommission defined)
      if (selectedFundingRequest.training.operaOffCommission != null) {
        setValue("operaOffCommission", selectedFundingRequest.training.operaOffCommission);
      }
    }
  }, [setValue, fundingRequestId, fundingRequests]);

  return (
    <>
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-3 gap-3">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="fundingRequestId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Demande de financement</FormLabel>
                  <FormControl>
                    <Combobox
                      options={fundingRequestOptions}
                      value={watch("fundingRequestId")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner demande de financement..."
                      search={{
                        notFoundText: "Pas de demande de financement trouvée.",
                        commandInputPlaceHolder: "Chercher demande de financement...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="externalFundingRequestId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Module</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" placeholder="Exemple : IS-0069683-1" value={watch("externalFundingRequestId") ?? ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="transmitNotificationToAdmin"
              render={({ field }) => (
                <FormItem className="flex flex-row space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Envoyer le mail à vrd.siebert et non à l’élève</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="obtentionDate"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel>Date d'obtention</FormLabel>
                    <PopoverCalendar value={field.value} onChange={value => field.onChange(value)} maxDate={maxDate} minDate={minDate} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="internshipEndDate"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel>Date (de fin de stage)</FormLabel>
                    <PopoverCalendar value={field.value} onChange={value => field.onChange(value)} maxDate={maxDate} minDate={minDate} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="expirationTimeInMonths"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Mois de carence</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      step="1"
                      min="1"
                      value={watch("expirationTimeInMonths") ?? ""}
                      disabled={!isAfdasFunder}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <div className="flex items-end gap-2">
              <FormField
                control={form.control}
                name="allocatedAmount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Financement obtenu</FormLabel>
                    <FormControl>
                      <Input type="number" step="0.01" min={MIN_FUNDING_AMOUNT} {...field} value={watch("allocatedAmount")} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Minus className="mb-2" />
              <FormField
                control={form.control}
                name="operaOffCommission"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>OPERA Off</FormLabel>
                    <FormControl>
                      <Input type="number" step="0.01" {...field} value={watch("operaOffCommission")} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Equal className="mb-2" />
              <FormItem>
                <FormLabel>Total crédité</FormLabel>
                <FormControl>
                  <Input type="number" disabled value={totalAmount} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </div>
            <FormField
              control={form.control}
              name="commentary"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Commentaire</FormLabel>
                  <FormControl>
                    <Textarea maxLength={240} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-3 mt-2 flex justify-end">
            <Button type="submit" disabled={!isDirty || processing === "pending"}>
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
