import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "@components/ui/sidebar";
import { Link } from "react-router-dom";
import { User } from "@user-management-context/read/domain/types/user";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import logo from "../../../../assets/img/logo/new-logo.svg";
import smallLogo from "../../../../assets/img/logo/new-logo-small.svg";
import { ROLE_BASED_URLS } from "src/routes";
import { cn } from "@components/utils/utils";

interface Props {
  user: Nullable<User>;
  state: "expanded" | "collapsed";
}

export function NavHeader({ user, state }: Props) {
  const isCollapsed = state === "collapsed";
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <SidebarMenuButton size="lg" asChild className="flex justify-center hover:bg-inherit">
          <Link to={user && user.role !== ROLES.ADMIN ? ROLE_BASED_URLS[user.role].dashboard : ROLE_BASED_URLS[ROLES.ADMIN].payment.list}>
            <img src={isCollapsed ? smallLogo : logo} alt="logo" className={cn(isCollapsed && "h-4")} />
          </Link>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
