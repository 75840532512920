import { createReducer } from "@reduxjs/toolkit";
import { CreationState } from "@redux/app-state";
import { createConvention, resetCreateConvention } from "./create-convention";

const initialState: CreationState = { justCreatedId: null, processing: "idle" };

export const createConventionReducer = createReducer<CreationState>(initialState, builder => {
  builder.addCase(createConvention.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedId: payload,
      processing: "success",
    };
  });
  builder.addCase(createConvention.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(createConvention.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetCreateConvention, () => {
    return initialState;
  });
});
